import axios, { AxiosInstance, CreateAxiosDefaults } from "axios"

import { SPATIAL_UID_HEADER_NAME } from "@spatialsys/js/util/constants"

import { createAuthSessionEndpoints } from "./auth-session"
import { createThirdPartyGameEndpoints } from "./third-party-games"
import { createUsernameGeneratorEndpoint } from "./username-generator"

export type SwapiClientParams = CreateAxiosDefaults & {
  apiUrl?: string
}

/**
 * Spatial Web API client.
 */
export type SwapiClient = ReturnType<typeof createSwapiClient>

/**
 * Creates a client object for making requests to the Spatial Web API.
 */
export function createSwapiClient({ apiUrl = "", ...axiosDefaults }: SwapiClientParams = {}) {
  const clients: AxiosInstance[] = []

  function createClient(basePath: string) {
    const client = axios.create({
      ...axiosDefaults,
      withCredentials: true,
      baseURL: `${apiUrl}${basePath}`,
    })
    clients.push(client)
    return client
  }

  function updateClient(cb: (client: AxiosInstance) => void) {
    for (const client of clients) {
      cb(client)
    }
  }

  return {
    authSession: createAuthSessionEndpoints(createClient("/api/auth/session")),
    generateUsername: createUsernameGeneratorEndpoint(createClient("/api")),
    thirdPartyGame: createThirdPartyGameEndpoints(createClient("/api/p")),

    setSpatialUidHeader(spatialUid: string) {
      updateClient((client) => {
        client.defaults.headers.common[SPATIAL_UID_HEADER_NAME] = spatialUid
      })
    },

    updateClient,
  }
}
