import { extendTailwindMerge } from "tailwind-merge"

type ClassGroupIds = "text-shadow"

/**
 * Custom tailwind-merge config based on our Tailwind config.
 * Should be updated with `./tailwind.config.ts`
 *
 * @see https://github.com/dcastil/tailwind-merge/blob/v1.12.0/docs/configuration.md
 * @see https://github.com/dcastil/tailwind-merge/blob/v1.12.0/docs/recipes.md
 */
export const customTwMerge = extendTailwindMerge<ClassGroupIds>({
  // Reference: https://github.com/dcastil/tailwind-merge/blob/v1.12.0/src/lib/default-config.ts
  // Add any styles here that are not tracked by https://github.com/dcastil/tailwind-merge/blob/v1.12.0/docs/configuration.md#theme
  extend: {
    classGroups: {
      ease: [{ ease: ["emo", "emo-in-out", "emo-out", "emo-cubic"] }],
      "font-family": [{ font: ["body", "heading"] }],
      "font-size": [{ text: ["h1", "h2", "h3", "h4", "h5", "m1", "m2", "m3", "chat"] }],
      "font-weight": ["font-demibold"],
      shadow: ["shadow-drawer", "shadow-outline"],
      "text-shadow": [{ "text-shadow": ["chat", "xs", "sm", "default", "lg", "xl", "none"] }],
      tracking: [{ tracking: ["heading", "body"] }],
      z: [{ z: ["above-modal", "dock", "drawer", "menu", "modal", "overlay", "tooltip"] }],
    },
    // Add any styles here that are tracked by https://github.com/dcastil/tailwind-merge/blob/v1.12.0/docs/configuration.md#theme
    theme: {
      colors: ["blue", "red", "light-gray", "translucent-black"],
      spacing: ["navbar-h", "dialog-body"],
    },
  },
})
