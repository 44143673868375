import { AxiosInstance } from "axios"

import { RequireAtLeastOne } from "@spatialsys/js/types"

import { Friend, GetFriendsResponse, PaginationResponseMetadata } from "../types"

export type SearchUsersRequest = RequireAtLeastOne<{
  displayName?: string
  userID?: string
  username?: string
}>

export type SearchUsersResponse = PaginationResponseMetadata & {
  query: SearchUsersRequest
  results: Friend[]
}

export type GetFriendsToFillResponse = {
  filled: boolean
  followers: Friend[]
  totalFollowers: number
}

export function createFriendsEndpoint(client: AxiosInstance) {
  return {
    getFriends: async function (): Promise<GetFriendsResponse> {
      const response = await client.get<GetFriendsResponse>(`/`)
      return response.data
    },
    getReceivedFriendRequests: async function (): Promise<GetFriendsResponse> {
      const response = await client.get<GetFriendsResponse>(`/pending`)
      return response.data
    },
    getSentFriendRequests: async function (): Promise<GetFriendsResponse> {
      const response = await client.get<GetFriendsResponse>(`/sent`)
      return response.data
    },
    /**
     * Sends a friend request or accepts a friend request
     *
     */
    addFriend: async function ({ userId }: { userId: string }): Promise<void> {
      const response = await client.post(`/`, { userId })
      return response.data
    },
    /**
     * Removes a user as a friend or declines a friend request
     *
     * TODO: The backend will split this functionality into two separate endpoints
     */
    removeFriend: async function (userId: string): Promise<void> {
      const response = await client.delete(`/${userId}`)
      return response.data
    },
    blockFriend: async function (userId: string): Promise<void> {
      const response = await client.post(`/${userId}/block`)
      return response.data
    },
    unblockFriend: async function (userId: string): Promise<void> {
      const response = await client.post(`/${userId}/unblock`)
      return response.data
    },
    /**
     * Search for a user by username, display name, or userID
     */
    async searchUsers(params: SearchUsersRequest): Promise<SearchUsersResponse> {
      const response = await client.post<SearchUsersResponse>(`/search`, params)
      return response.data
    },
    /**
     * Friend recommendations
     *
     * Returns SearchUsersResponse because SAPI responds with the same type as /search
     */
    async getFriendRecommendations(): Promise<SearchUsersResponse> {
      const response = await client.get<SearchUsersResponse>(`/recommendations`)
      return response.data
    },
    /**
     * If the user would like to automatically send friend requests to followers they also follow back
     *
     */
    async postFriendsToFill(fill: boolean): Promise<void> {
      const response = await client.post<void>(`/fill`, { fill })
      return response.data
    },
    /**
     * Determines if the user has followers they also follow back to auto send friend requests to
     *
     */
    async getFriendsToFill(): Promise<GetFriendsToFillResponse> {
      const response = await client.get<GetFriendsToFillResponse>(`/fill`)
      return response.data
    },
  }
}
