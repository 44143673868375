import { ReactNode, createContext, useContext } from "react"

import { SapiClient } from "@spatialsys/js/sapi/client"

const SapiContext = createContext<SapiClient>(null as any)

export function SapiProvider({ children, client }: { children: ReactNode; client: SapiClient }) {
  return <SapiContext.Provider value={client}>{children}</SapiContext.Provider>
}

export function useSapi() {
  return useContext(SapiContext)
}
