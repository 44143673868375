import { AxiosInstance } from "axios"

export function createAppStoreEndpoints(client: AxiosInstance) {
  return {
    /**
     * Verifies an iOS subscription to Spatial +
     */
    async verifyAppleReceipt(receipt: string): Promise<void> {
      await client.post<void>("/appstore/verify-receipt", { receipt })
    },
  }
}
