import { GetRoomResponse, SpaceMetadata } from "@spatialsys/js/sapi/types"
import { TrackedComponents } from "@spatialsys/react/analytics"
import {
  AppUpdateAvailability,
  BackpackItemState,
  InputManager_InputMethod,
  RequestAlertToReactType,
  SpaceJoinContextState,
  SpatialAdType,
  StartupMessage,
} from "@spatialsys/unity/app-state"

type ReactAlertMessageBase = {
  title: string
  message: string
  permitText: string
  deniedText: string
  callbackID: number
  roomId?: string
  roomSlug?: string
  linkHref?: string
}

type ReactAlertMessageT<T extends RequestAlertToReactType, P = undefined> = {
  alertType: T
} & ReactAlertMessageBase &
  (P extends undefined ? {} : P)

export type RequestAdParams = {
  adType: SpatialAdType
  adRequestId: number
}

export type RequestAdResponse = {
  success: boolean
  errorReason?: string
  errorMessage?: string
}

export type ReactAlertMessageRequestAd = ReactAlertMessageT<
  RequestAlertToReactType.RequestAd,
  { requestAdParams: RequestAdParams }
>

/**
 * Specifically for purchase confirmation alerts
 */
export type ReactAlertMessagePurchaseConfirmation = ReactAlertMessageT<
  RequestAlertToReactType.ItemPurchaseWithUpsellConfirmation | RequestAlertToReactType.ItemPurchaseConfirmation,
  { purchaseConfirmationParams: ReactAlertMessagePurchaseConfirmationParams }
>

/**
 * For any alert types that don't have additional custom params
 */
type ReactAlertMessageExcludingComplex = ReactAlertMessageT<
  Exclude<
    RequestAlertToReactType,
    | RequestAlertToReactType.ItemPurchaseWithUpsellConfirmation
    | RequestAlertToReactType.ItemPurchaseConfirmation
    | RequestAlertToReactType.RequestAd
  >
>

/**
 * The body of a message from Unity to React that is displayed as a modal/toast
 * The callback can be executed by invoking `UnityMessages.requestAlertFeedback(callbackID, deniedText)`
 */
export type ReactAlertMessage =
  | ReactAlertMessageExcludingComplex
  | ReactAlertMessagePurchaseConfirmation
  | ReactAlertMessageRequestAd

export type ReactAlertMessagePurchaseConfirmationParams = {
  spaceId: string
  itemId: string
  itemName: string
  itemThumbnailUrl: string
  itemAmount: number
  itemUnitPrice: number
  totalSalesPrice: number
  balanceRemainingAfter: number
  brieflyDelayBuyButton: boolean
  /** This is only set when upselling to closest coins pack purchase */
  recommendedCoinsPackageProductId?: string
  coinsPackageCoinsAmount: number
}

export enum ReportCategory {
  Harassment = "Trolling or harassing users",
  Rooms = "Creating portals to offensive rooms",
  Child = "Is a child on an adult account",
  Other = "Other",
  Abuse = "Abuse or harassment",
  HarmfulOrViolent = "Harmful misinformation or glorifying violence",
  Copyright = "Copyright violation",
  Spam = "Spam",
}

/** The message that is passed in `spawnContent` and `replaceObjectContent` */
export interface ContentObject {
  contentId: string
  contentName: string
  contentType: string
  contentSourceType: string
  mimeType?: string
}

export interface EmotesAnalyticsData {
  method: InputManager_InputMethod
  source?: TrackedComponents
}

/**
 * The "source" — surface/screen/page — the user joined the space from.
 */
export const enum SpaceJoinContextSource {
  BrandPage = "Brand Page",
  Recommendations = "Recommendations",
  HeroCarousel = "HeroCarousel",
  HeroCarouselNewUserSlide = "HeroCarousel New User Slide",
  Trending = "Trending",
  Popular = "Popular",
  Live = "Live",
  Newest = "Newest",
  Recent = "Recent",
  YourSpaces = "Your Spaces",
  Shared = "Shared",
  Team = "Team",
  ControlsAndTips = "ControlsAndTips",
  Loved = "Loved",
  UserProfile = "User Profile",
  LeaveRecs = "Leave Recs",
  Search = "Search",
  SearchRecs = "Search Recs",
  NoRecentsPlaceholderRecs = "No Recents Placeholder Recs",
  HomeFeed = "Home Feed",
  CategoryPage = "Category Page",
  BadgeModal = "Badge Modal",
  AvatarModal = "Avatar Modal",
  EmoteModal = "Emote Modal",
  DirectLink = "Direct Link",
}

/**
 * Properties associated with the space that the user is joining.
 */
export type SpaceJoinContextSpaceMetadata = {
  "Title Length"?: number
  "Description Length"?: number
  "Has Custom Thumbnail"?: boolean
  "Num Tags"?: number
}

/**
 * Analytics that apply when the user selects a space from within our app,
 * as opposed to other sources such as a direct link or browser navigation.
 */
export type SpaceItemClickMetadata = {
  "Category ID"?: string
  /** The index of the element within the source, or a section within the source */
  "Space Index"?: number
  /** The index of the section within the overall source, if applicable. */
  "Section Index"?: number
  "On Cubemap Preview"?: boolean
  "On Hover Card"?: boolean
  "On Space Name"?: boolean
  "On Space Thumbnail"?: boolean
  "On Video Thumbnail"?: boolean
}

export type SourcedSpaceItemClickMetadata = SpaceItemClickMetadata & {
  /** The general page, screen, or component that contained the element that the user clicked on. */
  Source?: SpaceJoinContextSource
}

export type JoinContext = SpaceJoinContextState & {
  spaceProperties?: SpaceJoinContextSpaceMetadata
  discoveryMetadata?: SourcedSpaceItemClickMetadata
}

export const createSpaceMetadataProperties = (space: SpaceMetadata): SpaceJoinContextSpaceMetadata => {
  return {
    "Description Length": space.description?.length,
    "Has Custom Thumbnail": Boolean(space.roomThumbnails.customGetUrl),
    "Num Tags": space.tags?.length,
    "Title Length": space.name?.length,
  }
}

export interface JoinRoomArgs {
  roomId: string
  shareId?: string
  photonSessionId?: string
  spaceData?: GetRoomResponse
  joinRoomResponse?: any // the json response from sapi (as an object)
  context?: JoinContext & {
    spaceProperties?: Record<string, string | number | boolean>
    discoveryMetadata?: Record<string, string | number | boolean>
  }
  roomInstanceId?: string | null
}

/**
 * Message from Unity to React when a quest is completed
 */
export type QuestCompleteMessage = {
  questId: number
}

/**
 * Message from Unity to React when a quest task is completed
 */
export type QuestTaskCompleteMessage = {
  questId: number
  taskId: number
}

/**
 * Message from Unity to react when a badge has been rewarded
 */
export type BadgeRewardedMessage = {
  badgeId: string
}

/**
 * Message from Unity to React when a new item was added to the backpack.
 */
export type BackpackItemAddedMessage = BackpackItemState

export type JumpedThroughPortalMessage = {
  roomId: string
  roomSlug: string
}

export type BootstrapResult = {
  startupMessage: StartupMessage
  updateAvailabilityStatus: AppUpdateAvailability
}
