/**
 * @see https://www.radix-ui.com/primitives/docs/components/tooltip
 * @see https://ui.shadcn.com/docs/components/tooltip
 */
"use client"

import * as TooltipPrimitive from "@radix-ui/react-tooltip"
import { VariantProps, cva } from "class-variance-authority"
import { Fragment, forwardRef } from "react"

import { cn } from "../utils"

const TooltipRoot = TooltipPrimitive.Root
const TooltipArrow = TooltipPrimitive.Arrow
const TooltipTrigger = TooltipPrimitive.Trigger

/**
 * Accepts the same props as `TooltipPrimitive.Provider`.
 * Defaults the delay duration to 200ms.
 *
 * This component should be rendered at the root of the component tree, wrapping the entire application.
 *
 * @see https://www.radix-ui.com/primitives/docs/components/tooltip#provider
 */
const TooltipProvider = (props: React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Provider>) => (
  <TooltipPrimitive.Provider delayDuration={200} {...props} />
)

const tooltipContentVariants = cva(
  cn(
    // Base styles
    "z-tooltip rounded-3xl px-2.5 py-1.5 font-heading text-xs font-semibold",
    // Exit animation
    "data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95"
  ),
  {
    variants: {
      color: {
        white: "bg-white fill-white text-black",
        "dark-gray": "bg-gray-800 fill-gray-800 text-white",
        "translucent-black": "bg-translucent-black fill-translucent-black text-white",
      },
      enterAnimation: {
        subtle: "animate-in fade-in-0 zoom-in-95",
        slide:
          "animate-in fade-in-0 zoom-in-95 data-[side=bottom]:slide-in-from-top-1 data-[side=left]:slide-in-from-right-1 data-[side=right]:slide-in-from-left-1 data-[side=top]:slide-in-from-bottom-1",
      },
      shadow: { none: "", sm: "drop-shadow-[0_6px_12px_rgba(0,0,0,0.2)]" },
    },
    defaultVariants: { color: "white", enterAnimation: "subtle", shadow: "none" },
  }
)
type TooltipContentProps = VariantProps<typeof tooltipContentVariants> &
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>

/**
 * Styled tooltip content.
 */
const TooltipContent = forwardRef<React.ElementRef<typeof TooltipPrimitive.Content>, TooltipContentProps>(
  ({ className, color, collisionPadding = 4, enterAnimation, sideOffset = 2, shadow, ...props }, ref) => {
    return (
      <TooltipPrimitive.Content
        ref={ref}
        collisionPadding={collisionPadding}
        sideOffset={sideOffset}
        className={cn(tooltipContentVariants({ color, enterAnimation, shadow, className }))}
        {...props}
      />
    )
  }
)
TooltipContent.displayName = TooltipPrimitive.Content.displayName

type TooltipProps = React.PropsWithChildren<{
  content: React.ReactNode
  arrowProps?: React.ComponentPropsWithoutRef<typeof TooltipArrow>
  contentProps?: React.ComponentPropsWithoutRef<typeof TooltipContent>
  rootProps?: React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Root>
  withArrow?: boolean
  withPortal?: boolean
}>

/**
 * A styled tooltip with arrow.
 * - The content of the tooltip is passed via the `content` prop.
 * - `children` is rendered as the Tooltip Trigger.
 *
 * @example
 * ```tsx
 * <Tooltip content="Hello World">
 *  <button>Hover me</button>
 * </Tooltip>
 * ```
 *
 * For more precise control over each part of the Tooltip, you can use the Tooltip Primitives.
 * This component's structure is based on https://www.radix-ui.com/primitives/docs/components/tooltip#abstract-parts-and-introduce-a-content-prop
 */
function Tooltip(props: TooltipProps) {
  const { children, arrowProps, content, contentProps, rootProps, withArrow = true, withPortal = false } = props
  const PortalComp = withPortal ? TooltipPrimitive.Portal : Fragment

  return (
    <TooltipRoot {...rootProps}>
      <TooltipTrigger asChild>{children}</TooltipTrigger>
      <PortalComp>
        {content && (
          <TooltipContent side="top" align="center" {...contentProps}>
            {content}
            {withArrow && <TooltipArrow {...arrowProps} />}
          </TooltipContent>
        )}
      </PortalComp>
    </TooltipRoot>
  )
}
TooltipContent.displayName = TooltipPrimitive.Content.displayName

export { Tooltip, TooltipRoot, TooltipArrow, TooltipTrigger, TooltipContent, TooltipProvider }
