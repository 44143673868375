import { AxiosInstance } from "axios"

import { Avatar } from "../types"

export type GetAvatarsRequest = {
  count?: number
  skip?: number
  spaceId?: string
}

export type GetAvatarsResponse = {
  avatars: Avatar[]
  count: number
  skip: number
  totalCount: number
}

export function createAvatarsEndpoint(client: AxiosInstance) {
  return {
    async getAvatars({ spaceId, count, skip }: GetAvatarsRequest): Promise<GetAvatarsResponse> {
      const params = spaceId ? { space_id: spaceId, count, skip } : { count, skip }
      const response = await client.get<GetAvatarsResponse>(`/me/avatars`, { params })
      return response.data
    },
  }
}
