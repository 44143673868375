/**
 * Do not change these after they're in production; add a new one.
 */
export const enum InteractionType {
  Click = "Click",
  Error = "Error",
  Keypress = "Keypress",
  Submission = "Submission",
  Success = "Success",
  View = "View",
}

/**
 * Do not change these after they're in production; add a new one.
 *
 * Please sort a-z as this list will get very large over time!
 */

export const enum InteractionName {
  AcceptFriendRequest = "AcceptFriendRequest",
  // Ads
  AdComplete = "AdComplete",
  AdEmpty = "AdEmpty",
  AdFailed = "AdFailed",
  AdRequested = "AdRequested",
  AdStart = "AdStart",
  AdTooSoon = "AdTooSoon",
  AddFriend = "AddFriend",
  AddSpawnPoint = "AddSpawnPoint",
  AttemptToJoinOnFriend = "AttemptToJoinOnFriend",
  AuthErrorCta = "AuthErrorCta",
  AuthErrorStartOver = "AuthErrorStartOver",
  AuthSuccess = "AuthSuccess",
  AuthlessNameSignIn = "AuthlessNameSignIn",
  AuthlessSignInBackpackToast = "AuthlessSignInBackpackToast",
  AuthlessSignInBannerCta = "AuthlessSignInBannerCta",
  AuthlessSignInBannerDismiss = "AuthlessSignInBannerDismiss",
  AuthlessSignInShopToast = "AuthlessSignInShopToast",
  AuthlessUserSendChatMessage = "AuthlessUserSendChatMessage",
  AuthlessUserSetNameAndAvatar = "AuthlessUserSetNameAndAvatar",
  AuthlessUserSignUpForAvatarCustomization = "AuthlessUserSignUpForAvatarCustomization",
  AutoFillFriends = "AutoFillFriends",
  AvatarCustomizationRpmAvatarExported = "AvatarCustomizationRpmAvatarExported",
  CameraRotationMode = "CameraRotationMode",
  /** User cancelled avatar customization without saving */
  CancelSentFriendRequest = "CancelSentFriendRequest",
  ChatChannelJoin = "ChatChannelJoin",
  ChatChannelJoinAndSawFirstMessage = "ChatChannelJoinAndSawFirstMessage",
  ChatMessageSent = "ChatMessageSent",
  ClearAllFrames = "ClearAllFrames",
  ClearContentInRoom = "ClearContentInRoom",
  ClearCustomSkybox = "ClearCustomSkybox",
  ConfirmCustomEnv = "ConfirmCustomEnv",
  ConfirmVerifyEmail = "ConfirmVerifyEmail",
  ControlsAndTipsFirstQuest = "ControlsAndTipsFirstQuest",
  CopyEmbedCode = "CopyEmbedCode",
  CopyShareUrl = "CopyShareUrl",
  CreateNewSpace = "CreateNewSpace",
  CreateTeam = "CreateTeam",
  CreatorToolkitNewsletterSignUp = "CreatorToolkitNewsletterSignUp",
  CustomEnvFromObject = "CustomEnvFromObject",
  CycleAuthlessAvatar = "CycleAuthlessAvatar",
  DeleteSpace = "DeleteSpace",
  EditEnvironmentPosition = "EditEnvironmentPosition",
  EditGenericBody = "EditGenericBody",
  EditShirtColor = "EditShirtColor",
  EditSkinColor = "EditSkinColor",
  // Space name, description, tags, and thumbnail
  EditSpaceInfoCancelConfirm = "EditSpaceInfoCancelConfirm",
  EditSpaceInfoSave = "EditSpaceInfoSave",
  EmailSupport = "EmailSupport",
  // This isn't used anywhere, just a placeholder. Only for Unity side
  Emote = "Emote",
  EmoteKeybindSignInBannerCta = "EmoteKeybindSignInBannerCta",
  EmoteKeybindSignInBannerDismissed = "EmoteKeybindSignInBannerDismissed",
  EndGoLive = "EndGoLive",
  EndGoLiveModalConfirm = "EndGoLiveModalConfirm",
  EndGoLiveModalDismiss = "EndGoLiveModalDismiss",
  Exit = "Exit",
  FirstTutorialBack = "FirstTutorialBack",
  FirstTutorialDone = "FirstTutorialDone",
  FirstTutorialNext = "FirstTutorialNext",
  FirstTutorialSkip = "FirstTutorialSkip",
  FollowUser = "FollowUser",
  FreeDowngrade = "FreeDowngrade",
  FriendsIntroModal = "FriendsIntroModal",
  GoLive = "GoLive",
  GoLiveModalConfirm = "GoLiveModalConfirm",
  GoLiveModalDismiss = "GoLiveModalDismiss",
  HideEmptyFrames = "HideEmptyFrames",
  /** Ideally, this could be merged with Space Join Context, but it is tracked as a separate event due to implementation complexity */
  HomepageExternalGameTile = "HomepageExternalGameTile",
  HomepageHeaderContactSales = "HomepageHeaderContactSales",
  HomepageHeroCreator = "HomepageHeroCreator",
  HomepageHeroCta = "HomepageHeroCta",
  HyperlinkClicked = "HyperlinkClicked",
  HyperlinkCreated = "HyperlinkCreated",
  HyperlinkEditCancelled = "HyperlinkEditCancelled",
  HyperlinkLeaveSpatialCancel = "HyperlinkLeaveSpatialCancel",
  HyperlinkLeaveSpatialConfirm = "HyperlinkLeaveSpatialConfirm",
  InvalidProfileUrl = "InvalidProfileUrl",
  InvalidTokenGateAccessSettings = "InvalidTokenGateAccessSettings",
  JoinRecommendedSpaceOnLeave = "JoinRecommendedSpaceOnLeave",
  LandingPageCta = "LandingPageCta",
  LandingPageEmailInput = "LandingPageEmailInput",
  LeaveSpace = "LeaveSpace",
  LeaveSpaceCancel = "LeaveSpaceCancel",
  LeaveSpaceConfirm = "LeaveSpaceConfirm",
  LoginAttempt = "LoginAttempt",
  LoveSpace = "LikeSpace",
  MakeCurrentInstanceHostInstance = "MakeCurrentInstanceHostInstance",
  ManageAppleProSubscription = "ManageAppleProSubscription",
  ManageOculusProSubscription = "ManageOculusProSubscription",
  ManageStripeProSubscription = "ManageStripeProSubscription",
  MaximizeCanvas = "MaximizeCanvas",
  MidgameAdComplete = "MidgameAdComplete",
  MidgameAdEmpty = "MidgameAdEmpty",
  MidgameAdFailed = "MidgameAdFailed",
  MidgameAdRequested = "MidgameAdRequested",
  MidgameAdStart = "MidgameAdStart",
  MidgameAdTooSoon = "MidgameAdTooSoon",
  MinimizeCanvas = "MinimizeCanvas",
  MuteOther = "MuteOther",
  MuteSelf = "MuteSelf",
  NavbarCta = "NavbarCta",
  NavbarLogin = "NavbarLogin",
  NftLinkClicked = "NftLinkClicked",
  OnboardingChangedDisplayname = "OnboardingChangedDisplayname",
  OnboardingChangedUsername = "OnboardingChangedUsername",
  OnboardingComplete = "OnboardingComplete",
  OnboardingNotificationAccepted = "OnboardingNotificationAccepted",
  OnboardingNotificationDismissed = "OnboardingNotificationDismissed",
  OnboardingRandomizeUsername = "OnboardingRandomizeUsername",
  OnboardingSubmissionError = "OnboardingSubmissionError",
  OnboardingSubmitted = "OnboardingSubmitted",
  OnboardingToggleTos = "OnboardingToggleTos",
  OnboardingToggleUsernameAsDisplayName = "OnboardingToggleUsernameAsDisplayName",
  OpenCreateSpaceInApp = "OpenCreateSpaceInApp",
  OpenCreatorProfile = "OpenCreatorProfile",
  OpenEditProfile = "OpenEditProfile",
  OpenEnvSettingsPanel = "OpenEnvSettingsPanel",
  OpenInApp = "OpenInApp",
  OpenLayersPanel = "OpenLayersPanel",
  OpenNewSpacePicker = "OpenNewSpacePicker",
  OpenPropertiesPanel = "OpenPropertiesPanel",
  OpenSpaceInApp = "OpenSpaceInApp",
  OpenTag = "OpenTag",
  OpenUniversalShop = "OpenUniversalShop",
  PlusUpgrade = "PlusUpgrade",
  PlusUpgradePurchaseConfirmation = "PlusUpgradePurchaseConfirmation",
  ProfilePageCancel = "ProfilePageCancel",
  ProfilePageEditAvatar = "ProfilePageEditAvatar",
  ProfilePageSave = "ProfilePageSave",
  PublishSpace = "PublishSpace",
  PurchaseCoinsPackage = "PurchaseCoinsPackage",
  PurchaseWithCoins = "PurchaseWithCoins",
  PushNotificationClicked = "PushNotificationClicked",
  /** User denied/blocked browser permission for notifications, or browser failed to grant permission. */
  PushNotificationPermissionFailed = "PushNotificationPermissionFailed",
  /** User granted browser permission for notifications, and device token was sent to SAPI. */
  PushNotificationPermissionGranted = "PushNotificationPermissionGranted",
  /** User accepted the initial permission modal, transitioning into the browser permission prompt. */
  PushNotificationPermissionModalAccepted = "PushNotificationPermissionModalAccepted",
  /** User dismissed the initial permission modal. They will be prompted again in the near future. */
  PushNotificationPermissionModalDismissed = "PushNotificationPermissionModalDismissed",
  PushNotificationReceived = "PushNotificationReceived",
  QuestsCompleteModalClose = "QuestsCompleteModalClose",
  QuestsCompleteModalKeepExploring = "QuestsCompleteModalKeepExploring",
  QuestsCompleteModalSelectSpace = "QuestsCompleteModalSelectSpace",
  ReadyPlayerMeAnnouncementBannerCta = "ReadyPlayerMeAnnouncementBannerCta",
  ReadyPlayerMeAnnouncementBannerDismiss = "ReadyPlayerMeAnnouncementBannerDismiss",
  RecordVideoStart = "RecordVideoStart",
  RecordVideoStop = "RecordVideoStop",
  RecordedVideo = "RecordedVideo",
  RejectFriendRequest = "RejectFriendRequest",
  RejectSystemPermission = "RejectSystemPermission",
  RemoveFriend = "RemoveFriend",
  /**
   * Even though this is the same endpoint as `EditSpaceInfo`, it's a different event because there are some views
   * where we only show the option to rename, i.e. the overflow menu of the spaces list.
   */
  RenameSpace = "RenameSpace",
  RenameSpaceCancel = "RenameSpaceCancel",
  RequestResetPasswordEmail = "RequestResetPasswordEmail",
  ResendVerificationEmail = "SentVerificationEmail",
  ResetPasswordAttempt = "ResetPasswordAttempt",
  ReturnedFromSsoLogin = "ReturnedFromSsoLogin",
  RewardAdComplete = "RewardAdComplete",
  RewardAdEmpty = "RewardAdEmpty",
  RewardAdFailed = "RewardAdFailed",
  RewardAdRequested = "RewardAdRequested",
  RewardAdStart = "RewardAdStart",
  SaveAvatarCustomization = "SaveAvatarCustomization",
  SaveAvatarData = "SaveAvatarData",
  SaveProfile = "SaveProfile",
  SaveTokenGateAccessSettings = "SaveTokenGateAccessSettings",
  SelectAnalyticsDateRange = "SelectAnalyticsDateRange",
  SelectAnalyticsKpi = "SelectAnalyticsKpi",
  SelectAnalyticsSpace = "SelectAnalyticsSpace",
  SelectAnalyticsWorld = "SelectAnalyticsWorld",
  SelectCreatorToolkitAvatar = "SelectCreatorToolkitAvatar",
  SelectRpmAvatar = "SelectRpmAvatar",
  SetCanvasViewSizeDefault = "SetCanvasViewSizeDefault",
  SetCanvasViewSizeTheater = "SetCanvasViewSizeTheater",
  SetProfileBackgroundBanner = "SetProfileBackgroundBanner",
  ShareProfile = "ShareProfile",
  ShareSpace = "ShareSpace",
  ShareToFacebook = "ShareToFacebook",
  ShareToLinkedIn = "ShareToLinkedIn",
  ShareToTwitter = "ShareToTwitter",
  SignUp = "SignUp",
  SpacePreviewJoinLive = "SpacePreviewJoinLive",
  SpaceSubscriptionError = "SpaceSubscriptionError",
  SpaceTile = "SpaceTile",
  SpacesSearch = "SpacesSearch",
  SpatialPlusToast = "SpatialPlusToast",
  SplashScreenFinishedLoading = "SplashScreenFinishedLoading",
  StartDanceStreak = "StartDanceStreak",
  SupportForCustomEnv = "SupportForCustomEnv",
  SwitchSpaceInstance = "SwitchSpaceInstance",
  SwitchSpacesTab = "SwitchSpacesTab",
  TakeScreenshot = "TakeScreenshot",
  TurnOffWebcam = "TurnOffWebcam",
  TurnOnWebcam = "TurnOnWebcam",
  UnfollowUser = "UnfollowUser",
  UnloveSpace = "UnlikeSpace",
  UnmuteOther = "UnmuteOther",
  UnmuteSelf = "UnmuteSelf",
  UnpublishSpace = "UnpublishSpace",
  UpgradeSpaceSubscription = "UpgradeSpaceSubscription",
  UpgradeToUnlockAccess = "UpgradeToUnlockAccess",
  UpsellSignUpCta = "UpsellSignUpCta",
  WolvesDenCta = "WolvesDenCta",
}

export type AdStatus = "complete" | "empty" | "failed" | "requested" | "start" | "tooSoon"

// Helper function to return interaction type based on ad type.
export function getAdInteractionType(status: AdStatus) {
  switch (status) {
    case "complete":
      return InteractionName.AdComplete
    case "requested":
      return InteractionName.AdRequested
    case "failed":
      return InteractionName.AdFailed
    case "start":
      return InteractionName.AdStart
    case "tooSoon":
      return InteractionName.AdTooSoon
    case "empty":
    default:
      return InteractionName.AdEmpty
  }
}
