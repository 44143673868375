import Head from "next/head"
import { useRouter } from "next/router"
import { memo } from "react"

import Config from "@spatialsys/web/config"

/**
 * The meta tag so Safari will render the iOS Smart Banner on iPhones and iPads
 *
 * Apple docs: https://developer.apple.com/documentation/webkit/promoting_apps_with_smart_app_banners
 *
 * It doesn't render when dynamically imported with ssr: false.  Apple may be using the initial HTML to determine if the banner should be shown.
 *
 * Apple appears to only be using the meta tag initially rendered by the server and therefore even if we update the URL provided to app-argument, it will not update the URL that the banner links to.
 */
export const AppleMobileSmartBanner = memo(function AppleMobileSmartBanner() {
  const { asPath } = useRouter()

  return (
    <Head>
      <meta
        name="apple-itunes-app"
        content={`app-id=${Config.IOS_APP_STORE_ID}, app-argument=${Config.CANONICAL_URL_ORIGIN}${asPath}`}
      />
    </Head>
  )
})
