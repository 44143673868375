import { last } from "lodash"

import { ActionT, GetActionType, makeActionCreator } from "@spatialsys/js/redux"
import { GetTransactionStatusResponse } from "@spatialsys/js/sapi/purchase/coins"
import { BillingCycle, CoinPackage, PaidPricingPlanId, PricingPlanId } from "@spatialsys/js/sapi/types"
import { Message } from "@spatialsys/js/types"
import {
  ReactAlertMessagePurchaseConfirmation,
  ReactAlertMessagePurchaseConfirmationParams,
} from "@spatialsys/unity/bridge"

export const enum Modals {
  BootstrapMessage,
  BuyCoins,
  CameraControls,
  CreateSpace,
  Debug,
  HostTools,
  Login,
  Pair,
  QuestComplete,
  ReportSpace,
  ConfirmBuyItemModal,
  UpsellBuyItemModal,
  StripePaymentModal,
  IntroFriendsModal,
  AutoFillFriendsModal,
  UnfriendModal,
  FriendsListModal,
  PlusCantUpgradeToSpaceSub,
  SpaceSubUpgrade,
  SelectSpaceToUpgrade,
  SpaceSubCheckout,
  MetaMaskSetupModal,
  ConfirmDeleteAccount,
}

type SpaceSubUpgradeModals =
  | "analytics"
  | "authlessVoiceOrTextChat"
  | "default"
  | "embed"
  | "fullscreenMode"
  | "goLive"
  | "guestAccessRestricted"
  | "limitsExceeded"
  | "screenshareLimitReached"
  | "screenshareUpsell"
  | "storageLimitReached"
  | "tokenGating"
  | "webcam"

type ModalOptionT<T extends Modals, P = undefined> = Message<T, P>

type BootstrapMessageModal = ModalOptionT<Modals.BootstrapMessage>
type BuyCoinsModal = ModalOptionT<Modals.BuyCoins>
type CameraControlsModal = ModalOptionT<Modals.CameraControls>
type CreateSpaceModal = ModalOptionT<Modals.CreateSpace>
type DebugModal = ModalOptionT<Modals.Debug>
type LoginModal = ModalOptionT<Modals.Login, { forceRedirect?: boolean; titleCta?: string }>
type PairModal = ModalOptionT<Modals.Pair>
type ReportSpaceModal = ModalOptionT<Modals.ReportSpace, { spaceID: string; spaceName: string }>
type UnfriendModal = ModalOptionT<Modals.UnfriendModal, { inSpace: boolean; userId: string }>
type FriendsListModal = ModalOptionT<Modals.FriendsListModal, { currentProfileUserId: string }>
type IntroFriendsModal = ModalOptionT<Modals.IntroFriendsModal>
type AutoFillFriendsModal = ModalOptionT<Modals.AutoFillFriendsModal>
type PlusCantUpgradeToSpaceSubModal = ModalOptionT<Modals.PlusCantUpgradeToSpaceSub>
type MetaMaskSetupModal = ModalOptionT<Modals.MetaMaskSetupModal>
type ConfirmDeleteAccountModal = ModalOptionT<Modals.ConfirmDeleteAccount>
export type ConfirmBuyItemModal = ModalOptionT<
  Modals.ConfirmBuyItemModal,
  Pick<ReactAlertMessagePurchaseConfirmation, "callbackID" | "deniedText" | "permitText" | "purchaseConfirmationParams">
>
export type UpsellBuyItemModal = ModalOptionT<
  Modals.UpsellBuyItemModal,
  Pick<ReactAlertMessagePurchaseConfirmation, "callbackID" | "deniedText" | "permitText" | "purchaseConfirmationParams">
>

export type StripePaymentModal = ModalOptionT<
  Modals.StripePaymentModal,
  {
    coinPackageOrPriceId: CoinPackage | string
    /** If defined and `onSuccess` is also defined, no success UI will be shown.
     * A loading spinner will continue to render even after the transaction has completed to avoid brief UI flashing.
     * It is expected that `onSuccess` closes this modal
     */
    hideSuccessUi?: boolean
    itemId?: string
    onCancel?: () => void
    onSuccess?: (response: GetTransactionStatusResponse) => void
    purchaseConfirmationParams?: ReactAlertMessagePurchaseConfirmationParams
    spaceId?: string
  } & ({ itemId: string; itemQuantity: number } | { itemId?: never; itemQuantity?: never })
>

export type SpaceSubCheckoutArgs = {
  planId: Extract<PricingPlanId, "BUSINESS" | "PRO">
  spaceId: string
  term: BillingCycle
}

export type SpaceSubCheckoutModal = ModalOptionT<Modals.SpaceSubCheckout, SpaceSubCheckoutArgs>

export type SpaceSubUpgradeModal = ModalOptionT<
  Modals.SpaceSubUpgrade,
  // If no recommended plan was provided, then it will automatically suggest one based on current usage.
  {
    recommendedPlanId?: PaidPricingPlanId
    source: SpaceSubUpgradeModals
    spaceId: string
  }
>

export type SelectSpaceToUpgradeModalArgs = {
  selectedBillingTerm: BillingCycle
  selectedPricingPlanId: Extract<PricingPlanId, "BUSINESS" | "PRO">
}

export type SelectSpaceToUpdateModal = ModalOptionT<Modals.SelectSpaceToUpgrade, SelectSpaceToUpgradeModalArgs>

export type ModalOption =
  | AutoFillFriendsModal
  | BootstrapMessageModal
  | BuyCoinsModal
  | CameraControlsModal
  | ConfirmBuyItemModal
  | ConfirmDeleteAccountModal
  | CreateSpaceModal
  | DebugModal
  | FriendsListModal
  | IntroFriendsModal
  | LoginModal
  | MetaMaskSetupModal
  | PairModal
  | PlusCantUpgradeToSpaceSubModal
  | ReportSpaceModal
  | SelectSpaceToUpdateModal
  | SpaceSubCheckoutModal
  | SpaceSubUpgradeModal
  | StripePaymentModal
  | UnfriendModal
  | UpsellBuyItemModal
export type ModalsState = ModalOption[]

export const initialModalState: (immediatelyOpenLogin?: boolean) => ModalsState = (immediatelyOpenLogin = false) => {
  if (immediatelyOpenLogin) return [{ type: Modals.Login, payload: {} }]
  return []
}

export enum ModalsActionType {
  CloseModal = "CloseModal",
  OpenModal = "OpenModal",
}

export type CloseModal = ActionT<ModalsActionType.CloseModal, Modals>
export type OpenModal = ActionT<ModalsActionType.OpenModal, ModalOption>

export const ModalActions = {
  closeModal: makeActionCreator<CloseModal>(ModalsActionType.CloseModal),
  openModal: makeActionCreator<OpenModal>(ModalsActionType.OpenModal),
}

export type ModalAction = GetActionType<typeof ModalActions>

export function modalsReducer(state: ModalsState, action: ModalAction): ModalsState {
  switch (action.type) {
    case ModalsActionType.CloseModal:
      return state.filter((modal) => modal.type !== action.payload)
    case ModalsActionType.OpenModal:
      // Don't allow opening the same modal multiple times
      if (last(state)?.type === action.payload.type) {
        return state
      }
      return state.concat(action.payload)
    default:
      return state
  }
}
