import Link from "next/link"
import { memo } from "react"

import { useAppContext } from "@spatialsys/web/app-context"

type MenuItemProps = {
  children: React.ReactNode
  href: string
}

export const MenuItem = memo(function MenuItem(props: MenuItemProps) {
  const { children, href } = props

  const actions = useAppContext((context) => context.actions)
  return (
    <Link
      href={href}
      className="flex items-center gap-2 whitespace-nowrap px-2 py-1 font-heading text-h5 font-semibold no-underline hover:rounded-sm hover:bg-accent"
      onClick={actions.setCategoryMenuClosed}
    >
      {children}
    </Link>
  )
})
