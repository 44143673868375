import * as Sentry from "@sentry/nextjs"
import { AxiosError } from "axios"

import { SAPILogChannel, SapiClientParams, createSapiClient } from "@spatialsys/js/sapi/client"
import { PlatformHeaderString } from "@spatialsys/js/sapi/types"
import { createSwapiClient } from "@spatialsys/js/swapi/client"
import Config from "@spatialsys/web/config"
import { logger } from "@spatialsys/web/logger"

/**
 * Sends a network error to Sentry with the HTTP method, status code, and request URL
 */
const sendErrorToSentry = (error: AxiosError) => {
  Sentry.configureScope((scope) => {
    const tags = {
      http_method: error.config?.method?.toUpperCase(),
      status_code: error.code ?? error.request?.status,
      url: error.config?.url,
    }
    scope.setTags(tags)
    error.message = `Network Error - ${tags.status_code} on ${tags.http_method} ${tags.url}`
    Sentry.captureException(error)
  })
  return Promise.reject(error)
}

// Please use this when logging responses, otherwise tokens will be accidentally logged
function logErrorResponse(error: any) {
  const strippedResponse = {
    data: error?.response?.data,
    status: error?.response?.status,
    statusText: error?.response?.statusText,
    responseURL: error?.response?.request?.responseURL,
    headers: error?.response?.headers,
    config: {},
  }
  logger.error(SAPILogChannel, "Received error from SAPI", strippedResponse)
  return Promise.reject(error)
}

const sapiClientParams: SapiClientParams = {
  apiUrl: Config.API_URL,
  platform: PlatformHeaderString.Web,
  spatialUnityVersion: Config.SPATIAL_UNITY_VERSION,
  channelName: Config.CHANNEL_NAME,
}

export const sapiClient = createSapiClient(sapiClientParams)
// The API URL is empty to send the requests to the same origin that the page is served from.
export const swapiClient = createSwapiClient()

export const attachErrorLoggers = () => {
  sapiClient.updateClient((client) => {
    client.interceptors.response.use(undefined, logErrorResponse)
  })
  swapiClient.updateClient((client) => {
    client.interceptors.response.use(undefined, logErrorResponse)
  })
}

export const attachSentryLogging = () => {
  sapiClient.updateClient((client) => {
    client.interceptors.response.use(undefined, sendErrorToSentry)
  })
  swapiClient.updateClient((client) => {
    client.interceptors.response.use(undefined, sendErrorToSentry)
  })
}

export const setAuthHeaders = (accessToken: string) => {
  sapiClient.setAuthHeader(accessToken)
}

export const clearAuthHeaders = () => {
  sapiClient.unsetAuthHeader()
}

/**
 * Sets the Spatial UID header on all axios API clients, as well as the default axios instance.
 */
export const setSpatialUidHeaders = (spatialUid: string) => {
  sapiClient.setSpatialUidHeader(spatialUid)
  swapiClient.setSpatialUidHeader(spatialUid)
}
