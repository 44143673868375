import { sample } from "lodash"
import Router from "next/router"
import { call, fork, put, select } from "typed-redux-saga/macro"

import { UserData } from "@spatialsys/js/sapi/sapi/users"
import { formatAvatarModelAndThumbnailUrl } from "@spatialsys/js/util/format-avatar-model-and-thumbnail-url"
import { getDefaultAuthlessDisplayName } from "@spatialsys/js/util/get-default-authless-displayname"
import { convertQueryParamToString } from "@spatialsys/js/util/query-params"
import { InteractionName, InteractionType, trackInteraction } from "@spatialsys/react/analytics"
import { UserAvatarStyle } from "@spatialsys/unity/app-state"
import { UnityMessages } from "@spatialsys/unity/bridge"
import { waitUntilChanged, waitUntilExists, waitUntilTrue } from "@spatialsys/use-saga"
import { track } from "@spatialsys/web/analytics"
import { Actions, AppState, Selectors } from "@spatialsys/web/app-state"
import Config from "@spatialsys/web/config"
import { Storage } from "@spatialsys/web/storage"

export function* authlessUserDataSaga(user: UserData) {
  yield* call(initializeAuthlessUserDataSaga, user)

  yield* fork(analyticsSaga)

  // Set once after Unity is booted
  yield* waitUntilTrue((state: AppState) => state.unity.isDoneBooting)
  yield* call(setAuthlessUserInfoInUnity)

  while (true) {
    // Set after the user data is updated and is confirmed
    yield* waitUntilChanged((state: AppState) => state.authlessUserData)
    yield* waitUntilTrue((state: AppState) => Boolean(state.authlessUserData?.confirmationStatus))
    yield* call(setAuthlessUserInfoInUnity)
  }
}

function* initializeAuthlessUserDataSaga(user: UserData) {
  const hasAuthlessUserData = yield* select((state: AppState) => Boolean(state.authlessUserData))
  if (hasAuthlessUserData) {
    return
  }

  const avatars = user.treatmentsParsed.authlessRpmAvatars

  const savedName = Storage.fetch(Storage.AUTHLESS_DISPLAY_NAME_LOCAL_STORAGE_KEY)
  const savedAvatar = Storage.fetch(Storage.AUTHLESS_AVATAR_LOCAL_STORAGE_KEY)
  const savedRpmId = Storage.fetch(Storage.AUTHLESS_RPM_ID_LOCAL_STORAGE_KEY)

  yield* put(
    Actions.setAuthlessUserData({
      name: savedName || getDefaultAuthlessDisplayName(),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      avatar: savedAvatar || sample(avatars)!,
      rpmId: savedRpmId,
      confirmationStatus: savedName && savedAvatar ? "Loaded from storage" : null,
      isModalOpen: false,
    })
  )
}

function* setAuthlessUserInfoInUnity() {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { name, avatar } = (yield* select(Selectors.getAuthlessUserData))!
  const rpmId = convertQueryParamToString(Router.query["avatarId"])
  const flags = yield* waitUntilExists((state: AppState) => state.featureFlags)
  const authlessAvatars = flags.authlessAvatarBodies.split("|")

  const displayName = name.trim()
  UnityMessages.setAuthlessDisplayName(displayName)
  UnityMessages.setUserAvatarStyle(UserAvatarStyle.ReadyPlayerMe)

  // Check that the avatar is a valid authless avatar, otherwise use a random one
  // The list of authless avatars in web (from feature flag) may differ slightly
  // from the list in Unity (treatments)
  const avatarToUse = authlessAvatars.includes(avatar) ? avatar : sample(authlessAvatars)!
  const urls = formatAvatarModelAndThumbnailUrl({
    avatarBaseUrl: Config.AUTHLESS_AVATAR_BASE_URL,
    avatarPath: avatarToUse,
    rpmBaseUrl: Config.RPM_MODELS_BASE_URL,
    rpmId,
  })
  UnityMessages.setAvatarReadyPlayerMeUrl(urls.avatarModelUrl, urls.avatarThumbnailUrl)

  Storage.setItem(Storage.AUTHLESS_DISPLAY_NAME_LOCAL_STORAGE_KEY, displayName)
  Storage.setItem(Storage.AUTHLESS_AVATAR_LOCAL_STORAGE_KEY, avatar)

  if (rpmId) {
    Storage.setItem(Storage.AUTHLESS_RPM_ID_LOCAL_STORAGE_KEY, rpmId)
  }

  UnityMessages.setIsAuthlessInfoConfirmed(true)
}

/**
 * Tracks every time the user confirms the authless name and avatar.
 */
function* analyticsSaga() {
  while (true) {
    // Wait until confirmation status is unset (i.e. authless modal becomes visible)
    yield* waitUntilTrue((state: AppState) => !state.authlessUserData?.confirmationStatus)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const oldData = (yield* select(Selectors.getAuthlessUserData))!

    // Wait until confirmation status is set (i.e. user confirms the choice and the modal closes)
    yield* waitUntilTrue((state: AppState) => Boolean(state.authlessUserData?.confirmationStatus))
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const newData = (yield* select(Selectors.getAuthlessUserData))!

    // Unclear how exactly, but in some cases `oldData` or `newData` can be undefined.
    // See DEV-36118
    if (oldData && newData) {
      yield* call(
        trackInteraction,
        track,
        {
          name: InteractionName.AuthlessUserSetNameAndAvatar,
          type: InteractionType.Submission,
        },
        {
          "Confirmation Method": newData.confirmationStatus,
          "Changed Name": oldData.name !== newData.name,
          "Changed Avatar": oldData.avatar !== newData.avatar,
          "Used Custom RPM Avatar": Boolean(newData.rpmId),
        }
      )
    }
  }
}
