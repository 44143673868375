import { ActionT, GetActionType, makeActionCreator } from "@spatialsys/js/redux"

export type RouteState = {
  path: string
}

export type RouteSeed = {
  initialPath: string
}

export const getInitialRouteState = (seed: RouteSeed): RouteState => ({
  path: seed.initialPath,
})

export enum RouteActionType {
  SetRoute = "SetRoute",
}

export type SetRoute = ActionT<RouteActionType.SetRoute, RouteState>

export const RouteActions = {
  setRoute: makeActionCreator<SetRoute>(RouteActionType.SetRoute),
}

export type RouteAction = GetActionType<typeof RouteActions>

export function routeReducer(state: RouteState, action: RouteAction): RouteState {
  switch (action.type) {
    case RouteActionType.SetRoute:
      return action.payload
    default:
      return state
  }
}
