import { AxiosInstance } from "axios"

import { PurchaseSubscriptionRequest, PurchaseSubscriptionResponse } from "../types"

export function createSpaceSubscriptionEndpoints(client: AxiosInstance) {
  return {
    /**
     * Create a Stripe PaymentIntent
     * @docs https://stripe.com/docs/payments/quickstart
     */
    async createSpaceSubCheckoutSession(args: PurchaseSubscriptionRequest) {
      const response = await client.post<PurchaseSubscriptionResponse>(`/subscription`, args)
      return response.data
    },
  }
}
