import { AxiosInstance } from "axios"

/** POST /auth/session */
export type CreateSessionArgs = {
  idToken: string
  refreshToken: string
}

export type VerifyAndRefreshSessionResponse = {
  expiresAt: number
  idToken: string
}

export function createAuthSessionEndpoints(client: AxiosInstance) {
  return {
    /**
     * Creates an auth session, encoding ID and refresh tokens in a cookie
     */
    async create(args: CreateSessionArgs) {
      return await client.post<void>(``, args)
    },

    /** Clears auth session, clearing the cookie */
    async clear() {
      return await client.delete<void>(`/delete`)
    },

    /**
     * Refresh auth session. This request does not send any parameters,
     * the auth session is parsed from the request cookie.
     */
    async refresh() {
      const response = await client.post<VerifyAndRefreshSessionResponse>(`/refresh`)
      return response.data
    },

    /**
     * Verifies auth session. This request does not send any parameters,
     * the auth session is parsed from the request cookie.
     */
    async verify() {
      const response = await client.post<VerifyAndRefreshSessionResponse>(`/verify`)
      return response.data
    },
  }
}
