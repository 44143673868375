import { AppStateKeyPaths } from "@spatialsys/unity/app-state"

/**
 * A list of state key paths observed by all platforms.
 *
 * If a state key path is not in this list, and is not explicitly observed by the platform
 * updates to that state key path will not be sent through the bridge.
 *
 * NOTE: please try to keep this alphabetically sorted. Use VSCode - Sort Lines Ascending (Ctrl+Shift+P)
 */
export const ObservedUnityKeyPaths: readonly AppStateKeyPaths[] = [
  "contentMetaDataCache",
  "environment/customNFTEnvironmentID",
  "environment/customNFTEnvironmentOptions",
  "environment/disableFirstTutorial",
  "environment/downloadProgress",
  "environment/loadProgress",
  "environment/loadStatus",
  "featureFlags",
  "isAdsSupported",
  "isAuthless",
  "isInAppPurchasesSupported",
  "isLoggedIn",
  "isMouseInUnity",
  "isUnityCapturingInput",
  "loginStatus",
  "rooms",
  "roomSession/actors",
  "roomSession/actorsLookup",
  "roomSession/addContentEnabled",
  "roomSession/backpack",
  "roomSession/camera",
  "roomSession/changeEnvironmentEnabled",
  "roomSession/clearRoomEnabled",
  "roomSession/connection/numParticipantsTotal",
  "roomSession/coreGUIEnabledStatesResult",
  "roomSession/coreGUIOpenStatesResult",
  "roomSession/creatorSocialProfile",
  "roomSession/customEnvironmentMeshTeleportAvailable",
  "roomSession/debug",
  "roomSession/deleteContentEnabled",
  "roomSession/dock/subDockMenu",
  "roomSession/downloadContentEnabled",
  "roomSession/editAdminContentEnabled",
  "roomSession/editRoomNameEnabled",
  "roomSession/features",
  "roomSession/hostInstanceExists",
  "roomSession/initialDataLoadComplete",
  "roomSession/inRoom",
  "roomSession/inRoomAndFullyParticipating",
  "roomSession/inRoomAndFullySynced",
  "roomSession/inSession",
  "roomSession/isAdministrator",
  "roomSession/isAuthlessInfoConfirmed",
  "roomSession/isClearAllFramesAvailable",
  "roomSession/isHideAllEmptyFramesAvailable",
  "roomSession/isReadOnlyActor",
  "roomSession/isRestrictedToViewOnly",
  "roomSession/joinContext",
  "roomSession/lightBoxTargetObjectID",
  "roomSession/livestream",
  "roomSession/loadTemplateEnabled",
  "roomSession/localActorAvatar/body",
  "roomSession/localActorInitialized",
  "roomSession/localActorNumber",
  "roomSession/moderation",
  "roomSession/objectIDToDirectURLLookup",
  "roomSession/participantChatDisabled",
  "roomSession/persistenceStatus",
  "roomSession/questSystem",
  "roomSession/room",
  "roomSession/roomID",
  "roomSession/roomInstanceID",
  "roomSession/roomInstances",
  "roomSession/roomModalType",
  "roomSession/sandbox",
  "roomSession/saveAsTemplateEnabled",
  "roomSession/saveRoomEnabled",
  "roomSession/screenshareEnabled",
  "roomSession/screenshareTimeUsed",
  "roomSession/selectedObject",
  "roomSession/sessionDataSynced",
  "roomSession/sessionStatus",
  "roomSession/settingsMenuVisible",
  "roomSession/sharedState/actorLocationLookup",
  "roomSession/sharedState/actorMetaData",
  "roomSession/sharedState/customEnvironment/customTransformSet",
  "roomSession/sharedState/customEnvironment/meshTeleportEnabled",
  "roomSession/sharedState/customEnvironment/modelURL",
  "roomSession/sharedState/customEnvironment/skyboxURL",
  "roomSession/sharedState/livestream",
  "roomSession/sharedState/locations",
  "roomSession/sharedState/scene/documents",
  "roomSession/sharedState/scene/galleryFrames",
  "roomSession/sharedState/scene/galleryInfos",
  "roomSession/sharedState/scene/galleryPedestals",
  "roomSession/sharedState/scene/hasEmptyGalleryFrames",
  "roomSession/sharedState/scene/hyperlinks",
  "roomSession/sharedState/scene/images",
  "roomSession/sharedState/scene/models",
  "roomSession/sharedState/scene/objects",
  "roomSession/sharedState/scene/pinnedObjects",
  "roomSession/sharedState/scene/portals",
  "roomSession/sharedState/scene/rtcFeeds",
  "roomSession/sharedState/scene/videoPlayers",
  "roomSession/sharedState/serverSaveStatus",
  "roomSession/sharedState/settings/areEmptyGalleryFramesHidden",
  "roomSession/sharedState/settings/disableTextureCompressionForEnvironment",
  "roomSession/sharedState/settings/disableTextureCompressionForObjects",
  "roomSession/sharedState/settings/enableAudioAttenuation",
  "roomSession/sharedState/settings/enableAutosave",
  "roomSession/sharedState/settings/environment",
  "roomSession/sharedState/settings/environmentVariant",
  "roomSession/sharePanelEnabled",
  "roomSession/shop",
  "roomSession/shopActivity",
  "roomSession/shouldBeConnectedToRTC",
  "roomSession/shouldShowLoadingScreen",
  "roomSession/socialProfileMenu",
  "roomSession/spaceLimits",
  "roomSession/speechCaptioningAvailable",
  "roomSession/speechCaptioningAvailable",
  "roomSession/statusMsg",
  "roomSession/uploadTargetFrameObjectID",
  "roomSession/userTools/customEnvironmentTool/currentStep",
  "roomSession/userTools/useTransformGizmos",
  "roomSession/voice/activeProvider",
  "roomSession/voice/isConnected",
  "roomSession/voice/isMuted",
  "roomSession/voice/shouldBeConnected",
  "roomSession/voice/transmissionActive",
  "roomSession/voice/userEnabledVoiceConnection",
  "roomSession/voice/isAuthlessVoiceTransmissionAllowed",
  "roomSession/webcamEnabled",
  "roomSession/webglCursorMode",
  "rtc/channelConnectionStatus",
  "targetFrameRate",
  "toastSystem/toasts",
  "universalShop",
  "userProfile",
  "wallet",
  "windowIsVisible",
]
