import { ReactNode, createContext, useContext } from "react"

import { SwapiClient } from "@spatialsys/js/swapi/client"

const SwapiContext = createContext<SwapiClient>(null as any)

export function SwapiProvider({ children, client }: { children: ReactNode; client: SwapiClient }) {
  return <SwapiContext.Provider value={client}>{children}</SwapiContext.Provider>
}

export function useSwapi() {
  return useContext(SwapiContext)
}
