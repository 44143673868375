import { call, put, select, takeLatest } from "typed-redux-saga/macro"

import { loadScript } from "@spatialsys/js/util/load-script"
import { UnityMessages } from "@spatialsys/unity/bridge"
import { waitUntilTrue } from "@spatialsys/use-saga"
import { ActionType, Actions, AppState } from "@spatialsys/web/app-state"

import { H5_SCRIPT_PARAMETERS, H5_SCRIPT_URL } from "./constants"
import { handleRequestAd } from "./handlers"
import type { AdBreakOptions, AdConfigOptions } from "./types"

export function* h5Saga() {
  // Wait for feature flag and then enable ads.
  yield* waitUntilTrue((state: AppState) => Boolean(state.featureFlags?.h5Ads))

  // Initialize H5 API if uninitialized.
  const apiStatus = yield* select((state: AppState) => state.h5.apiStatus)
  if (apiStatus === "uninitialized") yield* call(initializeH5)
  yield* waitUntilTrue((state: AppState) => state.h5.apiStatus === "initialized")

  // Once initialized, ready to listen for ads.
  UnityMessages.setIsAdsSupported(true)
  yield* takeLatest(ActionType.RequestAd, handleRequestAd)
}

export function* initializeH5() {
  // Initialize API functions `adBreak` and `adConfig`.
  window.adsbygoogle = window.adsbygoogle || []
  const pushAd = (o: AdBreakOptions | AdConfigOptions) => {
    window.adsbygoogle.push(o)
  }
  window.adBreak = pushAd
  window.adConfig = pushAd

  // Load and initialize Ad Placement API.
  try {
    yield* call(loadScript, H5_SCRIPT_URL, H5_SCRIPT_PARAMETERS)
    yield* call(setupAdConfig)
    yield* put(Actions.setH5ApiStatus("initialized"))
  } catch (err) {
    yield* put(Actions.setH5ApiStatus("failed"))
    console.log("Failed to load Ad Placement API:", err)
  }
}

/**
 * Configure Ad Placement API by calling `adConfig`.
 * @see https://developers.google.com/ad-placement/apis/adconfig
 */
export function setupAdConfig() {
  return new Promise<void>((resolve) =>
    window.adConfig({
      sound: "on",
      preloadAdBreaks: "on", // Preload reward ads for greater chance of displaying.
      onReady: resolve,
    })
  )
}
