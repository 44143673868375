import { Slot } from "@radix-ui/react-slot"
import { VariantProps, cva } from "class-variance-authority"
import { forwardRef } from "react"

import { ReactComponent as LoaderIcon } from "@spatialsys/assets/icons/loader.svg"

import { BaseComponentProps } from "../types"
import { cn } from "../utils"

export const loaderVariants = cva("mx-auto rounded-full bg-transparent", {
  variants: {
    color: {
      /** Theme-aware */
      auto: "text-foreground",
      black: "text-black",
      white: "text-white",
      gray: "text-gray-400",
      blue: "text-blue",
    },
    size: {
      small: "h-8 w-8",
      large: "h-16 w-16",
      // Sets width and height equal to the font size
      icon: "icon",
    },
    variant: {
      plain: "animate-spin",
      fancy: "animate-rotate-plane border border-solid border-current",
    },
  },
  defaultVariants: { color: "auto", size: "large", variant: "plain" },
})

export type LoaderProps = VariantProps<typeof loaderVariants> & {
  className?: string
}

/**
 * A visual cue that an action is processing. Use this to indicate to the user to wait — something good is happening!
 *
 * @example
 * <Loader variant="fancy"  />
 *
 * @see https://web-docs.spatial.io/components/loader
 */
const Loader = forwardRef<HTMLDivElement, LoaderProps & BaseComponentProps>((props, ref) => {
  const { asChild = false, className, color, size, variant = "plain", ...rest } = props
  const Comp = asChild ? Slot : "div"
  return (
    <Comp ref={ref} className={cn(loaderVariants({ variant, color, size, className }))} {...rest}>
      {variant === "plain" && <LoaderIcon />}
    </Comp>
  )
})
Loader.displayName = "Loader"

export { Loader, LoaderIcon as LoaderPlain }
