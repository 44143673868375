import * as Sentry from "@sentry/nextjs"
import { fork, select, takeLatest } from "typed-redux-saga/macro"

import { Mixpanel, formatAvatarMixpanelProperties } from "@spatialsys/web/analytics"
import { AppActionType, AppState, SetUser } from "@spatialsys/web/app-state"
import { Storage } from "@spatialsys/web/storage"

import { LOCAL_STORAGE_KEY_AUTHLESS_ID } from "../auth"
import { authlessBadgesSaga } from "./authless-badges-saga"
import { authlessUserDataSaga } from "./authless-user-data-saga"

export function* userSaga() {
  yield* fork(authlessBadgesSaga)
  yield* takeLatest(AppActionType.SetUser, setUserInfo)
}

/**
 * Updates all the places with the user, such as Mixpanel and Sentry
 */
export function* setUserInfo({ payload: user }: SetUser) {
  if (!user) {
    // Register "Authless Status" as a property on authless users.
    Mixpanel.register({ "Authless Status": "Authless" })
    Mixpanel.unregister("Active Subscription")
    return
  }

  const isAuthless = yield* select((state: AppState) => state.auth.useAuthlessToken)
  // Identify the user in Mixpanel and Sentry
  Mixpanel.identify(user.id)
  if (isAuthless) {
    // Don't include $user_id as a property on authless users. This is set automatically
    // by the mixpanel library when calling identify. The first $identify event will have it
    // but none of the subsequent ones will.
    Mixpanel.unregister("$user_id")
    // Register "Authless Status" as a property on authless users.
    Mixpanel.register({ "Authless Status": "Authless" })
    Mixpanel.unregister("Active Subscription")
  } else {
    // If the user is authenticated, unregister "Authless Status".
    Mixpanel.unregister("Authless Status")
    Mixpanel.register({ "Active Subscription": user.subscription?.isActive })
  }
  Sentry.setUser({ id: user.id, email: user.email, username: user.displayName })

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  if (user.avatarData) {
    const avatarProperties = formatAvatarMixpanelProperties(user.avatarData)
    Mixpanel.people.set(avatarProperties)
  }

  if (isAuthless) {
    // Persist authless user ID in local storage
    Storage.setItem(LOCAL_STORAGE_KEY_AUTHLESS_ID, user.id)

    yield* fork(authlessUserDataSaga, user)
  }
}
