import { SAPILobbyType, SAPIRoom } from "@spatialsys/js/sapi/types"
import { LobbyType, RoomData } from "@spatialsys/unity/app-state"
import Config from "@spatialsys/web/config"

export * from "./lib/facebook-share"
export * from "./lib/feature-flag-space"
export * from "./lib/linkedin-share"
export * from "./lib/twitter-share"

export const SPATIAL_PARK_PATH = "park"

type FormatSpacePathArgs = {
  /**
   * The prefix to use for the space path. Defaults to `/s`. Must start with `/` and not end with `/`.
   */
  pathPrefix?: string
  id: string
  slug: string
  shareId?: string
  instanceId?: string
  enableRtcInEmbed?: boolean
}

/**
 * Given a space ID, slug, and optional share ID, returns a string that
 * is the full URL path to a space, e.g. `/s/slug-id` or `/embed/slug-id`.
 */
export function formatSpacePath({ pathPrefix = "/s", ...rest }: FormatSpacePathArgs) {
  return `${pathPrefix}/${formatSpaceSlugAndId(rest)}`
}

type FormatSpaceSlugAndIdArgs = Omit<FormatSpacePathArgs, "pathPrefix">
/**
 * Given a space ID, slug, and optional share ID, returns a string with the slug, id, and share ID
 * in a format that can be used in a URL path.
 * @example
 * const path = `/s/${formatSpaceSlugAndId({ id: "63b739e666e3d6a752116b78", slug: "Punch-Hero", shareId: "2179761834265457822" })}`
 */
export function formatSpaceSlugAndId({ id, slug, shareId, instanceId, enableRtcInEmbed }: FormatSpaceSlugAndIdArgs) {
  let path = `${slug}-${id}`

  const queryParams = new URLSearchParams()

  if (shareId) {
    queryParams.append("share", shareId)
  }

  if (instanceId) {
    queryParams.append("instance", instanceId)
  }

  if (enableRtcInEmbed) {
    queryParams.append("enableRtc", "1")
  }

  const queryString = queryParams.toString()
  if (queryString) {
    path += `?${queryString}`
  }

  return path
}

export function isSpacePath(path: string) {
  return path.startsWith("/s/") || path.startsWith("/embed/")
}
export function getSpaceIdAndSlugFromUrl(path: string, pathPrefix = "/s") {
  const [slugAndId] = path.slice(pathPrefix.length + 1).split("?")
  return slugAndId
}

/**
 * @param path a valid space path, e.g. /s/slug-id
 * @returns
 */
export function getSpaceIdFromUrl(path: string, pathPrefix = "/s") {
  const [slugAndId] = path.slice(pathPrefix.length + 1).split("?")
  return getSpaceIdFromSlugAndId(slugAndId)
}

export function getSpaceIdFromSlugAndId(slugAndId: string): string {
  return slugAndId.split("-").pop() as string
}

/** Subset type of SAPIRoom for overflow menu */
export type SAPIRoomSubsetSpaceShare = Pick<SAPIRoom, "id" | "slug" | "lobbyType" | "shareID">

export function formatShareUrl(baseUrl: string, room: SAPIRoomSubsetSpaceShare | RoomData) {
  if (room.lobbyType === LobbyType.PublicLobby || room.lobbyType === SAPILobbyType.Public) {
    return `${baseUrl}/${SPATIAL_PARK_PATH}`
  }
  const pathname = formatSpacePath({ id: room.id, slug: room.slug, shareId: room.shareID })

  return `${baseUrl}${pathname}`
}

export const getShareUrl = (room: SAPIRoomSubsetSpaceShare | RoomData | SAPIRoom) => {
  return formatShareUrl(Config.WEB_URL, room)
}
