import { AxiosInstance } from "axios"

import { UnityPackage } from "../types"

export type GetUnityPackageRequest = { packageId: string }

export type GetUnityBuildLogsRequest = { sku: string; version: number }
export type GetUnityBuildLogsResponse = { downloadUrl: string }

export function createPackagesEndpoints(client: AxiosInstance) {
  return {
    /** Get a Unity Package by ID */
    async getPackage({ packageId }: GetUnityPackageRequest) {
      const response = await client.get<UnityPackage>(`/${packageId}`)
      return response.data
    },
    async getPackageBuildLog({ sku, version }: GetUnityBuildLogsRequest) {
      version = Math.round(version) // expect integer value
      const response = await client.get<GetUnityBuildLogsResponse>(`/${sku}/${version}/log`)
      return response.data
    },
  }
}
