import { AxiosInstance } from "axios"

import { GetCategoriesMenuResponse } from "../../types"

export function createMenuEndpoints(client: AxiosInstance) {
  return {
    async getCategories() {
      const response = await client.get<GetCategoriesMenuResponse>("/categories")
      return response.data
    },
  }
}
