import { produce } from "immer"

import { ActionT, GetActionType, makeActionCreator } from "@spatialsys/js/redux"

export const MIN_INTERVAL_BETWEEN_ADS_MS = 3 * 60 * 1000 // 3 minutes
export const INITIAL_TIME_BETWEEN_MIDGAME_ADS_MS = 2 * 60 * 1000 // 2 minutes
export const INITIAL_MS_OFFSET = MIN_INTERVAL_BETWEEN_ADS_MS - INITIAL_TIME_BETWEEN_MIDGAME_ADS_MS

export type AdsState = {
  /**
   * The last time that a midgame ad was played in ms since epoch.
   */
  midgameAdLastPlayedTs: number
}

export const initialAdsState: () => AdsState = () => ({
  midgameAdLastPlayedTs: Date.now(),
})

export enum AdsActionType {
  PlayMidgameAd = "PlayMidgameAd",
  SetMidgameAdLastPlayed = "SetMidgameAdLastPlayed",
}

export type PlayMidgameAd = ActionT<AdsActionType.PlayMidgameAd>
export type SetMidgameAdLastPlayed = ActionT<AdsActionType.SetMidgameAdLastPlayed, number>

export const AdsActions = {
  playMidgameAd: makeActionCreator<PlayMidgameAd>(AdsActionType.PlayMidgameAd),
  setMidgameAdLastPlayed: makeActionCreator<SetMidgameAdLastPlayed>(AdsActionType.SetMidgameAdLastPlayed),
}

export type AdsAction = GetActionType<typeof AdsActions>

export const adsReducer = (state: AdsState, action: AdsAction): AdsState => {
  switch (action.type) {
    case AdsActionType.PlayMidgameAd:
      return produce(state, (draft) => void (draft.midgameAdLastPlayedTs = Date.now()))
    case AdsActionType.SetMidgameAdLastPlayed:
      return produce(state, (draft) => void (draft.midgameAdLastPlayedTs = action.payload))
    default:
      return state
  }
}
