/**
 *
 * @param {Object} config
 * @param {string} config.spatialUnityVersion
 * @param {string} config.shortShaLastUnityChange
 * @param {string} config.channel
 * @returns
 */
const createDefaultUnityVersionString = ({ spatialUnityVersion, shortShaLastUnityChange, channel }) =>
  `Spatial_${spatialUnityVersion}_${shortShaLastUnityChange}_${channel}`

module.exports = { createDefaultUnityVersionString }
