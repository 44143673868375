import { AxiosInstance } from "axios"

import { getFirstSapiError } from "../utils/sapi-error"

export type SamlSsoLoginArgs = {
  email: string
}

/** From login or signup */
export type SamlSsoResponseWithToken = {
  providerID: string
}

export function createSamlSsoLoginEndpoint(client: AxiosInstance) {
  return {
    /** Attempts to log in with SAML SSO email */
    login: async function (args: SamlSsoLoginArgs): Promise<SamlSsoResponseWithToken> {
      try {
        const response = await client.get<SamlSsoResponseWithToken>(`/saml?email=${args.email}`)
        return response.data
      } catch (error) {
        throw getFirstSapiError(error) ?? error
      }
    },
  }
}
