import { WebGLAvailability, getWebGLAvailability } from "@spatialsys/js/util/is-web-gl-available"
import { isMobileUA, isUnsupportedSafariVersion } from "@spatialsys/web/user-agent"

export type BrowserNotSupportedReason = "mobile" | "safari-15.4" | "webgl" | null

export function getBrowserSupport(userAgent: string): BrowserNotSupportedReason {
  if (isMobileUA(userAgent)) {
    return "mobile"
  }
  if (isUnsupportedSafariVersion(userAgent)) {
    return "safari-15.4"
  }
  const webglAvailability = getWebGLAvailability()
  if (webglAvailability.availability !== WebGLAvailability.WebGL2) {
    return "webgl"
  }
  return null
}
