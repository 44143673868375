import { AxiosInstance } from "axios"

import { CheckoutRequest } from "../types"

export function createCheckoutEndpoint(client: AxiosInstance) {
  return {
    /** Buy an item with coins */
    async checkout(req: CheckoutRequest) {
      const response = await client.post<void>(``, req)
      return response.data
    },
  }
}
