import { AxiosInstance, RawAxiosRequestHeaders } from "axios"

import { EditRoomRequest } from "../sapi/rooms"
import { GetFeedRequest, GetFeedResponse } from "../spaces/spaces"

/**
 * This is the "legacy" client, where we threw all requests under `/v2`.
 * SAPI is moving towards simplifing path by omitting `api/` and keep it as simple as `/v2`.
 */
export function createV2Endpoints(client: AxiosInstance) {
  return {
    editSpace: createEditSpaceEndpoint(client),
    getFeed: createGetFeedEndpoint(client),
  }
}

function createEditSpaceEndpoint(client: AxiosInstance) {
  return async function editSpace({
    roomId,
    name,
    description,
    tags,
    isCustomThumbnail,
    seoDescription,
    seoTitle,
    thumbnailImage,
    thumbnailVideo,
    posterImage,
    posterVideo,
    logo,
    fullScreenModeEnabled,
  }: EditRoomRequest): Promise<void> {
    const data = new FormData()

    if (name) {
      data.append("name", name)
    }
    // allow empty strings for seoTitle and seoDescription to clear them
    if (typeof seoTitle !== "undefined") {
      data.append("seoTitle", seoTitle)
    }
    if (typeof seoDescription !== "undefined") {
      data.append("seoDescription", seoDescription)
    }
    if (description) {
      data.append("description", description)
    }
    if (tags) {
      tags.forEach((tag) => {
        data.append("tags", tag)
      })
    }
    if (isCustomThumbnail != null) {
      data.append("isCustomThumbnail", isCustomThumbnail.toString())
    }
    if (thumbnailImage) {
      data.append("thumbnailImage", thumbnailImage)
    }
    if (thumbnailVideo) {
      data.append("thumbnailVideo", thumbnailVideo)
    }
    if (posterImage) {
      data.append("posterImage", posterImage)
    }
    if (posterVideo) {
      data.append("posterVideo", posterVideo)
    }
    if (logo) {
      data.append("logo", logo)
    }
    data.append("fullScreenModeEnabled", fullScreenModeEnabled ? "true" : "false")

    const response = await client.patch(`/spaces/${roomId}`, data)
    return response.data
  }
}

function createGetFeedEndpoint(client: AxiosInstance) {
  return async function getFeed({ userAgent }: GetFeedRequest = {}): Promise<GetFeedResponse> {
    const headers: RawAxiosRequestHeaders = {}
    if (userAgent) {
      headers["user-agent"] = userAgent
    }
    const resp = await client.get<GetFeedResponse>(`/feed`, { headers })
    return resp.data
  }
}
