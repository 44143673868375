"use client"

import { toast as sonnerToast } from "sonner"

import { Loader } from "../loader"

const loading: typeof sonnerToast.loading = (message, data) => {
  return sonnerToast.loading(message, {
    icon: <Loader className="h-5 w-5 text-foreground" />,
    ...data,
  })
}

const promise: typeof sonnerToast.promise = (promise, data) => {
  return sonnerToast.promise(promise, {
    icon: <Loader className="h-5 w-5 text-foreground" />,
    ...data,
  })
}

/**
 * Use `.bind` to override the `loading` and `promise` functions without incurring an infinite callback cycle.
 */
const toastCopy = sonnerToast.bind({})

/**
 * Assign the member functions from `sonnerToast` to our custom toast object,
 * overriding the `loading` and `promise` functions to include our custom loader.
 */
export const toast = Object.assign(toastCopy, {
  ...sonnerToast,
  loading: loading,
  promise: promise,
})
