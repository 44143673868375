import Head from "next/head"

import "@spatialsys/web/core/css/nprogress.scss"
import "@spatialsys/web/core/js/components/app/app.scss"
import "./styles.css"
// Global CSS from third-party libraries
import "@solana/wallet-adapter-react-ui/styles.css"

import Config from "@spatialsys/web/config"
import { AppleMobileSmartBanner } from "@spatialsys/web/core/js/components/ios-smart-banner/ios-smart-banner"
import { DescriptionTags, OgImageTag, TitleTags } from "@spatialsys/web/core/js/components/seo/seo"
import { GoogleTagManagerScript } from "@spatialsys/web/core/js/components/third-party-scripts/google-tag-manager"

import { AppSkeleton, type CustomAppProps } from "../components/pages-app-skeleton"

export default function CustomApp(props: CustomAppProps) {
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        {TitleTags("Spatial - Create Immersive UGC, Virtual Classrooms, Experiential Marketing", { suffix: false })}
        {DescriptionTags(
          "Join 2M+ creators & brands building and publishing social games, brand experiences, virtual learning, galleries, onboarding, & training. No-code + Unity-based tools. Web (No Download Required), Mobile, VR."
        )}
        {OgImageTag(`${Config.PUBLIC_ASSETS_BASE_URL}/spatial.io/og-image.jpg`)}
        {Config.DEPLOYMENT_ENV === "production" && (
          <meta name="facebook-domain-verification" content="q97ab3qc382m09iyz1fgpno68ejdf1" />
        )}
        {Config.DEPLOYMENT_ENV === "production" && (
          <meta name="google-adsense-account" content="ca-pub-7849013300997404" />
        )}
      </Head>

      <AppleMobileSmartBanner />

      {/* Third party scripts */}
      {Config.DEPLOYMENT_ENV === "production" && <GoogleTagManagerScript />}

      <AppSkeleton {...props} />
    </>
  )
}
