import {
  InfiniteData,
  QueryClient,
  QueryKey,
  UseInfiniteQueryOptions,
  UseQueryOptions,
  useInfiniteQuery,
  useQuery,
} from "@tanstack/react-query"

import {
  GetAnotherUsersFriendsResponse,
  GetAnotherUsersMembershipResponse,
  GetAvatarsByUsernameRequest,
  GetAvatarsByUsernameResponse,
  GetBadgesByUsernameRequest,
  GetBadgesByUsernameResponse,
  GetEmotesByUsernameRequest,
  GetEmotesByUsernameResponse,
  GetPublishedSpacesByUsernameRequest,
  GetPublishedSpacesByUsernameResponse,
} from "@spatialsys/js/sapi/profiles"
import { SocialProfileV2, SpaceAndCreator } from "@spatialsys/js/sapi/types"

import { useSapi } from "../use-sapi"

export const GET_ANOTHER_USERS_LIST_OF_FRIENDS_QUERY_KEY = "GET_ANOTHER_USERS_LIST_OF_FRIENDS"
export const GET_ANOTHER_USERS_MEMBERSHIP_STATUS_QUERY_KEY = "GET_ANOTHER_USERS_MEMBERSHIP_STATUS"
const GET_PROFILE_BY_USERNAME_QUERY_KEY = "GET_PROFILE_BY_USERNAME"
const GET_PROFILE_BY_USER_ID_QUERY_KEY = "GET_PROFILE_BY_USER_ID"

export function makeGetProfileByUsernameQueryKey(username: string) {
  return [GET_PROFILE_BY_USERNAME_QUERY_KEY, username]
}
export function makeGetProfileByUserIdQueryKey(userId: string) {
  return [GET_PROFILE_BY_USER_ID_QUERY_KEY, userId]
}

export const useGetProfileByUsernameQuery = (
  username: string,
  options?: UseQueryOptions<SocialProfileV2, unknown, SocialProfileV2, string[]>
) => {
  const sapiClient = useSapi()
  return useQuery({
    queryKey: makeGetProfileByUsernameQueryKey(username),
    queryFn: () => sapiClient.profiles.getProfileByUsername(username),
    staleTime: 1000 * 60,
    ...options,
  })
}

export const updateGetProfileByUsernameCache = async (
  queryClient: QueryClient,
  username: string,
  mutateFn: (profile: SocialProfileV2 | undefined) => SocialProfileV2 | undefined
) => {
  const querySocialProfileKey = [GET_PROFILE_BY_USERNAME_QUERY_KEY, username]
  const prevSocialProfileData = queryClient.getQueryData<SocialProfileV2>(querySocialProfileKey)

  await queryClient.cancelQueries(querySocialProfileKey)
  queryClient.setQueryData<SocialProfileV2>(querySocialProfileKey, mutateFn)

  return prevSocialProfileData
}

export const useGetProfileByUserIdQuery = (
  userId: string,
  options?: UseQueryOptions<SocialProfileV2, unknown, SocialProfileV2, string[]>
) => {
  const sapiClient = useSapi()
  return useQuery({
    queryKey: makeGetProfileByUserIdQueryKey(userId),
    queryFn: () => sapiClient.profiles.getProfileByUserId(userId),
    staleTime: 1000 * 60,
    ...options,
  })
}

export const updateGetProfileByUserIdCache = async (
  queryClient: QueryClient,
  userId: string,
  mutateFn: (profile: SocialProfileV2 | undefined) => SocialProfileV2 | undefined
) => {
  const querySocialProfileKey = [GET_PROFILE_BY_USER_ID_QUERY_KEY, userId]
  const prevSocialProfileData = queryClient.getQueryData<SocialProfileV2>(querySocialProfileKey)

  await queryClient.cancelQueries(querySocialProfileKey)
  queryClient.setQueryData<SocialProfileV2>(querySocialProfileKey, mutateFn)

  return prevSocialProfileData
}

export const useGetAnotherUsersFriends = (
  userId: string,
  options?: UseQueryOptions<GetAnotherUsersFriendsResponse, unknown, GetAnotherUsersFriendsResponse, string[]>
) => {
  const sapiClient = useSapi()
  return useQuery({
    queryKey: [GET_ANOTHER_USERS_LIST_OF_FRIENDS_QUERY_KEY, userId],
    queryFn: () => sapiClient.profiles.getAnotherUsersFriends({ userId }),
    ...options,
  })
}

export const useGetAnotherUsersMembership = (
  userId: string,
  options?: UseQueryOptions<GetAnotherUsersMembershipResponse, unknown, GetAnotherUsersMembershipResponse, string[]>
) => {
  const sapiClient = useSapi()
  return useQuery({
    queryKey: [GET_ANOTHER_USERS_MEMBERSHIP_STATUS_QUERY_KEY, userId],
    queryFn: () => sapiClient.profiles.getAnotherUsersMembership({ userId }),
    ...options,
  })
}

const GET_PUBLISHED_SPACES_BY_USERNAME_QUERY_KEY = "GET_PUBLISHED_SPACES_BY_USERNAME"

export function makeGetPublishedSpacesByUsernameQueryKey(args: GetPublishedSpacesByUsernameRequest) {
  const { username, ...rest } = args
  return [GET_PUBLISHED_SPACES_BY_USERNAME_QUERY_KEY, username, rest]
}

/**
 * Get user's published spaces by username
 */
export const useGetPublishedSpacesByUsernameQuery = (
  args: GetPublishedSpacesByUsernameRequest,
  options?: UseInfiniteQueryOptions<GetPublishedSpacesByUsernameResponse, unknown, GetPublishedSpacesByUsernameResponse>
) => {
  const sapiClient = useSapi()
  const argsWithDefaultPagination = { count: args.count ?? 250 }
  return useInfiniteQuery({
    queryKey: makeGetPublishedSpacesByUsernameQueryKey({ username: args.username, ...argsWithDefaultPagination }),
    queryFn: ({ pageParam }) =>
      sapiClient.profiles.getPublishedSpacesByUsername({
        username: args.username,
        ...argsWithDefaultPagination,
        skip: pageParam,
      }),
    getNextPageParam: (lastPage, allPages) =>
      lastPage.count < argsWithDefaultPagination.count ? undefined : argsWithDefaultPagination.count * allPages.length,
    staleTime: 5 * 1000 * 60,
    ...options,
  })
}

export const updatePublishedSpacesByUsernameCache = async (
  queryClient: QueryClient,
  mutateFn: (spaces: SpaceAndCreator[]) => SpaceAndCreator[]
) => {
  const queryKey = [GET_PUBLISHED_SPACES_BY_USERNAME_QUERY_KEY]
  await queryClient.cancelQueries({ queryKey: [GET_PUBLISHED_SPACES_BY_USERNAME_QUERY_KEY] })

  const prevCacheData = queryClient.getQueriesData<InfiniteData<GetPublishedSpacesByUsernameResponse>>({ queryKey })

  queryClient.setQueriesData<InfiniteData<GetPublishedSpacesByUsernameResponse>>({ queryKey }, (prev) => {
    if (prev) {
      return {
        ...prev,
        pages: prev.pages.map((page) => ({
          ...page,
          spaces: mutateFn(page.spaces),
        })),
      }
    }

    return undefined
  })

  return prevCacheData
}

export const rollBackPublishedSpacesByUsernameCache = (
  queryClient: QueryClient,
  previousData?: [QueryKey, InfiniteData<GetPublishedSpacesByUsernameResponse> | undefined][]
) => {
  if (previousData) {
    previousData.forEach(([key, data]) => {
      if (data) {
        queryClient.setQueryData<InfiniteData<GetPublishedSpacesByUsernameResponse>>(key, data)
      }
    })
  }
}

const GET_BADGES_BY_USERNAME_QUERY_KEY = "GET_BADGES_BY_USERNAME"

export function makeGetBadgesByUsernameQueryKey(args: GetBadgesByUsernameRequest) {
  const { username, ...rest } = args
  return [GET_BADGES_BY_USERNAME_QUERY_KEY, username, rest]
}

/**
 * Get user's badges by username
 */
export const useGetBadgesByUsernameQuery = (
  args: GetBadgesByUsernameRequest,
  options?: UseInfiniteQueryOptions<GetBadgesByUsernameResponse>
) => {
  const sapiClient = useSapi()
  const argsWithDefaultPagination = { count: args.count ?? 250 }
  return useInfiniteQuery({
    queryKey: makeGetBadgesByUsernameQueryKey({ username: args.username, ...argsWithDefaultPagination }),
    queryFn: ({ pageParam }) =>
      sapiClient.profiles.getBadgesByUsername({
        username: args.username,
        ...argsWithDefaultPagination,
        skip: pageParam,
      }),
    getNextPageParam: (lastPage, allPages) =>
      lastPage.count < argsWithDefaultPagination.count ? undefined : argsWithDefaultPagination.count * allPages.length,
    staleTime: 5 * 1000 * 60,
    ...options,
  })
}

const GET_AVATARS_BY_USERNAME_QUERY_KEY = "GET_AVATARS_BY_USERNAME"
export function makeGetAvatarsByUsernameQueryKey(args: GetAvatarsByUsernameRequest) {
  const { username, ...rest } = args
  return [GET_AVATARS_BY_USERNAME_QUERY_KEY, username, rest]
}

/**
 * Get user's avatars by username
 */
export const useGetAvatarsByUsernameQuery = (
  args: GetAvatarsByUsernameRequest,
  options?: UseInfiniteQueryOptions<GetAvatarsByUsernameResponse>
) => {
  const sapiClient = useSapi()
  const argsWithDefaultPagination = { count: args.count ?? 250 }
  return useInfiniteQuery({
    queryKey: makeGetAvatarsByUsernameQueryKey({ username: args.username, ...argsWithDefaultPagination }),
    queryFn: ({ pageParam }) =>
      sapiClient.profiles.getAvatarsByUsername({
        username: args.username,
        ...argsWithDefaultPagination,
        skip: pageParam,
      }),
    getNextPageParam: (lastPage, allPages) =>
      lastPage.count < argsWithDefaultPagination.count ? undefined : argsWithDefaultPagination.count * allPages.length,
    staleTime: 5 * 1000 * 60,
    ...options,
  })
}

const GET_EMOTES_BY_USERNAME_QUERY_KEY = "GET_EMOTES_BY_USERNAME"
export function makeGetEmotesByUsernameQueryKey(args: GetEmotesByUsernameRequest) {
  const { username, ...rest } = args
  return [GET_EMOTES_BY_USERNAME_QUERY_KEY, username, rest]
}

/**
 * Get user's emotes by username
 */
export const useGetEmotesByUsernameQuery = (
  args: GetEmotesByUsernameRequest,
  options?: UseInfiniteQueryOptions<GetEmotesByUsernameResponse>
) => {
  const sapiClient = useSapi()
  const argsWithDefaultPagination = { count: args.count ?? 250 }
  return useInfiniteQuery({
    queryKey: makeGetEmotesByUsernameQueryKey({ username: args.username, ...argsWithDefaultPagination }),
    queryFn: ({ pageParam }) =>
      sapiClient.profiles.getEmotesByUsername({
        username: args.username,
        ...argsWithDefaultPagination,
        skip: pageParam,
      }),
    getNextPageParam: (lastPage, allPages) =>
      lastPage.count < argsWithDefaultPagination.count ? undefined : argsWithDefaultPagination.count * allPages.length,
    staleTime: 5 * 1000 * 60,
    ...options,
  })
}
