import {
  InfiniteData,
  QueryClient,
  QueryKey,
  UseInfiniteQueryOptions,
  UseMutationOptions,
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query"
import { useMemo } from "react"

import {
  DeleteCustomThumbnailRequest,
  GetTagAutocompleteRequest,
  GetTagAutocompleteResponse,
  ReportSpaceRequest,
  ReportSpaceResponse,
  SetCustomThumbnailRequest,
  SetCustomThumbnailResponse,
  SetSpaceLovedRequest,
  UploadReportImageRequest,
} from "@spatialsys/js/sapi/spaces/space"
import {
  CreateCategoryRequest,
  CreateFeedVersionRequest,
  DeleteCategoryRequest,
  EditCategoryRequest,
  FeaturedCarouselItem,
  FeedConfigVersionHistoryResponse,
  GetCategoriesRequest,
  GetCategoryRequest,
  GetCategorySpacesResponse,
  GetFeaturedCarouselResponse,
  GetFeedConfigHistoryResponse,
  GetFeedForConfigRequest,
  GetFeedResponse,
  GetLovedSpacesRequest,
  GetLovedSpacesResponse,
  GetSpacesQueryType,
  GetSpacesRequest,
  GetSpacesResponse,
  SearchSpacesRequest,
  SearchSpacesResponse,
  UpdateFeedVersionRequest,
} from "@spatialsys/js/sapi/spaces/spaces"
import {
  CategoryDetailsResponse,
  FeedCategory,
  FeedComponent,
  FeedConfigResponse,
  SpaceAndCreator,
  SpaceMetadata,
  SpacePreviewResponse,
} from "@spatialsys/js/sapi/types"

import { debouncedQueryFunction } from "../debounced-query-function"
import { rollBackPublishedSpacesByUsernameCache, updatePublishedSpacesByUsernameCache } from "../profiles"
import { rollbackUsersPublishedSpacesCache } from "../sapi/user"
import { useSapi } from "../use-sapi"
import { updateGetUsersPublishedSpacesCache } from "../users/profiles"

export const enum SpacesQueryKeys {
  GetCategories = "getCategories",
  GetCategory = "getCategory",
  GetCategoryDetails = "getCategoryDetails",
  GetCategorySpaces = "getCategorySpaces",
  GetFeaturedCarousel = "getFeaturedCarousel",
  GetLovedSpaces = "getLovedSpaces",
  GetFeed = "getFeed",
  GetFeedConfig = "getFeedConfig",
  GetFeedHistory = "getFeedConfigHistory",
  GetFeedVersionHistory = "getFeedVersionHistory",
  GetSpaces = "getSpaces",
  GetSpacePreview = "GetSpacePreview",
  GetBatchSpacePreviews = "GetBatchSpacePreviews",
  GetSpaceCategories = "getSpaceCategories",
  SearchSpaces = "searchSpaces",
  GetTagAutocomplete = "getTagAutocomplete",
}

/** Reverts the query cache to its previous state using the provided context, i.e. when a mutation fails */
export const rollBackGetSpacesCache = (
  queryClient: QueryClient,
  previousSpaces?: [QueryKey, GetSpacesResponse | undefined][]
) => {
  if (previousSpaces) {
    previousSpaces.forEach(([key, spaces]) => {
      if (spaces) {
        queryClient.setQueryData<GetSpacesResponse>(key, spaces)
      }
    })
  }
}

/**
 * Mutates a space optimistically, updating the `getSpaces` result in any cache that begins with {@link SpacesQueryKeys.GetSpaces}
 * Spaces can show up in multiple tabs which is why we fuzzy match on the key If a space is guaranteed to show up in only one tab,
 * we could instead optimistically updated only the key [SpacesQueryKeys.GetSpaces, queryType]
 *
 * @param queryClient the react-query client
 * @param mutateFn a function that is called with a list of spaces. This function should update the list with
 * a new list if spaces, if applicable.
 * @returns the cached data for all queries with key {@link SpacesQueryKeys.GetSpaces}
 */
export const mutateGetSpacesOptimistically = async (
  queryClient: QueryClient,
  mutateFn: (spaces: SpaceAndCreator[]) => SpaceAndCreator[]
) => {
  // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
  await queryClient.cancelQueries([SpacesQueryKeys.GetSpaces])
  // Get all the spaces in the query cache using fuzzy matching on `SpacesQueryKeys.GetSpaces`
  const previousSpaces = queryClient.getQueriesData<GetSpacesResponse>([SpacesQueryKeys.GetSpaces])

  if (previousSpaces) {
    previousSpaces.forEach(([key]) => {
      queryClient.setQueryData<GetSpacesResponse>(key, (prev) => {
        return prev ? { ...prev, spaces: mutateFn(prev.spaces) } : undefined
      })
    })
  }

  return previousSpaces
}

/**
 * Fetches list of spaces.
 */
export const useGetSpacesQuery = (
  args: GetSpacesRequest,
  options?: Omit<
    UseQueryOptions<GetSpacesResponse, unknown, GetSpacesResponse, (SpacesQueryKeys | GetSpacesQueryType)[]>,
    "queryKey" | "queryFn"
  >
) => {
  const { type } = args
  const sapiClient = useSapi()
  return useQuery({
    ...options,
    queryKey: [SpacesQueryKeys.GetSpaces, type],
    queryFn: () => sapiClient.spaces.spaces.getSpaces({ type }),
  })
}

/** Reverts the query cache to its previous state using the provided context, i.e. when a mutation fails */
export const rollBackGetFeedCache = (
  queryClient: QueryClient,
  prevFeeds?: [QueryKey, GetFeedResponse | undefined][]
) => {
  if (!prevFeeds) return
  for (const [key, prev] of prevFeeds) {
    if (prev) {
      queryClient.setQueryData<GetFeedResponse>(key, prev)
    }
  }
}

/**
 * Mutates a space optimistically, updating the `getFeed` result in any cache that begins with {@link SpacesQueryKeys.GetFeed}
 *
 * @param queryClient the react-query client
 * @param mutateFn a function that is called with a list of feed items. This function should update each feed item with
 * a new list of spaces, if applicable.
 * @returns the cached data for all queries matching key {@link SpacesQueryKeys.GetFeed}
 */
export const mutateGetFeedOptimistically = async (
  queryClient: QueryClient,
  mutateFn: (feed: GetFeedResponse) => GetFeedResponse
) => {
  // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
  await queryClient.cancelQueries({ queryKey: [SpacesQueryKeys.GetFeed] })
  const previousFeeds = queryClient.getQueriesData<GetFeedResponse>({ queryKey: [SpacesQueryKeys.GetFeed] })

  queryClient.setQueriesData<GetFeedResponse>({ queryKey: [SpacesQueryKeys.GetFeed] }, (prev) => {
    if (prev) {
      return mutateFn(prev)
    }

    return prev
  })

  return previousFeeds
}

/**
 * Fetches list of sections to be displayed in the feed.
 */
export const useGetFeedQuery = (
  args: { userAgent?: string },
  options?: Omit<
    UseQueryOptions<GetFeedResponse, unknown, GetFeedResponse, (SpacesQueryKeys | string | undefined)[]>,
    "queryKey" | "queryFn"
  >
) => {
  const sapiClient = useSapi()
  return useQuery({
    staleTime: 5000 * 60,
    queryKey: [SpacesQueryKeys.GetFeed, args.userAgent],
    queryFn: () => sapiClient.spaces.spaces.getFeed({ userAgent: args.userAgent }),
    ...options,
  })
}

/**
 * Fetches list of sections to be displayed in the feed.
 */
export const useGetFeedV2Query = (
  args: { userAgent?: string },
  options?: Omit<
    UseQueryOptions<GetFeedResponse, unknown, GetFeedResponse, (SpacesQueryKeys | string | undefined)[]>,
    "queryKey" | "queryFn"
  >
) => {
  const sapiClient = useSapi()
  return useQuery({
    staleTime: 5000 * 60,
    queryKey: [SpacesQueryKeys.GetFeed, args.userAgent],
    queryFn: () => sapiClient.v2.getFeed(),
    ...options,
  })
}

/**
 * Fetches the feed for the given configuration.
 */
export const useGetFeedForConfigQuery = (
  args: GetFeedForConfigRequest,
  options?: Omit<UseQueryOptions<GetFeedResponse, unknown, GetFeedResponse, string[]>, "queryKey" | "queryFn">
) => {
  const argsKey = useMemo(() => JSON.stringify(args), [args])
  const sapiClient = useSapi()
  return useQuery({
    staleTime: 5000 * 60,
    ...options,
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [SpacesQueryKeys.GetFeed, argsKey],
    queryFn: () => sapiClient.spaces.spaces.getFeedForConfig(args),
  })
}

/**
 * Fetches the current feed configuration
 */
export const useGetFeedConfigQuery = (
  options?: Omit<UseQueryOptions<FeedConfigResponse, unknown, FeedConfigResponse, string[]>, "queryKey" | "queryFn">
) => {
  const sapiClient = useSapi()
  return useQuery({
    staleTime: 5000 * 60,
    ...options,
    queryKey: [SpacesQueryKeys.GetFeedConfig] as string[],
    queryFn: () => sapiClient.spaces.spaces.getFeedConfig(),
  })
}

/**
 * Creates a new Feed Version
 */
export const useCreateFeedVersionMutation = (options?: UseMutationOptions<void, unknown, CreateFeedVersionRequest>) => {
  const sapiClient = useSapi()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (args: CreateFeedVersionRequest) => sapiClient.spaces.spaces.createFeedVersion(args),
    onSuccess: () => {
      void queryClient.invalidateQueries([SpacesQueryKeys.GetFeedConfig])
      void queryClient.invalidateQueries([SpacesQueryKeys.GetFeedHistory])
    },
    ...options,
  })
}

export const useUpdateFeedVersionMutation = (options?: UseMutationOptions<void, unknown, UpdateFeedVersionRequest>) => {
  const sapiClient = useSapi()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (args: UpdateFeedVersionRequest) => sapiClient.spaces.spaces.updateFeedVersion(args),
    onSuccess: (_, args) => {
      void queryClient.invalidateQueries([SpacesQueryKeys.GetFeedConfig])
      void queryClient.invalidateQueries([SpacesQueryKeys.GetFeedHistory])
      void queryClient.invalidateQueries([SpacesQueryKeys.GetFeedVersionHistory, args.version])
    },
    ...options,
  })
}

/**
 * Fetches the feed configuration history
 */
export const useGetFeedHistoryQuery = (
  args: { count: number },
  options?: UseInfiniteQueryOptions<
    GetFeedConfigHistoryResponse,
    unknown,
    GetFeedConfigHistoryResponse,
    GetFeedConfigHistoryResponse,
    [string]
  >
) => {
  const sapiClient = useSapi()
  return useInfiniteQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [SpacesQueryKeys.GetFeedHistory],
    queryFn: ({ pageParam = 0 }) =>
      sapiClient.spaces.spaces.getFeedConfigHistory({ start: pageParam, count: args.count }),
    getNextPageParam: (lastPage, allPages) => {
      return lastPage.length < args.count ? undefined : args.count * allPages.length
    },
    staleTime: 5000 * 60,
    ...options,
  })
}

/**
 * Fetches the feed configuration history
 */
export const useGetFeedVersionHistoryQuery = (
  args: { count: number; version: number },
  options?: UseInfiniteQueryOptions<
    FeedConfigVersionHistoryResponse,
    unknown,
    FeedConfigVersionHistoryResponse,
    FeedConfigVersionHistoryResponse,
    [string, number]
  >
) => {
  const sapiClient = useSapi()
  return useInfiniteQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [SpacesQueryKeys.GetFeedVersionHistory, args.version],
    queryFn: ({ pageParam = 0 }) =>
      sapiClient.spaces.spaces.getFeedConfigHistoryForVersion({
        start: pageParam,
        count: args.count,
        version: args.version,
      }),
    getNextPageParam: (lastPage, allPages) => {
      return lastPage.length < args.count ? undefined : args.count * allPages.length
    },
    staleTime: 5000 * 60,
    ...options,
  })
}

/** Reverts the query cache to its previous state using the provided context, i.e. when a mutation fails */
export const rollBackSearchSpacesCache = (
  queryClient: QueryClient,
  previousSpaces?: [QueryKey, SearchSpacesResponse | undefined][]
) => {
  if (previousSpaces) {
    previousSpaces.forEach(([key, spaces]) => {
      if (spaces) {
        queryClient.setQueryData<SearchSpacesResponse>(key, spaces)
      }
    })
  }
}

/**
 * Updates searchSpaces cache, targeting all cache keys that begin with {@link SpacesQueryKeys.SearchSpaces}
 * Spaces can show up in multiple search queries which is why we fuzzy match on the key
 *
 * @param queryClient the react-query client
 * @param mutateFn a function that is called with a list of spaces. This function should update the list with
 * a new list of spaces, if applicable.
 * @returns the cached data for all queries with key {@link SpacesQueryKeys.SearchSpaces}
 */
export const updateSearchSpacesCache = async (
  queryClient: QueryClient,
  mutateFn: (spaces: SpaceAndCreator[]) => SpaceAndCreator[]
) => {
  // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
  await queryClient.cancelQueries([SpacesQueryKeys.SearchSpaces])
  // Get all the spaces in the query cache using fuzzy matching on `SpacesQueryKeys.SearchSpaces`
  const previousSpaces = queryClient.getQueriesData<SearchSpacesResponse>([SpacesQueryKeys.SearchSpaces])

  if (previousSpaces) {
    previousSpaces.forEach(([key]) => {
      queryClient.setQueryData<SearchSpacesResponse>(key, (prev) => {
        return prev ? { ...prev, results: mutateFn(prev.results) } : undefined
      })
    })
  }

  return previousSpaces
}

/**
 * Search for a space
 */
export const useSearchSpacesQuery = (
  args: SearchSpacesRequest,
  options?: Omit<
    UseQueryOptions<SearchSpacesResponse, unknown, SearchSpacesResponse, (SpacesQueryKeys | string)[]>,
    "queryKey" | "queryFn"
  > & { debounceMs?: number }
) => {
  const { query } = args
  const sapiClient = useSapi()
  return useQuery({
    enabled: Boolean(query), // Only execute if the query string is truthy
    ...options,
    queryKey: [SpacesQueryKeys.SearchSpaces, query],
    queryFn: debouncedQueryFunction(() => sapiClient.spaces.spaces.searchSpaces({ query }), options?.debounceMs ?? 0),
  })
}

/** Reverts the query cache to its previous state using the provided context, i.e. when a mutation fails */
export const rollBackGetCategorySpacesCache = (
  queryClient: QueryClient,
  previousData?: [QueryKey, InfiniteData<GetCategorySpacesResponse> | undefined][]
) => {
  if (previousData) {
    previousData.forEach(([key, data]) => {
      if (data) {
        queryClient.setQueryData<InfiniteData<GetCategorySpacesResponse>>(key, data)
      }
    })
  }
}

/**
 * Updates getCategorySpaces cache, targeting all cache keys that begin with {@link SpacesQueryKeys.GetCategorySpaces}
 *
 * @param queryClient the react-query client
 * @param mutateFn a function that is called with a list of spaces. This function should update the list with
 * a new list of spaces, if applicable.
 * @returns the cached data for all queries with key {@link SpacesQueryKeys.GetCategorySpaces}
 */
export const updateGetCategorySpacesCache = async (
  queryClient: QueryClient,
  mutateFn: (spaces: SpaceAndCreator[]) => SpaceAndCreator[]
) => {
  // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
  await queryClient.cancelQueries([SpacesQueryKeys.SearchSpaces])
  const previousCategories = queryClient.getQueriesData<InfiniteData<GetCategorySpacesResponse>>([
    SpacesQueryKeys.GetCategorySpaces,
  ])
  if (previousCategories) {
    for (const [key] of previousCategories) {
      // Go through each category
      queryClient.setQueryData<InfiniteData<GetCategorySpacesResponse>>(key, (category) => {
        if (!category) {
          return undefined
        }
        return {
          ...category,
          // Go through each page of the category's data and update the spaces in that page
          pages: category.pages.map((data) => {
            return {
              ...data,
              results: mutateFn(data.results),
            }
          }),
        }
      })
    }
  }

  return previousCategories
}

/**
 * Get spaces in a category
 */
export const useGetCategorySpacesQuery = (
  args: { category: string; count: number },
  options?: Omit<
    UseInfiniteQueryOptions<
      GetCategorySpacesResponse,
      unknown,
      GetCategorySpacesResponse,
      GetCategorySpacesResponse,
      (SpacesQueryKeys | string)[]
    >,
    "queryKey" | "queryFn"
  >
) => {
  const { category, count } = args
  const sapiClient = useSapi()
  return useInfiniteQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [SpacesQueryKeys.GetCategorySpaces, category],
    queryFn: ({ pageParam = 0 }) => sapiClient.spaces.spaces.getCategorySpaces({ category, count, start: pageParam }),
    getNextPageParam: (lastPage, allPages) => {
      // If the last result returned fewer than the requested count, there are no more results
      return lastPage.results.length < count ? undefined : count * allPages.length
    },
    ...options,
  })
}

/** Reverts the query cache to its previous state using the provided context, i.e. when a mutation fails */
export const rollBackGetCategoriesCache = (
  queryClient: QueryClient,
  previousCategories?: [QueryKey, FeedCategory[] | undefined][]
) => {
  if (previousCategories) {
    previousCategories.forEach(([key, categories]) => {
      if (categories) {
        queryClient.setQueryData<FeedCategory[]>(key, categories)
      }
    })
  }
}

/**
 * Mutates a category optimistically, updating the `getCategories` result in any cache that begins with {@link SpacesQueryKeys.GetCategories}
 *
 * @param queryClient the react-query client
 * @param mutateFn a function that is called with a category. This function should update the category, if applicable.
 * @returns the cached data for all queries with key {@link SpacesQueryKeys.GetCategories}
 */
const mutateGetCategoriesOptimistically = async (
  queryClient: QueryClient,
  mutateFn: (categories: FeedCategory[]) => FeedCategory[]
) => {
  // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
  await queryClient.cancelQueries([SpacesQueryKeys.GetCategories])
  // Get all the categories in the query cache using fuzzy matching on `SpacesQueryKeys.GetCategories`
  const previousCategories = queryClient.getQueriesData<FeedCategory[]>([SpacesQueryKeys.GetCategories])

  if (previousCategories) {
    previousCategories.forEach(([key]) => {
      queryClient.setQueryData<FeedCategory[] | undefined>(key, (prev) => {
        if (prev) {
          return mutateFn(prev)
        }

        return prev
      })
    })
  }

  return previousCategories
}

/**
 * Mutates a category optimistically, updating all caches that contain the category.
 * @param queryClient The react-query client.
 * @param categoryId The ID (slug) of the category to mutate.
 * @param mutateCategoryFn A pure function that is called with the space to mutate. This function should return the mutated space.
 * @returns `rollbackFn` - a function that can be called to revert the query cache to its previous state, i.e. when a mutation fails.
 */
const mutateCategoryOptimistically = async (
  queryClient: QueryClient,
  categoryId: string,
  mutateCategoryFn: (category: FeedCategory) => FeedCategory
) => {
  const mutateFn = (data: FeedCategory) => {
    if (data.slug !== categoryId) {
      return data
    }
    return mutateCategoryFn(data)
  }

  const mutateArrayFn = (array: FeedCategory[]) => array.map(mutateFn)
  const previousCategories = await mutateGetCategoriesOptimistically(queryClient, mutateArrayFn)

  return (queryClient: QueryClient) => {
    rollBackGetCategoriesCache(queryClient, previousCategories)
  }
}

/**
 * Get all categories matching a query
 */
export const useGetCategories = (
  args: GetCategoriesRequest,
  options?: Omit<
    UseQueryOptions<FeedCategory[], unknown, FeedCategory[], (SpacesQueryKeys | string)[]>,
    "queryKey" | "queryFn"
  > & { debounceMs?: number }
) => {
  const { query } = args
  const sapiClient = useSapi()
  return useQuery({
    ...options,
    queryKey: [SpacesQueryKeys.GetCategories, query ?? ""],
    queryFn: debouncedQueryFunction(() => sapiClient.spaces.spaces.getCategories(args), options?.debounceMs ?? 0),
  })
}

export const useCategoryDetailsQuery = (
  args: GetCategoryRequest,
  options?: Omit<
    UseQueryOptions<CategoryDetailsResponse, unknown, CategoryDetailsResponse, (SpacesQueryKeys | string)[]>,
    "queryKey" | "queryFn"
  >
) => {
  const sapiClient = useSapi()
  return useQuery({
    ...options,
    queryKey: [SpacesQueryKeys.GetCategoryDetails, args.category],
    queryFn: () => sapiClient.spaces.spaces.getCategoryDetails({ category: args.category }),
  })
}

export const useCreateCategoryMutation = (
  options?: UseMutationOptions<
    void,
    unknown,
    CreateCategoryRequest,
    {
      previousCategories: [QueryKey, FeedCategory[] | undefined][]
    }
  >
) => {
  const sapiClient = useSapi()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (args: CreateCategoryRequest) => sapiClient.spaces.spaces.createCategory(args),
    onMutate: async ({ category }) => {
      const mutateFn = (categories: FeedCategory[]) => categories.concat(category)

      const previousCategories = await mutateGetCategoriesOptimistically(queryClient, mutateFn)

      return {
        previousCategories,
      }
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [SpacesQueryKeys.GetCategories] })
      void queryClient.invalidateQueries({ queryKey: [SpacesQueryKeys.GetSpaceCategories] })
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (_err, _args, context) => {
      rollBackGetCategoriesCache(queryClient, context?.previousCategories)
    },
    // It's common practice to refetch all data after error or success using `onSettled`
    // However, since there is so much data in the various query keys, we will avoid doing so in this scenario
    ...options,
  })
}

export const useEditCategoryMutation = (
  options?: UseMutationOptions<
    void,
    unknown,
    EditCategoryRequest,
    {
      rollbackFn: (queryClient: QueryClient) => void
    }
  >
) => {
  const sapiClient = useSapi()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (args: EditCategoryRequest) => sapiClient.spaces.spaces.editCategory(args),
    onMutate: async ({ category }) => {
      const mutateFn = (_: FeedCategory) => {
        return { ...category }
      }

      const rollbackFn = await mutateCategoryOptimistically(queryClient, category.slug, mutateFn)

      return {
        rollbackFn,
      }
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (_err, _args, context) => {
      context?.rollbackFn(queryClient)
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [SpacesQueryKeys.GetCategories] })
      void queryClient.invalidateQueries({ queryKey: [SpacesQueryKeys.GetSpaceCategories] })
    },
    // It's common practice to refetch all data after error or success using `onSettled`
    // However, since there is so much data in the various query keys, we will avoid doing so in this scenario
    ...options,
  })
}

export const useDeleteCategoryMutation = (
  options?: UseMutationOptions<
    void,
    unknown,
    DeleteCategoryRequest,
    {
      previousCategories: [QueryKey, FeedCategory[] | undefined][]
    }
  >
) => {
  const sapiClient = useSapi()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (args: DeleteCategoryRequest) => sapiClient.spaces.spaces.deleteCategory(args),
    onMutate: async ({ category }) => {
      const mutateFn = (categories: FeedCategory[]) => categories.filter((c) => c.slug !== category)

      const previousCategories = await mutateGetCategoriesOptimistically(queryClient, mutateFn)

      // Return a context object with the snapshotted value
      return {
        previousCategories,
      }
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (_err, _args, context) => {
      rollBackGetCategoriesCache(queryClient, context?.previousCategories)
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [SpacesQueryKeys.GetCategories] })
      void queryClient.invalidateQueries({ queryKey: [SpacesQueryKeys.GetSpaceCategories] })
    },
    // It's common practice to refetch all data after error or success using `onSettled`
    // However, since there is so much data in the various query keys, we will avoid doing so in this scenario
    ...options,
  })
}

/** Reverts the query cache to its previous state using the provided context, i.e. when a mutation fails */
export const rollBackGetLovedSpacesCache = (
  queryClient: QueryClient,
  previousLovedSpaces?: [QueryKey, GetLovedSpacesResponse | undefined][]
) => {
  if (previousLovedSpaces) {
    previousLovedSpaces.forEach(([key, prev]) => {
      if (prev) {
        queryClient.setQueryData<GetLovedSpacesResponse>(key, prev)
      }
    })
  }
}

/**
 * Mutates a space optimistically, updating the `getLovedSpaces` result in any cache that begins with {@link SpacesQueryKeys.GetLovedSpaces}
 *
 * @param queryClient the react-query client
 * @param mutateFn a function that is called with a dictionary of space IDs and whether the space is loved or not.
 * @returns the cached data for all queries with key {@link SpacesQueryKeys.GetLovedSpaces}
 */
export const mutateGetLovedSpacesOptimistically = async (
  queryClient: QueryClient,
  mutateFn: (spacesLoved: GetLovedSpacesResponse["spacesLoved"]) => GetLovedSpacesResponse["spacesLoved"]
) => {
  // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
  await queryClient.cancelQueries([SpacesQueryKeys.GetLovedSpaces])
  // Get all the spaces in the query cache using fuzzy matching on `SpacesQueryKeys.GetLovedSpaces`
  const previousLovedSpaces = queryClient.getQueriesData<GetLovedSpacesResponse>([SpacesQueryKeys.GetLovedSpaces])

  previousLovedSpaces.forEach(([key]) => {
    queryClient.setQueryData<GetLovedSpacesResponse>(key, (prev) => {
      if (prev) {
        return {
          ...prev,
          spacesLoved: mutateFn(prev.spacesLoved),
        }
      }

      return prev
    })
  })

  return previousLovedSpaces
}

/** Reverts the query cache to its previous state using the provided context, i.e. when a mutation fails */
export const rollBackGetFeaturedCarouselCache = (queryClient: QueryClient, prev?: GetFeaturedCarouselResponse) => {
  if (prev) {
    queryClient.setQueryData<GetFeaturedCarouselResponse>([SpacesQueryKeys.GetFeaturedCarousel], prev)
  }
}

/**
 * Mutates a space optimistically, updating the `getLovedSpaces` result in any cache that begins with {@link SpacesQueryKeys.GetLovedSpaces}
 *
 * @param queryClient the react-query client
 * @param mutateFn a function that is called with a dictionary of space IDs and whether the space is loved or not.
 * @returns the cached data for all queries with key {@link SpacesQueryKeys.GetLovedSpaces}
 */
export const mutateGetFeaturedCarouselOptimistically = async (
  queryClient: QueryClient,
  mutateFn: (featured: GetFeaturedCarouselResponse) => GetFeaturedCarouselResponse
) => {
  // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
  await queryClient.cancelQueries([SpacesQueryKeys.GetFeaturedCarousel])
  const prevFeaturedCarousel = queryClient.getQueryData<GetFeaturedCarouselResponse>([
    SpacesQueryKeys.GetFeaturedCarousel,
  ])

  queryClient.setQueryData<GetFeaturedCarouselResponse>([SpacesQueryKeys.GetFeaturedCarousel], (prev) => {
    if (prev) {
      return mutateFn(prev)
    }

    return prev
  })

  return prevFeaturedCarousel
}

/**
 * Mutates a space optimistically, updating all caches that contain the space.
 * @param queryClient The react-query client.
 * @param spaceId The ID of the space to mutate.
 * @param mutateSpaceFn A pure function that is called with the space to mutate. This function should return the mutated space.
 * @returns `rollbackFn` - a function that can be called to revert the query cache to its previous state, i.e. when a mutation fails.
 */
export const mutateSpaceOptimistically = async (
  queryClient: QueryClient,
  spaceId: string,
  mutateSpaceFn: (space: SpaceMetadata) => SpaceMetadata
) => {
  const mutateFn = (data: SpaceAndCreator) => {
    if (data.space.id !== spaceId) {
      return data
    }
    return {
      ...data,
      space: mutateSpaceFn(data.space),
    }
  }

  const mutateFeedComponentFn = <T extends { data: { spaces: Array<SpaceAndCreator> } }>(item: T): T => {
    const newSpaces = item.data.spaces.map((space: SpaceAndCreator) => {
      if (space.space?.id !== spaceId) {
        return space
      }
      return { ...space, space: mutateSpaceFn(space.space) }
    })
    return { ...item, data: { ...item.data, spaces: newSpaces } }
  }

  const mutateArrayFn = (array: SpaceAndCreator[]) => array.map(mutateFn)
  const previousSpaces = await mutateGetSpacesOptimistically(queryClient, mutateArrayFn)
  const previousPublishedSpaces = await updateGetUsersPublishedSpacesCache(queryClient, mutateArrayFn)
  const previousPublishedSpacesByUsername = await updatePublishedSpacesByUsernameCache(queryClient, mutateArrayFn)
  const previousSearchSpaces = await updateSearchSpacesCache(queryClient, mutateArrayFn)
  const previousGetCategorySpaces = await updateGetCategorySpacesCache(queryClient, mutateArrayFn)
  const previousGetBatchSpacePreviews = await updateGetBatchSpacePreviewsCache(queryClient, mutateArrayFn)
  const previousSpacePreview = await updateGetSpacePreviewCache(queryClient, spaceId, mutateFn)

  const previousFeaturedCarousel = await mutateGetFeaturedCarouselOptimistically(queryClient, (data) => {
    return data.map((slide): FeaturedCarouselItem => {
      if (!slide.space || slide.space.id !== spaceId) {
        return slide
      }
      return { ...slide, space: mutateSpaceFn(slide.space) }
    })
  })

  const previousFeeds = await mutateGetFeedOptimistically(queryClient, (data) => {
    return {
      feedItems: data.feedItems.map((item): FeedComponent => mutateFeedComponentFn(item)),
    }
  })

  return (queryClient: QueryClient) => {
    rollBackGetSpacesCache(queryClient, previousSpaces)
    rollbackUsersPublishedSpacesCache(queryClient, previousPublishedSpaces)
    rollBackPublishedSpacesByUsernameCache(queryClient, previousPublishedSpacesByUsername)
    rollBackSearchSpacesCache(queryClient, previousSearchSpaces)
    rollBackGetCategorySpacesCache(queryClient, previousGetCategorySpaces)
    rollbackGetBatchSpacePreviewsCache(queryClient, previousGetBatchSpacePreviews)
    rollBackGetSpacePreviewCache(queryClient, spaceId, previousSpacePreview)
    rollBackGetFeaturedCarouselCache(queryClient, previousFeaturedCarousel)
    rollBackGetFeedCache(queryClient, previousFeeds)
  }
}

/**
 * Given a list of spaces, returns if the user has loved each space or not
 */
export const useGetLovedSpacesQuery = (
  args: GetLovedSpacesRequest,
  options?: Omit<
    UseQueryOptions<GetLovedSpacesResponse, unknown, GetLovedSpacesResponse, (SpacesQueryKeys | string)[]>,
    "queryKey" | "queryFn"
  >
) => {
  const sapiClient = useSapi()
  return useQuery({
    ...options,
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [SpacesQueryKeys.GetLovedSpaces, ...args.spaceIds],
    queryFn: () => sapiClient.spaces.spaces.getLovedSpaces(args),
  })
}

/**
 * Fetches the featured carousel
 */
export const useGetFeaturedCarouselQuery = (
  options?: Omit<
    UseQueryOptions<GetFeaturedCarouselResponse, unknown, GetFeaturedCarouselResponse, SpacesQueryKeys[]>,
    "queryKey" | "queryFn"
  >
) => {
  const sapiClient = useSapi()
  return useQuery({
    /**
     * This data rarely changes, and if it does it would cause a huge UI mixup — so set
     * staleTime to Infinity to avoid ever refetching
     */
    staleTime: Infinity,
    cacheTime: Infinity,
    queryKey: [SpacesQueryKeys.GetFeaturedCarousel],
    queryFn: sapiClient.spaces.spaces.getFeaturedCarousel,
    ...options,
  })
}

/**
 * Loves/unloves a space for the current user, then increments/decrements the love count depending on what the loved state changed to.
 * Updates the query cache optimistically, updating the space in all query caches that match `GET_SPACES_QUERY_KEY`
 * If the mutation fails, restores the cache to its previous value
 */
export const useSetSpaceLovedMutation = ({
  onError,
  onMutate,
  ...options
}: UseMutationOptions<
  void,
  unknown,
  SetSpaceLovedRequest,
  {
    previousLovedSpaces: [QueryKey, GetLovedSpacesResponse | undefined][]
    rollbackFn: (queryClient: QueryClient) => void
  }
> = {}) => {
  const sapiClient = useSapi()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (args: SetSpaceLovedRequest) => sapiClient.spaces.space.setSpaceLoved(args),
    onMutate: async ({ spaceId, isLoved }) => {
      const mutateFn = (space: SpaceMetadata) => {
        // Increment/decrement like count depending on state change.
        const loveCount = space.likeCount + (isLoved ? 1 : -1)
        return { ...space, likeCount: loveCount, liked: isLoved }
      }
      const rollbackFn = await mutateSpaceOptimistically(queryClient, spaceId, mutateFn)

      const previousLovedSpaces = await mutateGetLovedSpacesOptimistically(queryClient, (data) => {
        if (data[spaceId]) {
          return { ...data, [spaceId]: false }
        }
        return { ...data, [spaceId]: true }
      })

      await onMutate?.({ spaceId, isLoved })

      // Return a context object with the snapshotted value
      return {
        previousLovedSpaces,
        rollbackFn,
      }
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (_err, _args, context) => {
      rollBackGetLovedSpacesCache(queryClient, context?.previousLovedSpaces)
      context?.rollbackFn(queryClient)
      onError?.(_err, _args, context)
    },
    // It's common practice to refetch all data after error or success using `onSettled`
    // However, since there is so much data in the various query keys, we will avoid doing so in this scenario
    ...options,
  })
}

/**
 * Sets a space's thumbnail
 * Updates the query cache, updating the space in all query caches that match `GET_SPACES_QUERY_KEY`
 * If the mutation fails, restores the cache to its previous value
 */
export const useSetCustomThumbnailMutation = (
  options?: UseMutationOptions<
    SetCustomThumbnailResponse,
    unknown,
    SetCustomThumbnailRequest,
    {
      rollbackFn: () => void
    }
  >
) => {
  const sapiClient = useSapi()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: sapiClient.spaces.space.setCustomThumbnail,
    onSuccess: async (response, args) => {
      const mutateFn = (space: SpaceMetadata): SpaceMetadata => ({
        ...space,
        roomThumbnails: {
          ...space.roomThumbnails,
          customGetUrl: response.customGetUrl,
        },
      })

      const rollbackFn = await mutateSpaceOptimistically(queryClient, args.roomId, mutateFn)

      return {
        rollbackFn,
      }
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (_err, args, context) => {
      context?.rollbackFn()
    },
    // It's common practice to refetch all data after error or success using `onSettled`
    // However, since there is so much data in the various query keys, we will avoid doing so in this scenario
    ...options,
  })
}

/**
 * Deletes a space's thumbnail
 * Updates the query cache optimistically, removing the thumbnail from all query caches that match `GET_SPACES_QUERY_KEY`
 * If the mutation fails, restores the cache to its previous value
 */
export const useDeleteCustomThumbnailMutation = (
  options?: UseMutationOptions<
    void,
    unknown,
    DeleteCustomThumbnailRequest,
    {
      rollbackFn: (queryClient: QueryClient) => void
    }
  >
) => {
  const sapiClient = useSapi()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (args: DeleteCustomThumbnailRequest) => {
      await sapiClient.spaces.space.deleteCustomThumbnail(args)
    },
    onMutate: async ({ roomId }) => {
      const updateSpaceAndCreatorFn = (space: SpaceMetadata): SpaceMetadata => ({
        ...space,
        roomThumbnails: {
          ...space.roomThumbnails,
          customGetUrl: "",
        },
      })

      const rollbackFn = await mutateSpaceOptimistically(queryClient, roomId, updateSpaceAndCreatorFn)

      // Return a context object with the snapshotted value
      return { rollbackFn }
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (_err, _roomToDelete, context) => {
      context?.rollbackFn(queryClient)
    },
    // It's common practice to refetch all data after error or success using `onSettled`
    // However, since there is so much data in the various query keys, we will avoid doing so in this scenario
    ...options,
  })
}

export const useTagAutocompleteQuery = (
  request: GetTagAutocompleteRequest,
  options?: UseQueryOptions<GetTagAutocompleteResponse> & { debounceMs?: number }
) => {
  const { query } = request
  const sapiClient = useSapi()
  return useQuery({
    queryKey: [SpacesQueryKeys.GetTagAutocomplete, query],
    queryFn: debouncedQueryFunction(
      () => sapiClient.spaces.space.getTagAutocomplete(request),
      options?.debounceMs ?? 0
    ),
    // No need to fetch if input is empty.
    enabled: query.length > 0,
    // Setting keepPreviousData prevents flickering when user types.
    // When input is empty, we don't want to show any previous suggestions, so we set keepPreviousData to true only if non-empty.
    keepPreviousData: query.length > 0,
    staleTime: 60 * 1000,
    ...options,
  })
}

/**
 * Choose a 5 minute stale time because this data does not change frequently, but is used
 * in various places like `RoomHead` and `LoadingSplash`. Setting a longer stale time prevents too many
 * requests in quick succession
 */
const GET_SPACE_PREVIEW_STALE_TIME = 5000 * 60

export const useGetBatchSpacePreviewsQuery = (
  spaceIds: string[],
  options?: UseQueryOptions<SpaceAndCreator[], unknown, SpaceAndCreator[], (SpacesQueryKeys | string)[]>
) => {
  const sapiClient = useSapi()
  return useQuery({
    queryKey: [SpacesQueryKeys.GetBatchSpacePreviews, ...spaceIds],
    queryFn: () => sapiClient.spaces.spaces.getBatchSpacesPreview(spaceIds),
    staleTime: GET_SPACE_PREVIEW_STALE_TIME,
    ...options,
  })
}

export const rollbackGetBatchSpacePreviewsCache = (
  queryClient: QueryClient,
  prevEntries: [QueryKey, SpaceAndCreator[] | undefined][]
) => {
  if (!prevEntries) return
  for (const [key, prev] of prevEntries) {
    if (prev) {
      queryClient.setQueryData<SpaceAndCreator[]>(key, prev)
    }
  }
}

/**
 * Updates query cache for `getBatchSpaces`
 *
 * @param queryClient the react-query client
 * @param spaceIds the list of space IDs. Forms the query key `[{@link SpacesQueryKeys.GetBatchSpacesPreview}, ...spaceIds]`
 * @param updateFn a function that is called with a list of `SpaceAndCreator[]`.
 * @returns the cached data for the current cached entry `[{@link SpacesQueryKeys.GetBatchSpacesPreview}, ...spaceIds]`
 */
export const updateGetBatchSpacePreviewsCache = async (
  queryClient: QueryClient,
  updateFn: (response: SpaceAndCreator[]) => SpaceAndCreator[]
) => {
  // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
  await queryClient.cancelQueries({ queryKey: [SpacesQueryKeys.GetBatchSpacePreviews] })
  const prevQueries = queryClient.getQueriesData<SpaceAndCreator[]>({
    queryKey: [SpacesQueryKeys.GetBatchSpacePreviews],
  })

  queryClient.setQueriesData<SpaceAndCreator[]>({ queryKey: [SpacesQueryKeys.GetBatchSpacePreviews] }, (prev) => {
    if (!prev) return undefined

    return updateFn(prev)
  })

  return prevQueries
}

/**
 * Fetches space data. This query can be accessed without authentication.
 */
export const useGetSpacePreviewQuery = (
  spaceId: string,
  options?: UseQueryOptions<SpacePreviewResponse, unknown, SpacePreviewResponse, (SpacesQueryKeys | string)[]>
) => {
  const sapiClient = useSapi()
  return useQuery({
    queryKey: [SpacesQueryKeys.GetSpacePreview, spaceId],
    queryFn: () => sapiClient.spaces.space.getSpacePreview(spaceId),
    staleTime: GET_SPACE_PREVIEW_STALE_TIME,
    enabled: Boolean(spaceId),
    ...options,
  })
}

/** Reverts the query cache to its previous state using the provided context, i.e. when a mutation fails */
export const rollBackGetSpacePreviewCache = (
  queryClient: QueryClient,
  spaceId: string,
  previousResponse?: SpaceAndCreator
) => {
  const queryKey = [SpacesQueryKeys.GetSpacePreview, spaceId]
  if (previousResponse) {
    queryClient.setQueryData<SpaceAndCreator>(queryKey, previousResponse)
  }
}

/**
 * Updates the an individual entry for `getSpacePreview`
 *
 * @param queryClient the react-query client
 * @param spaceId the spaceId. Forms the query key `[{@link SpacesQueryKeys.GetSpacePreview}, spaceId]`
 * @param updateFn a function that is called with a single `SpaceAndCreator`.
 * @returns the cached data for the current cached entry `[{@link SpacesQueryKeys.GetSpacePreview}, spaceId]`
 */
export const updateGetSpacePreviewCache = async (
  queryClient: QueryClient,
  spaceId: string,
  updateFn: (response: SpaceAndCreator) => SpaceAndCreator
) => {
  const queryKey = [SpacesQueryKeys.GetSpacePreview, spaceId]
  // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
  await queryClient.cancelQueries(queryKey)
  const prevQueryData = queryClient.getQueryData<SpaceAndCreator>(queryKey)

  queryClient.setQueryData<SpaceAndCreator>(queryKey, (prev) => {
    if (!prev) return undefined

    return updateFn(prev)
  })

  return prevQueryData
}

/**
 * Fetches a space's categories
 */
export const useGetSpaceCategories = (
  spaceId: string,
  options?: UseQueryOptions<FeedCategory[], unknown, FeedCategory[], (SpacesQueryKeys | string)[]>
) => {
  const sapiClient = useSapi()
  return useQuery({
    queryKey: [SpacesQueryKeys.GetSpaceCategories, spaceId],
    queryFn: () => sapiClient.spaces.space.getSpaceCategories(spaceId),
    ...options,
  })
}

/**
 * Sends a report for this space
 */
export const useReportSpaceMutation = (
  options?: UseMutationOptions<ReportSpaceResponse, unknown, ReportSpaceRequest>
) => {
  const sapiClient = useSapi()
  return useMutation({ mutationFn: sapiClient.spaces.space.reportSpace, ...options })
}

/**
 * Uploads image for a given report to s3
 */
export const useUploadReportImageMutation = (options?: UseMutationOptions<void, unknown, UploadReportImageRequest>) => {
  const sapiClient = useSapi()
  return useMutation({ mutationFn: sapiClient.spaces.space.uploadReportImage, ...options })
}
