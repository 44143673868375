import { AxiosInstance } from "axios"

export type CreatePaymentIntentRequest = {
  coinsPackagePriceID: string
  spatialItemID?: string
  spatialItemQuantity?: number
  spatialPaymentToken: string
  spatialSpaceID?: string
}

export type CreatePaymentIntentResponse = {
  clientSecret: string
}

export function createStripeEndpoint(client: AxiosInstance) {
  return {
    /**
     * Create a Stripe PaymentIntent
     * @docs https://stripe.com/docs/payments/quickstart
     */
    async createPaymentIntent(args: CreatePaymentIntentRequest) {
      const response = await client.post<CreatePaymentIntentResponse>(`/payment-intent`, args)
      return response.data
    },
  }
}
