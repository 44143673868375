import { AxiosInstance } from "axios"

import { SpaceAndCreator } from "../types"

export type GetRecommendedSpacesRequest = { count: number; spaceId?: string }
export type GetRecommendedSpacesResponse = { spaces: SpaceAndCreator[] }

export function createExploreEndpoints(client: AxiosInstance) {
  return {
    /**
     * Gets a list of recommended spaces. May be updated in the future to be specific to the user and current space (optional)
     */
    getRecommendedSpaces: async function (args: GetRecommendedSpacesRequest): Promise<GetRecommendedSpacesResponse> {
      const response = await client.get<GetRecommendedSpacesResponse>("/recommend", {
        params: { count: args.count, space_id: args.spaceId },
      })
      return response.data
    },
  }
}
