"use client"

import { LazyMotion } from "framer-motion"
import { Provider as ReactWrapBalancerProvider } from "react-wrap-balancer"

import "setimmediate"
import "@spatialsys/js/util/start-view-transition"

import { useTheme } from "next-themes"

import { TrackingContextProvider } from "@spatialsys/react/analytics"
import { SapiProvider } from "@spatialsys/react/query-hooks/use-sapi"
import { SwapiProvider } from "@spatialsys/react/query-hooks/use-swapi"
import { initAndRegisterProperties, track } from "@spatialsys/web/analytics"
import { setSpatialUid } from "@spatialsys/web/app-context"
import { ReduxStateInspector } from "@spatialsys/web/core/js/components/redux-state-inspector"
import { replacer } from "@spatialsys/web/core/js/util/redux-serialize"
import { sapiClient, swapiClient } from "@spatialsys/web/sapi"
import { Toaster, TooltipProvider } from "@spatialsys/web/ui"

/**
 * Sets the Spatial UID header on all API requests. We must set this here instead of a `useEffect`
 * because React Query executes requests in an observer initialized in a `useState`, which means
 * the requests are sent prior to effects being run.
 *
 * On the server, the header gets set as needed in each page's SSR function.
 */
if (typeof document !== "undefined") {
  initAndRegisterProperties()
  setSpatialUid()
}

const serialize = {
  replacer,
}

// https://www.framer.com/motion/guide-reduce-bundle-size/
const loadMotionFeatures = () =>
  import(/* webpackChunkName: "framer-motion-features" */ "framer-motion").then((mod) => mod.domMax)

type ProviderProps = React.PropsWithChildren<{}>

/**
 * Global context providers for the app.
 * This is used by BOTH the app router and the page router.
 * Thus, components here should NOT use hooks that are specific to either router, for example,
 * `next/router`.
 * @see https://nextjs.org/docs/app/building-your-application/upgrading/app-router-migration#step-5-migrating-routing-hooks
 *
 * This component does NOT include the following contexts:
 * - ReactQuery, because it is initialized differently in app router vs page router
 * ReactQueryProvider should be initialized in the parent of this component, wrapping this provider.
 *
 * - AppProvider, because it uses many `next/router` hooks, and also expects `pageProps` to be passed in
 * which is a page router concept.
 * AppProvider will be incrementally migrated to be app router compatible.
 *
 */
export function Providers({ children }: ProviderProps) {
  const { theme } = useTheme()

  return (
    <TrackingContextProvider track={track}>
      <SapiProvider client={sapiClient}>
        <SwapiProvider client={swapiClient}>
          <ReduxStateInspector name="Spatial Web" serialize={serialize}>
            <ReactWrapBalancerProvider>
              <TooltipProvider>
                <Toaster duration={4000} theme={theme as React.ComponentProps<typeof Toaster>["theme"]} />
                <LazyMotion features={loadMotionFeatures}>{children}</LazyMotion>
              </TooltipProvider>
            </ReactWrapBalancerProvider>
          </ReduxStateInspector>
        </SwapiProvider>
      </SapiProvider>
    </TrackingContextProvider>
  )
}
