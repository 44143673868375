import { AxiosInstance } from "axios"

import { AllCategoriesResponse } from "../types"

export function createCategoriesEndpoint(client: AxiosInstance) {
  return {
    /** Get all categories */
    async getCategories() {
      const response = await client.get<AllCategoriesResponse>(`/categories`)
      return response.data
    },
  }
}
