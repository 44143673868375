// TODO: This should be handled by the server
// Sample data for random display name generation for authless users
export const AUTHLESS_DISPLAY_NAME_VARIANT = [
  "Walrus",
  "Whale",
  "Woodpecker",
  "Panda",
  "Kangaroo",
  "Elephant",
  "Tiger",
  "Wombat",
  "Wolf",
  "Owl",
  "Peacock",
  "Penguin",
  "Lobster",
  "Camel",
  "Giraffe",
  "Zebra",
  "Bunny",
  "Puppy",
  "Manatee",
  "Hippo",
  "Koala",
]

export const AUTHLESS_DISPLAY_NAME_ADJECTIVES = ["Wandering", "Adventuring", "Exploring", "Anonymous"]
