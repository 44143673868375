import { last } from "lodash"

import { AppState } from "../app-state"

/**
 * Returns the open space modal, if one is open.
 * If a global modal is open (`state.modals`), this will always return null!
 * We only ever want one modal open at a time, and give global modals precedence over space modals.
 */
export const getOpenSpaceModal = (state: AppState) => {
  const isGlobalModalOpen = state.modals.length > 0
  return isGlobalModalOpen ? null : last(state.space.modals)
}
