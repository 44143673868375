import { AxiosInstance } from "axios"

export type RefreshPayoutStateResponse = {
  /** If true, the user has a fully linked Tipalti account */
  enrolled: boolean
}

export function createPayoutsEndpoint(client: AxiosInstance) {
  return {
    /**
     * Refreshes the payout state for a given user. SAPI checks to see if the user
     * is linked with a Tipalti account, and if so, updates that info in the user's SAPI profile.
     */
    async refreshPayoutState() {
      const response = await client.get<RefreshPayoutStateResponse>(`/me/payout-status`)
      return response.data
    },
  }
}
