/**
 * For now, put constants here that are shared between web frontend and backend.
 * A better place may be `libs/js/constants` or `libs/web/constants`
 */

export const EPOCH_DATE_STRING = "Thu, 01 Jan 1970 00:00:00 GMT"
export const AUTH_SESSION_COOKIE_NAME = "auth_session"

export const SIGN_IN_WITH_REDIRECT_COOKIE_NAME = "sign_in_with_redirect"
const SIGN_IN_WITH_REDIRECT_COOKIE_MAX_AGE = 5 * 60 // 5 minutes

// See https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie
export const SIGN_IN_WITH_REDIRECT_COOKIE_VALUE = `${SIGN_IN_WITH_REDIRECT_COOKIE_NAME}=true; path=/; max-age=${SIGN_IN_WITH_REDIRECT_COOKIE_MAX_AGE}; samesite=none; Secure`

export const SPATIAL_UID_COOKIE_NAME = "spatial_uid"
export const SPATIAL_UID_HEADER_NAME = "spatial-uid"
export const SPATIAL_CLIENT_IP_HEADER_NAME = "x-forwarded-for"
export const SPATIAL_UID_URL_PARAM_NAME = "spuid"
export const SPATIAL_UID_COOKIE_AGE = 365 * 24 * 60 * 60 // 1 year

// Production space IDs
export const PUNCH_HERO_SPACE_ID = "6491dbd9e551e16bb8789f04"
export const SHOOTY_SHOOTY_SPACE_ID = "65020b034b3117f026aa55be"
export const MOSTLY_ONLY_UP_SPACE_ID = "64f8d2297af3f1ab8a72737d"
export const RACING_EMPIRE_SPACE_ID = "64b570a71c1f96fd5ea6ca36"
export const INFINITE_ASCENT_SPACE_ID = "64a5bfa1f9bd5d584cdf7561"
export const BUDDY_BLITZ_SPACE_ID = "654965ac75a12ef6a86cd763"
export const NEON_CITY_SPACE_ID = "65506d117e57900298951e31"
export const MERGE_ZOMBIE_SPACE_ID = "65a6b43cca81d8e11d95c485"

export const productionSpaceIds = {
  punchHero: PUNCH_HERO_SPACE_ID,
  shootyShooty: SHOOTY_SHOOTY_SPACE_ID,
  mostlyOnlyUp: MOSTLY_ONLY_UP_SPACE_ID,
  racingEmpire: RACING_EMPIRE_SPACE_ID,
  infiniteAscent: INFINITE_ASCENT_SPACE_ID,
  buddyBlitz: BUDDY_BLITZ_SPACE_ID,
  neonCity: NEON_CITY_SPACE_ID,
  mergeZombie: MERGE_ZOMBIE_SPACE_ID,
} as const

export const singlePlayerGameIds = [SHOOTY_SHOOTY_SPACE_ID, MERGE_ZOMBIE_SPACE_ID]
export const INDEXEDDB_NAME = "spatial"
export const INDEXEDDB_ASSET_STORE_NAME = "assets"
