import { AxiosInstance } from "axios"

import { RequestEmailVerificationArgs, VerifyEmailArgs, VerifyEmailResponse, VerifySignatureResponse } from "../types"
import { getFirstSapiError } from "../utils/sapi-error"
import { SapiAuthErrorWithMessage } from "./email-pw"

export function createVerifyEndpoints(client: AxiosInstance) {
  return {
    verifySignature: async function (publicAddress: string, signature: string): Promise<VerifySignatureResponse> {
      const response = await client.post<VerifySignatureResponse>(`/verify/signature`, {
        publicAddress,
        signature,
      })
      return response.data
    },
    requestEmailVerification: async function (args: RequestEmailVerificationArgs): Promise<any> {
      try {
        const response = await client.post<any>(`/verify/email`, args)
        return response.data
      } catch (error) {
        const sapiError = getFirstSapiError(error)
        if (sapiError?.code === "TICKET_ALREADY_EXISTS") {
          throw new SapiAuthErrorWithMessage(sapiError.message, 10000)
        }
        throw error
      }
    },
    verifyEmailTicket: async function (args: VerifyEmailArgs): Promise<VerifyEmailResponse> {
      const response = await client.put<VerifyEmailResponse>(`/verify/email`, args)
      return response.data
    },
  }
}
