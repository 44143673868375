import { getAuth, inMemoryPersistence } from "firebase/auth"
import { call, fork, put, select, takeLatest, takeLeading } from "typed-redux-saga/macro"

import { InteractionName, trackEvent } from "@spatialsys/react/analytics"
import { UnityMessages } from "@spatialsys/unity/bridge"
import { waitUntilChanged } from "@spatialsys/use-saga"
import { track } from "@spatialsys/web/analytics"
import {
  ActionType,
  Actions,
  AppState,
  AuthActionType,
  AuthConnection,
  AuthStatus,
  AuthenticateAuthless,
  Logout,
  NoAuthError,
  SetAuth,
  SetAuthSuccess,
  UserUnauthenticatedError,
  VERIFY_EMAIL,
} from "@spatialsys/web/app-state"
import { logger } from "@spatialsys/web/logger"
import { sapiClient } from "@spatialsys/web/sapi"
import { Storage } from "@spatialsys/web/storage"
import { toast } from "@spatialsys/web/ui"

import { firebaseApp } from "../../firebase-app"
import { PerformanceMonitorLogChannel } from "../../performance-monitor"
import { checkWalletAddress, logout, startAuthlessSession } from "../auth"
import { parseEmailVerificationParams } from "../email-verification"
import { AuthLogChannel } from "../log-channel"
import { initialAuth } from "./auth-startup"
import { loginWithEmailPw } from "./connections/email-pw"
import { loginWithConnection } from "./connections/login-with-connection"
import { watchMetamaskAccountsChanged } from "./connections/metamask"
import { loginWithSamlSso } from "./connections/saml-sso"
import { authChangeSaga } from "./handle-auth-change-saga"
import { scheduleRefreshSession } from "./refresh-session"
import { userSaga } from "./user-saga"

export function* authSaga() {
  // Check for navigator to prevent accessing browser storage on the server
  // We only need to set the persistence for the browser
  if (typeof navigator !== "undefined") {
    void getAuth(firebaseApp).setPersistence(inMemoryPersistence)
  }
  yield* fork(userSaga)
  yield* fork(observeAccessTokenChanged)
  yield* fork(authChangeSaga)
  // Important to use `takeLeading. We don't want to cancel `authenticate` while it's being executed
  yield* takeLeading(ActionType.Authenticate, authenticate)
  yield* takeLeading(ActionType.AuthenticateAuthless, authenticateAuthless)
  yield* takeLatest(ActionType.SetAuthSuccess, scheduleRefreshSession)
  yield* takeLatest(ActionType.SetAuthSuccess, handleSetAuthSuccess)
  yield* takeLatest(ActionType.SetAuthSuccess, callPostLogin)
  yield* takeLatest(ActionType.SetAuthSuccess, registerSamlSsoUser)
  yield* takeLatest(ActionType.LoginWithConnection, loginWithConnection)
  yield* takeLatest(ActionType.LoginWithEmailPw, loginWithEmailPw)
  yield* takeLatest(ActionType.LoginWithSamlSso, loginWithSamlSso)
  yield* takeLatest(ActionType.VerifyEmail, verifyEmail)
  yield* takeLatest(ActionType.ClearAuthlessSession, handleClearAuthlessSession)
  yield* takeLatest(ActionType.Logout, handleLogout)
  yield* takeLatest(ActionType.DeleteAccount, deleteAccount)

  // Select the initial auth state. If authenticated, we manually call `scheduleRefreshSession`
  // to start the refresh loop since `SetAuthSuccess` is not dispatched when the initial auth state is authenticated.
  // Otherwise, dispatching `SetAuthSuccess` will call `scheduleRefreshSession`.
  const authState = yield* select((state: AppState) => state.auth)
  if (authState.status === AuthStatus.Authenticated) {
    const action: SetAuth = { payload: authState, type: AuthActionType.SetAuth }
    yield* call(scheduleRefreshSession, action)
  }
}

/**
 * Attempt to authenticate
 */
export function* authenticate() {
  const startTime = performance.now()
  logger.info(PerformanceMonitorLogChannel, "Web app Begin Auth", {
    startTime,
  })

  try {
    const authState = yield* call(initialAuth)
    const endTime = performance.now()
    logger.info(PerformanceMonitorLogChannel, "Web app authenticated successfully", {
      authProvider: authState.provider,
      milliseconds: endTime - startTime,
    })

    // Check that their wallet address matches their currently selected wallet.
    if (authState.publicAddress) {
      try {
        yield* fork(watchMetamaskAccountsChanged)
        yield* call(checkWalletAddress, authState.publicAddress)
      } catch (err) {
        logger.error(AuthLogChannel, "Wallet address mismatch")
        yield* put(
          Actions.setAuthState({
            ...authState,
            authenticationError: err,
            status: AuthStatus.AuthenticationError,
          })
        )
        return
      }
    }

    // We've authenticated successfully!
    yield* put(Actions.setAuthSuccess(authState))
  } catch (error: any) {
    const endTime = performance.now()
    logger.info(PerformanceMonitorLogChannel, "Web app auth error", {
      milliseconds: endTime - startTime,
    })
    logger.error(AuthLogChannel, "Received error while authenticating", error)

    if (error?.errorDescription === VERIFY_EMAIL) {
      yield* put(Actions.setAuthenticationError(error))
      return
    }

    yield* put(Actions.setAuthenticationError(new UserUnauthenticatedError()))
    return
  }
}

export function* handleSetAuthSuccess({ payload: authState }: SetAuthSuccess) {
  if (!authState.accessToken) {
    return
  }

  yield* call(UnityMessages.updateAccessToken, authState.accessToken)

  const { provider, loginMethod, useAuthlessToken, emailVerificationStatus } = authState
  trackEvent(
    track,
    { name: InteractionName.AuthSuccess },
    { provider, loginMethod, useAuthlessToken, emailVerificationStatus }
  )
}

/**
 * Attempts to verify the user's email by parsing the URL for a valid "ticket"
 */
export function* verifyEmail() {
  const initState = yield* call(parseEmailVerificationParams)
  if (initState.ticket) {
    try {
      const res = yield* call(sapiClient.auth.verify.verifyEmailTicket, { ticket: initState.ticket })
      yield* put(
        Actions.setEmailVerification({
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          email: initState.email!,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          publicAddress: initState.publicAddress!,
          hasVerifiedSuccessfully: res?.verified,
        })
      )
      return
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }
  yield* put(
    Actions.setEmailVerification({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      email: initState.email!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      publicAddress: initState.publicAddress!,
      hasVerifiedSuccessfully: false,
    })
  )
}

/**
 * Attempts to start an "authless" session (get noauth token to join a public space)
 */
function* authenticateAuthless({ payload: { roomId, shareId } }: AuthenticateAuthless) {
  try {
    const authState = yield* call(startAuthlessSession, roomId, shareId)
    yield* put(Actions.setAuthSuccess(authState))
  } catch (err) {
    yield* put(Actions.setAuthenticationError(new NoAuthError(roomId, shareId)))
  }
}

/**
 * Tracks changes to the access token, and syncs it with Unity.
 * Proactive fix for DEV-16973, where it appears that the token is being refreshed but not propagated to Unity.
 */
function* observeAccessTokenChanged() {
  while (true) {
    const [newAccessToken] = yield* waitUntilChanged((state: AppState) => state.auth?.accessToken)
    const isLoggedIn = yield* select((state: AppState) => state.unity.appState.isLoggedIn)
    // If not logged in, short-circuit. The access token is always sent to Unity on booting.
    if (newAccessToken && isLoggedIn) {
      yield* call(UnityMessages.updateAccessToken, newAccessToken)
    }
  }
}

/**
 * Call SAPI to merge Mixpanel profiles. Intended to be called after a successful login.
 */
function* callPostLogin() {
  try {
    yield* call(sapiClient.auth.postLogin)
  } catch (err: any) {
    // Swallow error
    logger.error(AuthLogChannel, "Error calling post-login", err)
  }
}

/**
 * Call SAPI to register SAML SSO login user. Intended to be called after a successful login.
 */
function* registerSamlSsoUser({ payload: authState }: SetAuthSuccess) {
  // If not logged in with SAML SSO, short-circuit. No need to register the user.
  if (authState.loginMethod !== AuthConnection.SSO) return

  try {
    yield* call(sapiClient.usersV2.samlSso.register)
  } catch (err: any) {
    // Swallow error
    logger.error(AuthLogChannel, "Error registering SAML SSO login user", err)
  }
}

function* handleClearAuthlessSession() {
  const isStarted = yield* select((state: AppState) => state.unity.isDoneBooting)
  if (isStarted) {
    UnityMessages.logOut()
  }
}

function handleLogout({ payload }: Logout) {
  void logout(payload)
}

export function* deleteAccount() {
  try {
    // Apply fade out effect to the entire page for smoother transition
    const fadeOutEffect = () => {
      const element = document.body
      let opacity = 1
      const timer = setInterval(() => {
        if (opacity <= 0) {
          clearInterval(timer)
          element.style.opacity = "0"
          // Ensure the opacity stays at 0 after the animation
          element.style.pointerEvents = "none"
        }
        element.style.opacity = opacity.toString()
        opacity -= 0.05 // Use a fixed decrement for smoother fading
      }, 50) // Increase interval for a slower, smoother fade
    }

    fadeOutEffect()

    yield* call(sapiClient.usersV2.account.deleteMe)
    yield* call(logout)
    Storage.setItem(Storage.HAS_ACCOUNT_DELETED, true)
  } catch (err: any) {
    logger.error(AuthLogChannel, "Error deleting account", err)
    toast.error("Failed to delete the account. Please try again and contact support if the issue persists.")
  }
}
