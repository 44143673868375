import { Toaster as SonnerToaster } from "sonner"

import { cn } from "../utils"

/**
 * Include `Toaster` anywhere in your app to support rendering toasts (preferably close to the root).
 *
 * Our toasts are built on [Sonner](https://sonner.emilkowal.ski). This component composes `<Toaster />` from Sonner,
 * applying some default styles and includes some default props.
 *
 * @see https://web-docs.spatial.io/components/toast
 */
export const Toaster = (props: React.ComponentProps<typeof SonnerToaster>) => {
  return (
    <SonnerToaster
      richColors
      closeButton
      position="bottom-center"
      toastOptions={{
        unstyled: true,
        /**
         * Summary of applied styles:
         * - custom background, text color, and description color for each toast type
         * - toast icon is hidden for all toast types `[&>div[data-icon]]:hidden`
         * - descriptions are 70% opacity for error, success, and warning toasts, applied using `[&[data-type]>div_[data-description]]`
         * The default toast style does not have `data-type` defined
         * - !important is applied to some classes to override default sonner styles
         * - fixed width since toasts of different sizes stacking on each other looks weird
         * - enlarged close button and moved it to the right
         * - TODO(DEV-29346): make the component theme aware. Styles here will likely need to be updated too to increase visual contrast.
         */
        classNames: {
          toast: cn(
            "mx-auto flex min-h-[2rem] w-[22.5rem] items-center justify-center gap-2 rounded-lg px-4 py-4 text-center",
            // Background color is the CSS variable `--toast-bg`
            "!bg-toast-bg shadow-sm",
            // Toast bg, description, and title colors are set using CSS variables
            "[--toast-bg:var(--popover)] [--toast-description:var(--muted-foreground)] [--toast-title:var(--foreground)]",
            // Override the description with a bunch of funky selectors. Default description is muted color, every other description is
            // the title color at 70% opacity
            "[&>div_[data-description]]:!text-toast-description [&[data-type]>div_[data-description]]:!text-toast-description/70",
            // Hide the content including close button when collapsed
            "*:data-[front=false]:data-[expanded=false]:opacity-0",
            // Hide the close button unless hovered. Sonner's default behaviour is to show the close button always,
            // See https://github.com/emilkowalski/sonner/pull/227
            "[&>[data-close-button]]:data-[sonner-toast]:data-[expanded=false]:opacity-0 [&_[data-close-button]]:focus-within:opacity-100 [&_[data-close-button]]:focus:opacity-100 [&_[data-close-button]]:hover:[&[data-sonner-toast]]:opacity-100"
          ),
          description: "text-center text-sm font-body",
          // `!` needed to override `[data-title] from sonner which applies font-weight
          title: "text-h5 font-heading !text-toast-title !font-semibold",
          // For each toast variant, just change CSS vars. Must use `!` modifier.

          error:
            "![--toast-bg:var(--toast-error)] ![--toast-description:var(--toast-error-foreground)] ![--toast-title:var(--toast-error-foreground)] [&>div[data-icon]]:hidden",
          success:
            "![--toast-bg:var(--toast-success)] ![--toast-description:var(--toast-success-foreground)] ![--toast-title:var(--toast-success-foreground)] [&>div[data-icon]]:hidden",
          warning:
            "![--toast-description:var(--toast-warning-foreground)] ![--toast-title:var(--toast-warning-foreground)] ![--toast-bg:var(--toast-warning)] [&>div[data-icon]]:hidden",

          // Close button styles: position at top right instead of top left, apply custom colors
          closeButton:
            "!left-full !right-0 !h-6 !w-6 !border-none !bg-toast-bg !text-toast-title shadow-sm backdrop-blur [--toast-close-button-transform:translate(-50%,-50%)]",
        },
      }}
      {...props}
    />
  )
}
