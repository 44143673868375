import { AxiosInstance } from "axios"

import { PricingPlansResponse, SpaceSubscriptionResponse } from "../types"

type SpaceSubScriptionTransactionStatus =
  | "ACTIVE" // payment has been processed and subscription is active.
  | "CANCELED" // subscription has been canceled.
  | "FAILED" // failed to process the payment.
  | "PENDING" // waiting for payment to be processed.

export type GetSubscriptionStatusResponse = { status: SpaceSubScriptionTransactionStatus }

type GetSpaceSubscriptionsStripePortalResponse = { url: string }

export function createSpaceSubscriptionsEndpoints(client: AxiosInstance) {
  return {
    /**
     * Space Subscription Pricing Plans
     */
    async getPricingPlans(): Promise<PricingPlansResponse> {
      const response = await client.get<PricingPlansResponse>("/prices")
      return response.data
    },
    /**
     * Poll the status of a space subscription transaction
     */
    async getSubscriptionStatus(transactionId: string): Promise<GetSubscriptionStatusResponse> {
      const response = await client.get<GetSubscriptionStatusResponse>(`/${transactionId}`)
      return response.data
    },
    async getSpaceSubscriptionStripePortal(): Promise<GetSpaceSubscriptionsStripePortalResponse> {
      const response = await client.get<GetSpaceSubscriptionsStripePortalResponse>("/portal")
      return response.data
    },
    async getSubscribedSpaces(): Promise<SpaceSubscriptionResponse> {
      const response = await client.get<SpaceSubscriptionResponse>("/spaces")
      return response.data
    },
  }
}
