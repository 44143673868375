import dynamic from "next/dynamic"
import { useEffect, useState } from "react"
import { InPortal, OutPortal, createHtmlPortalNode } from "react-reverse-portal"

import { useAppContext } from "@spatialsys/web/app-context"

import type { SpaceContainerProps } from "./space-container"

const SpaceContainer = dynamic(
  () => import(/* webpackChunkName: "space-container" */ "./space-container").then((mod) => mod.SpaceContainer),
  { ssr: false }
)

const portalNode = typeof document !== "undefined" ? createHtmlPortalNode() : null

/**
 * The entry point for SpaceContainer. If the browser supports Spatial, it will mount the
 * "in portal" of SpaceContainer, creating the element in the virtual DOM and creating the
 * underlying DOM elements, but not attaching them to the document. The elements will be attached
 * wherever the {@link SpaceContainerOutPortal} components are mounted.
 * Should only be used one place within the component tree.
 */
export const SpaceContainerInPortal = () => {
  const unsupportedReason = useAppContext((context) => context.state.browserUnsupportedReason)
  if (unsupportedReason) {
    return null
  }
  return portalNode ? (
    <InPortal node={portalNode}>
      <SpaceContainer />
    </InPortal>
  ) : null
}

let hasClientRenderedOnce = false

/**
 * An anchor location for the {@link SpaceContainerInPortal} DOM output.
 * Should only have one instance at a time mounted within the component tree.
 */
export const SpaceContainerOutPortal = (props: SpaceContainerProps) => {
  // OutPortals cannot be rendered on the server, and should not be rendered on the first pass
  // of the client as it causes a hydration mismatch. When subsequently rendered on the client
  // they should be rendered on the first pass, not after a useEffect and state update.
  // Keep track of the first render flag in a module-level variable and use as initial state value.
  const [hasRendered, setHasClientRendered] = useState(hasClientRenderedOnce)
  useEffect(() => {
    if (!hasRendered) {
      hasClientRenderedOnce = true
      setHasClientRendered(true)
    }
  }, [hasRendered])
  return portalNode && hasRendered ? <OutPortal node={portalNode} {...props} /> : null
}
