var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"6.149.0_9143d8141e07f6fdbe7fb2c03451c20722f9dcd8"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from "@sentry/nextjs"

import Config from "@spatialsys/web/config"

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn: SENTRY_DSN,
  /**
   * `sampleRate` controls how many errors/exceptions are sampled which end up in Issues in Sentry.
   * @see https://docs.sentry.io/platforms/javascript/guides/nextjs/configuration/options/#sample-rate
   */
  sampleRate: Config.DEPLOYMENT_ENV === "production" ? 0.5 : 1.0,
  /**
   * `tracesSampleRate` controls how transactions are sampled which end up in our Performance product in Sentry.
   * @see https://docs.sentry.io/platforms/javascript/guides/nextjs/configuration/options/#traces-sample-rate
   */
  tracesSampleRate: Config.DEPLOYMENT_ENV === "production" ? 0.02 : 1.0,
  environment: Config.SENTRY_ENVIRONMENT,
  beforeSend(event) {
    const transactionTag = event.tags?.transaction
    if (
      typeof transactionTag === "string" &&
      (transactionTag.startsWith("getInitialProps") || transactionTag === "middleware")
    ) {
      return null // Prevents sending these event to Sentry
    }
    return event
  },
  integrations: [new Sentry.BrowserProfilingIntegration()],
  // This is relative to tracesSampleRate, meaning, 0.2 * 0.06 = 1.2% of transactions will be sampled
  profilesSampleRate: Config.DEPLOYMENT_ENV === "production" ? 0.1 : 1.0,
})
Sentry.addTracingExtensions()
