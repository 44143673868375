type AvatarUrlParams = {
  avatarBaseUrl: string
  avatarPath: string
}

type RpmAvatarUrlParams = {
  rpmId?: string
  rpmBaseUrl: string
}

/**
 * Converts the given avatar path from the SAPI treatment into a full RPM avatar model URL and thumbnail URL for use in the client
 * @returns formatted avatar model url and thumbnail url
 */
export function formatAvatarModelAndThumbnailUrl(params: AvatarUrlParams | (AvatarUrlParams & RpmAvatarUrlParams)): {
  avatarModelUrl: string
  avatarThumbnailUrl: string
} {
  if ("rpmId" in params && params.rpmId) {
    return {
      avatarModelUrl: `${params.rpmBaseUrl}/${params.rpmId}.glb`,
      avatarThumbnailUrl: `${params.rpmBaseUrl}/${params.rpmId}.png`,
    }
  }
  const avatarId = params.avatarPath.split("-").pop()
  return {
    avatarModelUrl: `${params.avatarBaseUrl}/${params.avatarPath}/${avatarId}.glb`,
    avatarThumbnailUrl: `${params.avatarBaseUrl}/${params.avatarPath}/thumbnail-192.png`,
  }
}
