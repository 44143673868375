import { AxiosInstance } from "axios"

import type { ExternalGame, PaginationRequestMetadata, PaginationResponseMetadata } from "../types"

export type GetExternalGamesRequest = PaginationRequestMetadata
export type GetExternalGamesResponse = PaginationResponseMetadata & {
  games: ExternalGame[]
}

export type GetExternalGamesByCategoryRequest = PaginationRequestMetadata & {
  categoryId: string
}
export type GetExternalGamesByCategoryResponse = PaginationResponseMetadata & {
  games: ExternalGame[]
}

export function createExternalGamesEndpoint(client: AxiosInstance) {
  return {
    /** Gets third party game by slug */
    async getBySlug({ slug }: { slug: string }) {
      const response = await client.get<ExternalGame>(`/${slug}`)
      return response.data
    },
    /** Gets list of third party games */
    async getGames(args: PaginationRequestMetadata) {
      const response = await client.get<GetExternalGamesResponse>("", { params: args })
      return response.data
    },
    /** Gets list of third party games by category ID */
    async getByCategory(args: GetExternalGamesByCategoryRequest) {
      const { categoryId, ...params } = args
      const response = await client.get<GetExternalGamesByCategoryResponse>(`/categories/${categoryId}`, { params })
      return response.data
    },
  }
}
