/**
 * Polyfill for the `startViewTransition` method of [View Transitions](https://developer.chrome.com/docs/web-platform/view-transitions/).
 * If the browser doesn't support view transitions the function given as the input will simply be called
 * immediately.
 */

export type DocumentWithViewTransition = Document & {
  startViewTransition: (callback: () => void) => void
}

if (typeof document !== "undefined") {
  if (!document.startViewTransition) {
    document.startViewTransition = (callback: () => void) => {
      // If not supported, just resolve immediately.
      callback()
    }
  }
}

/**
 * Starts a view transition, returning a Promise that resolves once the view transition has started.
 * If the browser doesn't support view transitions, the Promise will resolve immediately.
 * See https://developer.chrome.com/docs/web-platform/view-transitions/ for more info.
 */
export function startViewTransition() {
  return new Promise<void>((resolve) => document.startViewTransition(resolve))
}

declare global {
  interface Document {
    startViewTransition(callback: () => void): void
  }
}
