import { GetActionType } from "@spatialsys/js/redux"

import { AdinPlayAction, AdinPlayActionType, AdinPlayActions, adinPlayReducer } from "./adinplay/adinplay-state"
import { AdsAction, AdsActionType, AdsActions, adsReducer } from "./ads/ads-state"
import { AppAction, AppActionType, AppActions, AppState, appStateReducer } from "./app-state"
import { AuthAction, AuthActionType, AuthActions, authReducer } from "./auth/auth-state"
import * as AuthSelectors from "./auth/selectors"
import { CanvasAction, CanvasActionType, CanvasActions, canvasReducer } from "./canvas"
import { H5Action, H5ActionType, H5Actions, h5Reducer } from "./h5/h5-state"
import { HlsAction, HlsActionType, HlsActions, hlsReducer } from "./hls"
import { ImaAction, ImaActionType, ImaActions, imaReducer } from "./ima/ima-state"
import { ModalAction, ModalActions, ModalsActionType, modalsReducer } from "./modals"
import { RouteAction, RouteActionType, RouteActions, routeReducer } from "./route"
import { RtcAction, RtcActionType, RtcActions, rtcReducer } from "./rtc"
import * as AppSelectors from "./selectors"
import { SpaceAction, SpaceActionType, SpaceActionUnionType, SpaceActions, spaceReducer } from "./space"
import { TokenGateAction, TokenGateActionType, TokenGateActions, tokenGateReducer } from "./token-gate-state"
import { UnityClientAction, UnityClientActionType, UnityClientActions, unityClientReducer } from "./unity-client"

export * from "./ads/ads-state"
export * from "./app-state"
export * from "./auth/auth-state"
export * from "./auth/errors"
export * from "./canvas"
export * from "./hls"
export * from "./modals"
export * from "./rtc"
export * from "./space"
export * from "./space/chat"
export * from "./space/crazy-games"
export * from "./space/join-context"
export * from "./space/modals"
export * from "./space/quests"
export * from "./space/rewards"
export * from "./space/space-selectors"
export * from "./token-gate-state"
export * from "./unity-client"
export * from "./route"

// Composing action domains together

export type ActionType =
  | AdinPlayActionType
  | AdsActionType
  | AppActionType
  | AuthActionType
  | CanvasActionType
  | H5ActionType
  | HlsActionType
  | ImaActionType
  | ModalsActionType
  | RouteActionType
  | RtcActionType
  | SpaceActionUnionType
  | TokenGateActionType
  | UnityClientActionType

export const ActionType = {
  ...AdinPlayActionType,
  ...AdsActionType,
  ...AppActionType,
  ...AuthActionType,
  ...CanvasActionType,
  ...H5ActionType,
  ...HlsActionType,
  ...ImaActionType,
  ...ModalsActionType,
  ...RouteActionType,
  ...RtcActionType,
  ...SpaceActionType,
  ...TokenGateActionType,
  ...UnityClientActionType,
}

export const Actions = {
  ...AdinPlayActions,
  ...AdsActions,
  ...AppActions,
  ...AuthActions,
  ...CanvasActions,
  ...H5Actions,
  ...HlsActions,
  ...ImaActions,
  ...ModalActions,
  ...RouteActions,
  ...RtcActions,
  ...SpaceActions,
  ...TokenGateActions,
  ...UnityClientActions,
}

export type Action = GetActionType<typeof Actions>

// Composing root state from sub-reducers

export function rootReducer(state: AppState, action: Action): AppState {
  const adinPlay = adinPlayReducer(state.adinPlay, action as AdinPlayAction)
  const ads = adsReducer(state.ads, action as AdsAction)
  const auth = authReducer(state.auth, action as AuthAction, state.reactQueryClient)
  const canvas = canvasReducer(state.canvas, action as CanvasAction)
  const h5 = h5Reducer(state.h5, action as H5Action)
  const hls = hlsReducer(state.hls, action as HlsAction)
  const ima = imaReducer(state.ima, action as ImaAction)
  const modals = modalsReducer(state.modals, action as ModalAction)
  const route = routeReducer(state.route, action as RouteAction)
  const rtc = rtcReducer(state.rtc, action as RtcAction)
  const space = spaceReducer(state.space, action as SpaceAction)
  const tokenGate = tokenGateReducer(state.tokenGate, action as TokenGateAction)
  const unity = unityClientReducer(state.unity, action as UnityClientAction)
  return {
    ...appStateReducer(state, action as AppAction),
    adinPlay,
    ads,
    auth,
    canvas,
    h5,
    hls,
    ima,
    modals,
    route,
    rtc,
    space,
    tokenGate,
    unity,
  }
}

// Composing selector functions together

export const Selectors = {
  ...AppSelectors,
  ...AuthSelectors,
}
