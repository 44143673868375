import { AxiosInstance } from "axios"

import { AvatarAnimation, Emoji, EmoteTypeV2, PaginationRequestMetadata, PaginationResponseMetadata } from "../types"

export type EmojiInfo = {
  displayEmoji: string
  id: string
}

export type Emote = AvatarAnimation | EmojiInfo

export type GetEmotesRequest = Partial<PaginationRequestMetadata>

export type GetEmotesResponse = PaginationResponseMetadata & {
  defaultEmotes: AvatarAnimation[]
  emotes: AvatarAnimation[]
}

export type GetRecentEmotesResponse = {
  emotes: (AvatarAnimation | Emoji)[]
}

export type SetRecentEmotesRequest = {
  emoteID: string
  emoteType: EmoteTypeV2
}

export function createEmotesEndpoint(client: AxiosInstance) {
  return {
    async getEmotes(params: GetEmotesRequest): Promise<GetEmotesResponse> {
      const response = await client.get<GetEmotesResponse>(`/me/emotes`, { params })
      return response.data
    },
    async getRecentEmotes(): Promise<GetRecentEmotesResponse> {
      const response = await client.get<GetRecentEmotesResponse>(`/me/recent-emotes`)
      return response.data
    },
    async updateRecentEmotes(params: SetRecentEmotesRequest): Promise<void> {
      const response = await client.post(`/me/emotes`, params)
      return response.data
    },
  }
}
