import { UseQueryOptions, useQuery } from "@tanstack/react-query"

import { GetFeatureFlagsResponse } from "@spatialsys/js/sapi/feature-flags"

import { useSapi } from "../use-sapi"

export const GET_FEATURE_FLAGS_QUERY_KEY = ["getFeatureFlags"]

/**
 * Check if an account already exists for a given email address.
 * Results are never cached, so that we always fetch up-to-date data
 */
export const useGetFeatureFlagsQuery = (
  options?: UseQueryOptions<GetFeatureFlagsResponse, unknown, GetFeatureFlagsResponse, string[]>
) => {
  const sapiClient = useSapi()
  return useQuery({
    queryKey: GET_FEATURE_FLAGS_QUERY_KEY,
    queryFn: () => sapiClient.featureFlags.get(),
    /** Never mark the flags as stale. This result is only invalidated manually! */
    cacheTime: Infinity,
    staleTime: Infinity,
    ...options,
  })
}
