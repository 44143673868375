import { call, fork, put, select } from "typed-redux-saga/macro"

import { UnityMessages } from "@spatialsys/unity/bridge"
import { waitUntilChanged, waitUntilExists } from "@spatialsys/use-saga"
import { Mixpanel } from "@spatialsys/web/analytics"
import { Actions, AppState, AuthState, AuthStatus, Modals } from "@spatialsys/web/app-state"

export function* authChangeSaga() {
  const firstState = yield* select((state: AppState) => state.auth)

  // When this saga first runs, the user may already be unauthenticated due to server-side auth.
  // So, we look at the initial value, and not only transitions to the value.
  if (firstState.status === AuthStatus.AuthenticationError || firstState.status === AuthStatus.LoginError) {
    yield* call(identifyAndUnregisterUserId)
  }

  while (true) {
    const states = yield* waitUntilChanged((state: AppState) => state.auth)
    yield* fork(loginToUnity, ...states)
    yield* call(invalidateQueriesOnAuthStatusChanged, ...states)
    yield* call(updateMixpanelSpatialUidOnAuthStatusChanged, ...states)
    yield* call(closeLoginModalIfAuthenticated, states[0])
  }
}
/**
 * Resets queries based on auth state changes, since certain queries return different results when authenticated vs unauthenticated.
 * Typically, we need to do invalidation during these auth state transitions:
 * - LoggedIn -> Unauthenticated (log out)
 * - Unauthenticated -> LoggedIn (log in)
 *
 * `resetQueries` clears all queries from the cache, and refetches active queries. Although this might be a bit heavy-handed,
 * it is much easier to maintain than adding queries selectively (i.e. adding queries which return different results when authenticated).
 *
 * Note that `LoggedIn` denotes a user that logs in explicitly, and excludes authless (no-auth) users.
 * We do not invalidate the feature flags and feed caches for authless users, as we can simply rely
 * on the result from their `spatial-uid`.
 */
export function* invalidateQueriesOnAuthStatusChanged(newState: AuthState, prevState: AuthState) {
  const { status: prevStatus, useAuthlessToken: prevAuthless } = prevState
  const { status: newStatus, useAuthlessToken: newAuthless } = newState
  const reactQueryClient = yield* select((state: AppState) => state.reactQueryClient)

  // Transitioned to authenticated — reset the cache as long as the new user is not authless
  if (newStatus === AuthStatus.Authenticated && prevStatus !== AuthStatus.Authenticated && !newAuthless) {
    void reactQueryClient.resetQueries()
  }

  // Transitioned to unauthenticated — reset the cache as long as the prev user was not authless
  if (newStatus !== AuthStatus.Authenticated && prevStatus === AuthStatus.Authenticated && !prevAuthless) {
    void reactQueryClient.resetQueries()
  }
}

/**
 * To handle the following edge case:
 * - user starts as authless, joins a space
 * - user goes back to home, logs in
 * - user joins a space
 *
 * UnityMessages.logIn() is only called once in `finishBooting` of `handle-unity-message`.
 * Instead, we need to explicitly call it on auth transition to LoggedIn.
 */
function* loginToUnity(newState: AuthState, prevState: AuthState) {
  // If not booted, logging in will be handled in `finishBooting`, short-circuit to avoid double login
  // (which still works, but this is just a micro-optimization)
  const isDoneBooting = yield* select((state: AppState) => state.unity.isDoneBooting)
  if (!isDoneBooting) return

  const { status: newStatus, useAuthlessToken: newAuthless } = newState
  const { status: prevStatus } = prevState

  // Transitioned to authenticated — login always
  if (newStatus === AuthStatus.Authenticated && newState.accessToken && prevStatus !== AuthStatus.Authenticated) {
    const user = yield* waitUntilExists((state: AppState) => state.user)
    UnityMessages.logIn(newState.accessToken, Boolean(newAuthless), JSON.stringify(user.raw))
  }
}

/**
 * Updates Spatial UID in the following cases when auth status changes:
 * - If auth status becomes `AuthenticationError`, identify the user in Mixpanel using `spatialUid`.
 * - If auth status becomes `LoggedIn`, do nothing. The user will be identified in Mixpanel in `user-saga`,
 * and also merged with `spatialUid` by calling `post-login` endpoint as part of responding to `SetAuthSuccess`.
 * - If auth status transitions from logged in to logged out, do nothing. Logout saga will handle
 * resetting Mixpanel and spatialUid.
 *
 * In other words, authless users are identified with their Spatial UID. Logged in users are identified with their user ID
 * in `user-saga`.
 */
function* updateMixpanelSpatialUidOnAuthStatusChanged(
  { status: newStatus }: AuthState,
  { status: prevStatus }: AuthState
) {
  /**
   * Transitioned to authenticated: do nothing. Mixpanel.identify is called in `user-saga`, profiles
   * are merged by calling `/post-login` via `SetAuthSuccess handling`.
   * Transitioned from authenticated to unauthenticated: do nothing.
   * - If it's a "LoggedIn" user, logging out forces a page refresh. In `logout` saga,  we must first
   * regenerate the Spatial UID before refreshing the page.
   * - If the it's an "authless" user, we don't want to regenerate the Spatial UID. It should remain the same.
   *
   * So the only case where we do anything is when the auth state transitions to `AuthenticationError` or `LoginError`,
   * and the previous state was NOT `Authenticated`. In this case, it's a user that failed to sign in, or did not have an
   * existing session, and the authentication occurred on the client. Alias them in Mixpanel using the spatialUid.
   */
  if (
    prevStatus !== AuthStatus.Authenticated &&
    (newStatus === AuthStatus.AuthenticationError || newStatus === AuthStatus.LoginError)
  ) {
    yield* call(identifyAndUnregisterUserId)
  }
}

function* identifyAndUnregisterUserId() {
  const spatialUid = yield* select((state: AppState) => state.spatialUid)
  Mixpanel.identify(spatialUid)
  Mixpanel.unregister("$user_id")
}

/**
 * Close the login modal if the user is authenticated. This removes it from the global modals array in app state,
 * which is important as some modals conditionally render only if the global modals array is empty.
 */
function* closeLoginModalIfAuthenticated(newState: AuthState) {
  if (newState.accessToken && !newState.useAuthlessToken) {
    yield* put(Actions.closeModal(Modals.Login))
  }
}
