import dynamic from "next/dynamic"
import { memo, useCallback } from "react"

import { useAppContext } from "@spatialsys/web/app-context"

const BreakpointIndicator = dynamic(() =>
  import(/* webpackChunkName: "breakpoint-indicator" */ "@spatialsys/web/ui/breakpoint-indicator").then(
    (mod) => mod.BreakpointIndicator
  )
)

export const BreakpointIndicatorWithState = memo(function BreakpointIndicatorWithState() {
  const showBreakpointIndicator = useAppContext((context) => context.state.debugSettings.showBreakpointIndicator)
  const actions = useAppContext((context) => context.actions)

  const hideBreakpointIndicator = useCallback(() => {
    actions.setDebugSettings({ showBreakpointIndicator: false })
  }, [actions])

  if (!showBreakpointIndicator) return null

  return (
    <button className="fixed bottom-1 left-1 z-50 hover:opacity-70" onClick={hideBreakpointIndicator}>
      <BreakpointIndicator />
    </button>
  )
})
