import { objectId } from "@spatialsys/js/util/bson"
import { SPATIAL_UID_COOKIE_AGE, SPATIAL_UID_COOKIE_NAME } from "@spatialsys/js/util/constants"
import { getCookieValue } from "@spatialsys/js/util/cookies"
import { logger } from "@spatialsys/web/logger"
import { setSpatialUidHeaders } from "@spatialsys/web/sapi"

let spatialUid = ""

export function getSpatialUid() {
  return spatialUid
}

/**
 * Only callable from the client. Reads the Spatial UID from `document.cookie`, or generates a new one
 * and updates `document.cookie` if it doesn't exist. Then, sets the Spatial UID header for all
 * SAPI requests.
 */
export function setSpatialUid() {
  const cookieValue = getCookieValue(document.cookie, SPATIAL_UID_COOKIE_NAME)

  // This should never happen, but if cookie doesn't exist, we'll generate a new ID
  if (!cookieValue) {
    spatialUid = objectId()
    document.cookie = `${SPATIAL_UID_COOKIE_NAME}=${spatialUid}; path=/; samesite=none, maxAge=${SPATIAL_UID_COOKIE_AGE}; httpOnly=false; Secure;`
    logger.error("Spatial UID cookie not found. Generating new Spatial UID.")
  } else {
    spatialUid = cookieValue
  }

  setSpatialUidHeaders(spatialUid)
}
