import { produce } from "immer"

import { ActionT, GetActionType, makeActionCreator } from "@spatialsys/js/redux"

export type AppHlsState = {
  urlToMediaStream: { [key: string]: MediaStream }
}

export const initialHlsState: AppHlsState = {
  urlToMediaStream: {},
}

export enum HlsActionType {
  AddMediaStream = "AddMediaStream",
  RemoveMediaStream = "RemoveMediaStream",
  ResetHlsState = "ResetHlsState",
}

export type ResetHlsState = ActionT<HlsActionType.ResetHlsState>
export type AddMediaStream = ActionT<
  HlsActionType.AddMediaStream,
  {
    stream: MediaStream
    url: string
  }
>
export type RemoveMediaStream = ActionT<HlsActionType.RemoveMediaStream, string>

export const HlsActions = {
  addMediaStream: makeActionCreator<AddMediaStream>(HlsActionType.AddMediaStream),
  removeMediaStream: makeActionCreator<RemoveMediaStream>(HlsActionType.RemoveMediaStream),
  resetHlsState: makeActionCreator<ResetHlsState>(HlsActionType.ResetHlsState),
}

export type HlsAction = GetActionType<typeof HlsActions>

export function hlsReducer(state: AppHlsState, action: HlsAction): AppHlsState {
  switch (action.type) {
    case HlsActionType.AddMediaStream:
      return produce(state, (draft) => {
        draft.urlToMediaStream[action.payload.url] = action.payload.stream
      })
    case HlsActionType.RemoveMediaStream:
      return produce(state, (draft) => {
        delete draft.urlToMediaStream[action.payload]
      })
    case HlsActionType.ResetHlsState:
      return initialHlsState
    default:
      return state
  }
}
