import { AxiosInstance } from "axios"

import { SpaceAndCreator, SpaceLimitsResponse } from "../types"

export type SearchSpacesRequest = {
  query: string
}

export type SearchSpacesResponse = { results: SpaceAndCreator[] }

export type UpdateLivestreamRequest = {
  latencyMode: string
  srtLatencyMs: number
}

export function createSpacesV2Endpoints(client: AxiosInstance) {
  return {
    /**
     * Search for a space by space name
     */
    async searchSpaces(params: SearchSpacesRequest): Promise<SearchSpacesResponse> {
      const response = await client.get<SearchSpacesResponse>(`/search-name?query=${params?.query}`)
      return response.data
    },
    async getSpaceLimits(spaceId: string): Promise<SpaceLimitsResponse> {
      const response = await client.get<SpaceLimitsResponse>(`${spaceId}/limits`)
      return response.data
    },
  }
}
