import { AxiosInstance, AxiosRequestConfig, RawAxiosRequestHeaders } from "axios"

import {
  Badge,
  CategoryDetailsResponse,
  CreatorMetadata,
  FeedCategory,
  FeedComponent,
  FeedSection,
  SapiVideo,
  SpaceAndCreator,
  SpaceMetadata,
} from "../types"

/**
 * The value in this enum should be the value sent to SAPI
 * in the `type` query parameter of `getSpaces`.
 */
export enum GetSpacesQueryType {
  Admin = "admin",
  LiveNow = "live",
  Loved = "loved",
  Newest = "newest",
  Popular = "popular",
  Recent = "recent",
  Team = "team",
  Trending = "trending",
  YourSpaces = "my_space",
}

/**
 * A subset of `GetSpacesQueryType` which can be fetched without authentication
 */
export const publicGetSpacesQueries = [
  GetSpacesQueryType.Trending,
  GetSpacesQueryType.Popular,
  GetSpacesQueryType.LiveNow,
  GetSpacesQueryType.Newest,
]

export type GetSpacesRequest = {
  type: GetSpacesQueryType
}

/** Defined in SAPI `services/explore/explore.go` */
export type GetSpacesResponse = {
  spaces: SpaceAndCreator[]
  title: string
  type: GetSpacesQueryType
}

export type SearchSpacesRequest = {
  query: string
}

export type SearchSpacesResponse = { results: SpaceAndCreator[] }

export type GetCategorySpacesResponse = {
  description?: string
  results: SpaceAndCreator[]
  title: string
}

export type GetCategorySpacesRequest = {
  category: string
  count?: number
  start?: number
}

export type GetCategoriesRequest = {
  count?: number
  query?: string
}

export type GetCategoryRequest = {
  category: string
}

export type EditCategoryRequest = {
  category: FeedCategory
}

export type CreateCategoryRequest = {
  category: FeedCategory
}

export type DeleteCategoryRequest = {
  category: string
}

export type GetFeedRequest = { userAgent?: string }
export type GetFeedResponse = { feedItems: FeedComponent[] }

export type GetFeedForConfigRequest = {
  asAuthlessUser?: boolean
  asUsername?: string
  options?: AxiosRequestConfig
  sections: FeedSection[]
}

export type CreateFeedVersionRequest = {
  description: string
  variants: Array<{ percentage: number; sections: FeedSection[]; variant: string }>
  version: number
}

export type UpdateFeedVersionRequest = {
  description: string
  variants: Array<{ sections: FeedSection[] }>
  version: number
}

export type GetFeedConfigHistoryRequest = {
  count: number
  start: number
}

export type FeedStats = {
  allHomeFeedClicks: number
  allHomeFeedImpressions: number
  desktopHomeFeedClicks: number
  desktopHomeFeedImpressions: number
  mobileHomeFeedClicks: number
  mobileHomeFeedImpressions: number
}

export type FeedStatsWithWinner = FeedStats & { isWinner: boolean }
export type FeedStatsWithOutcome = FeedStats & { outcome: Outcome }

export type Outcome = "better" | "unclear" | "worse"

export type FeedConfigResponse = {
  createdAt: string
  description: string
  lastUpdatedAt: string
  variants: Array<[{ sections: FeedSection[] }, FeedStatsWithOutcome]>
  version: number
}

export type GetFeedConfigHistoryResponse = Array<{
  createdAt: string
  lastUpdatedAt: string
  lastUpdatedBy: {
    username: string
  }
  variants: Array<{ percentage?: number; stats?: FeedStatsWithWinner }>
  version: number
}>

export type GetFeedConfigHistoryForVersionRequest = {
  count: number
  start: number
  version: number
}

export type FeedConfigVersionHistoryResponse = Array<{
  createdAt: string
  createdBy: {
    playerColor: string
    profilePicUrl: string
    username: string
  }
  description: string
  variants: Array<{ percentage: number; sections: FeedSection[]; variant: string }>
  version: number
}>

export type GetLovedSpacesRequest = { spaceIds: string[] }
export type GetLovedSpacesResponse = {
  spacesLoved: Record<string, boolean>
}

/**
 * A single item in the featured carousel
 */
export type FeaturedCarouselItem = {
  badge?: Badge
  creator?: CreatorMetadata
  ctaTitle: string
  ctaUrl?: string
  description: string
  hideInMobile: boolean
  id: string
  /** Integer */
  position: number
  space?: SpaceMetadata
  title: string
  video: SapiVideo
}

export type GetFeaturedCarouselResponse = FeaturedCarouselItem[]

export function createSpacesEndpoints(client: AxiosInstance) {
  return {
    /**
     * For now, this request is not paginated. We will add pagination in "v2" of live explore.
     */
    async getSpaces({ type }: GetSpacesRequest): Promise<GetSpacesResponse> {
      const resp = await client.get<GetSpacesResponse>(`/rooms`, { params: { type } })
      return { ...resp.data, type }
    },
    /**
     * Makes one request per space ID. Would be better if SAPI had a bulk GET endpoint.
     * This function should be used sparingly.
     */
    getBatchSpacesPreview: async function (spaceIds: string[]): Promise<SpaceAndCreator[]> {
      const queries = spaceIds.map((spaceId) => {
        return client.get<SpaceAndCreator>(`/${spaceId}/preview`)
      })

      return await Promise.all(queries).then((responses) => {
        return responses.map((response) => {
          return response.data
        })
      })
    },
    async searchSpaces({ query }: SearchSpacesRequest): Promise<SearchSpacesResponse> {
      const resp = await client.get<SearchSpacesResponse>(`/search`, { params: { query } })
      return resp.data
    },
    async getCategorySpaces({ category, count, start }: GetCategorySpacesRequest): Promise<GetCategorySpacesResponse> {
      const resp = await client.get<GetCategorySpacesResponse>(`/feed/categories/${category}`, {
        params: { start, count },
      })
      return resp.data
    },
    async getCategories({ query, count }: GetCategoriesRequest): Promise<FeedCategory[]> {
      const resp = await client.get<FeedCategory[]>(`/categories`, { params: { count, query } })
      return resp.data
    },
    async getCategory({ category }: GetCategoryRequest): Promise<FeedCategory> {
      const resp = await client.get<FeedCategory>(`/categories/${category}`)
      return resp.data
    },
    async getCategoryDetails({ category }: GetCategoryRequest): Promise<CategoryDetailsResponse> {
      const resp = await client.get<CategoryDetailsResponse>(`/categories/${category}/details`)
      return resp.data
    },
    async editCategory({ category }: EditCategoryRequest): Promise<void> {
      await client.put(`/categories/${category.slug}`, category)
      return
    },
    async createCategory({ category }: CreateCategoryRequest): Promise<void> {
      await client.post(`/categories`, category)
      return
    },
    async deleteCategory({ category }: DeleteCategoryRequest): Promise<void> {
      await client.delete(`/categories/${category}`)
      return
    },
    async getFeed({ userAgent }: GetFeedRequest): Promise<GetFeedResponse> {
      const headers: RawAxiosRequestHeaders = {}
      if (userAgent) {
        headers["user-agent"] = userAgent
      }
      const resp = await client.get<GetFeedResponse>(`/feed`, { headers })
      return resp.data
    },
    async getFeedForConfig({ options, ...request }: GetFeedForConfigRequest): Promise<GetFeedResponse> {
      const resp = await client.post<GetFeedResponse>("/feed", request, options)
      return resp.data
    },
    async getFeedConfig(): Promise<FeedConfigResponse> {
      const resp = await client.get("/feed/config")
      return resp.data
    },
    async createFeedVersion(request: CreateFeedVersionRequest): Promise<void> {
      await client.post("/feed/config", request)
    },
    async updateFeedVersion(request: UpdateFeedVersionRequest): Promise<void> {
      await client.put("/feed/config", request)
    },
    async getFeedConfigHistory(request: GetFeedConfigHistoryRequest): Promise<GetFeedConfigHistoryResponse> {
      const resp = await client.get(`/feed/config/history`, { params: request })
      return resp.data
    },
    async getFeedConfigHistoryForVersion(
      request: GetFeedConfigHistoryForVersionRequest
    ): Promise<FeedConfigVersionHistoryResponse> {
      const resp = await client.get(`/feed/config/history/${request.version}`, {
        params: { count: request.count, start: request.start },
      })
      return resp.data
    },
    async getLovedSpaces({ spaceIds }: GetLovedSpacesRequest): Promise<GetLovedSpacesResponse> {
      const resp = await client.post<GetLovedSpacesResponse>(`/rooms/love`, {
        roomIDs: spaceIds,
      })
      return resp.data
    },
    async getFeaturedCarousel(): Promise<GetFeaturedCarouselResponse> {
      const resp = await client.get<GetFeaturedCarouselResponse>("/featured")
      return resp.data
    },
  }
}
