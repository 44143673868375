import { produce } from "immer"

import { ActionT, GetActionType, makeActionCreator } from "@spatialsys/js/redux"

export type GamepixState = {
  isSdkLoaded: boolean
}

export const initialGamepixState: GamepixState = {
  isSdkLoaded: false,
}

export enum GamepixActionType {
  SetGamepixSdkLoaded = "SetGamepixSdkLoaded",
}

export type SetGamepixSdkLoaded = ActionT<GamepixActionType.SetGamepixSdkLoaded>

export const GamepixActions = {
  setGamepixSdkLoaded: makeActionCreator<SetGamepixSdkLoaded>(GamepixActionType.SetGamepixSdkLoaded),
}

export type GamepixAction = GetActionType<typeof GamepixActions>

export const gamepixReducer = (state: GamepixState, action: GamepixAction): GamepixState => {
  switch (action.type) {
    case GamepixActionType.SetGamepixSdkLoaded:
      return produce(state, (draft) => void (draft.isSdkLoaded = true))
    default:
      return state
  }
}
