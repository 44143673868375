import { call, put } from "typed-redux-saga/macro"

import { SpatialAdType } from "@spatialsys/unity/app-state"
import { UnityMessages } from "@spatialsys/unity/bridge"
import { Actions, RequestAd } from "@spatialsys/web/app-state"

import { trackH5AdEvent, trackH5AdFinishedEvent } from "./utils"

export function* handleRequestAd({ payload }: RequestAd) {
  if (payload.adType === SpatialAdType.MidGame) {
    yield* call(playMidGameAd, payload)
  } else if (payload.adType === SpatialAdType.Rewarded) {
    yield* call(playRewardedAd, payload)
  }
}

/**
 * Play a mid-game ad using Ad Placement API `adBreak` function.
 * @see https://developers.google.com/ad-placement/apis/adbreak
 */
export function* playMidGameAd(payload: RequestAd["payload"]) {
  const { callbackId } = payload
  trackH5AdEvent({ adStatus: "requested", adType: SpatialAdType.MidGame })
  yield* call(requestMidGameAdBreak, callbackId)

  // Once promise resolves, handle after effects, like focusing Unity.
  yield* put(Actions.focusUnity())
}

export function* playRewardedAd(payload: RequestAd["payload"]) {
  const { callbackId } = payload
  trackH5AdEvent({ adStatus: "requested", adType: SpatialAdType.Rewarded })
  yield* call(requestRewardedAdBreak, callbackId)

  yield* put(Actions.focusUnity())
}

export function requestMidGameAdBreak(callbackId: number) {
  return new Promise((resolve) =>
    window.adBreak({
      type: "next",
      name: "midgame-ad",
      // According to docs, `beforeAd` only runs if there is an ad to show. Report ad start here.
      beforeAd: () => {
        trackH5AdEvent({ adStatus: "start", adType: SpatialAdType.MidGame })
        UnityMessages.handleAdRequestStarted(callbackId)
        document.exitPointerLock() // Before ad starts, exit pointer lock (e.g for Shooty)
      },
      /**
       * `adBreakDone` will always run even if ad doesn't show: we handle success and failed states here.
       *  A full list of `breakStatus` values are found here.
       * @see https://developers.google.com/ad-placement/apis/adbreak#adbreakdone_and_placementinfo
       * */
      adBreakDone: (placementInfo) => {
        if (placementInfo.breakStatus === "viewed") {
          UnityMessages.requestAlertFeedback(callbackId, { success: true })
        } else {
          UnityMessages.requestAlertFeedback(callbackId, {
            success: false,
            errorMessage: `H5 ad failed with status ${placementInfo.breakStatus}`,
          })
        }

        trackH5AdFinishedEvent(placementInfo.breakStatus, SpatialAdType.MidGame)
        resolve(placementInfo)
      },
    })
  )
}

export function requestRewardedAdBreak(callbackId: number) {
  return new Promise((resolve) =>
    window.adBreak({
      type: "reward",
      name: "rewarded-ad",
      // `showAdFn` is a function defined by Ad Placement API that shows ad when called.
      beforeReward: (showAdFn) => {
        trackH5AdEvent({ adStatus: "start", adType: SpatialAdType.Rewarded })
        UnityMessages.handleAdRequestStarted(callbackId)
        document.exitPointerLock()
        showAdFn()
      },
      // `adDismissed` called when reward ad skipped.
      adDismissed: () => {
        UnityMessages.requestAlertFeedback(callbackId, { success: false, errorMessage: "Ad was skipped" })
      },
      // `adViewed` called when reward ad completed.
      adViewed: () => {
        UnityMessages.requestAlertFeedback(callbackId, { success: true })
      },
      adBreakDone: (placementInfo) => {
        // Handle other cases of ad status. If it wasn't viewed or dismissed, there was an error.
        if (placementInfo.breakStatus !== "viewed" && placementInfo.breakStatus !== "dismissed") {
          UnityMessages.requestAlertFeedback(callbackId, {
            success: false,
            errorMessage: `Ad failed with status ${placementInfo.breakStatus}`,
          })
        }

        trackH5AdFinishedEvent(placementInfo.breakStatus, SpatialAdType.Rewarded)
        resolve(placementInfo)
      },
    })
  )
}
