import * as Sentry from "@sentry/nextjs"
import { useQueryClient } from "@tanstack/react-query"
import { useRouter } from "next/router"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useReducer } from "reinspect"
import { createContext, useContextSelector } from "use-context-selector"

import { BoundActions, useBindActions } from "@spatialsys/js/redux"
import { getFirstSapiError } from "@spatialsys/js/sapi/utils/sapi-error"
import { useGetFeatureFlagsQuery } from "@spatialsys/react/query-hooks/feature-flags"
import { useMeQuery } from "@spatialsys/react/query-hooks/sapi/user"
import { useGetSpacePreviewQuery } from "@spatialsys/react/query-hooks/spaces"
import { useGetSpaceLimitsQuery } from "@spatialsys/react/query-hooks/spaces-v2/get-space-limits"
import { createContextStub } from "@spatialsys/react/util/create-context-stub"
import { RoomJoinMethod } from "@spatialsys/unity/app-state"
import { SpaceJoinContextSource } from "@spatialsys/unity/bridge"
import { isSpacePath } from "@spatialsys/url-utils"
import { RunSaga, useSaga } from "@spatialsys/use-saga"
import {
  Actions,
  AppState,
  AuthContextAppProps,
  AuthStatus,
  CanvasStateSeed,
  Selectors,
  createInitialState,
  rootReducer,
} from "@spatialsys/web/app-state"
import { logger } from "@spatialsys/web/logger"
import { toast } from "@spatialsys/web/ui"

import { appSaga } from "./sagas/app-saga"

const stub = createContextStub("AppProvider")

export interface AppContext {
  state: AppState
  actions: BoundActions<typeof Actions>
  runSaga: RunSaga
}

const devicePixelRatio = typeof window !== "undefined" ? window.devicePixelRatio : 1

const initialAppContext: AppContext = {
  state: createInitialState({
    canvas: { isReadyToJoin: false, mode: "closed", viewSize: "default" },
    reactQueryClient: {} as any,
    devicePixelRatio,
    hasTriedAuth: false,
    authSession: undefined,
    initialPath: "",
    shareId: null,
    spatialUid: "",
    spaceId: null,
    joinContext: {
      method: RoomJoinMethod.UserJoinedThroughDirectLink,
      discoveryMetadata: {
        Source: SpaceJoinContextSource.DirectLink,
      },
    },
    userAgent: "",
    pathPrefix: "/s",
  }),
  actions: {} as any,
  runSaga: stub,
}

export const AppContext = createContext(initialAppContext)

type AppProviderProps = React.PropsWithChildren<
  AuthContextAppProps & {
    canvas?: CanvasStateSeed
    immediatelyOpenLogin?: boolean
    instanceId?: string | null
    shareId?: string | null
    spaceId?: string
    spatialUid: string
    userAgent?: string
  }
>

export const AppProvider = (props: AppProviderProps) => {
  const {
    authSession,
    immediatelyOpenLogin,
    hasTriedAuth,
    instanceId,
    children,
    shareId,
    spaceId,
    spatialUid,
    userAgent,
  } = props
  const { canvas = { mode: "closed" } } = props
  const { asPath } = useRouter()

  const isSpace = isSpacePath(asPath)
  let pathPrefix: string = "/s"
  if (isSpace) {
    pathPrefix = asPath.startsWith("/embed/") ? "/embed" : "/s"
  }
  const reactQueryClient = useQueryClient()
  // TODO (DEV-19800): Initialize the state in a `useState` hook so that it `createInitialSate` is only run once,
  // as a workaround for a bug in `reinspect`. `createInitialAuthState` has a side effect, so it should only be run once.
  const [initialState] = useState(() =>
    createInitialState({
      canvas,
      immediatelyOpenLogin,
      reactQueryClient,
      devicePixelRatio,
      authSession,
      hasTriedAuth,
      initialPath: asPath,
      shareId,
      instanceId,
      spatialUid,
      spaceId,
      joinContext: {
        method: RoomJoinMethod.UserJoinedThroughDirectLink,
      },
      userAgent,
      pathPrefix,
    })
  )

  const [state, reducerDispatch] = useReducer(rootReducer, initialState, "App")
  const onAppSagaError = useCallback((err: Error) => {
    logger.error("App saga error", err)
    Sentry.captureException(err, {
      level: "fatal",
      tags: { saga: "appSaga" },
    })
    /**
     * An error here is fatal, the app will no longer work.
     * There is a rare case where the app will continuously throw exceptions, blowing through the Sentry quota.
     * To avoid this, we close the Sentry client after the first fatal exception.
     * We set a timeout to allow sufficient time for pending events to be flushed to Sentry.
     *
     * @see DEV-35595
     * @see https://docs.sentry.io/platforms/javascript/configuration/draining/
     */
    void Sentry.close(5000)
  }, [])
  const { dispatch, runSaga } = useSaga({ state, dispatch: reducerDispatch, onError: onAppSagaError }, appSaga)

  const actions = useBindActions(Actions, dispatch)

  useEffect(() => {
    global_setHasFatalException = () => actions.setHasFatalException(true)
  }, [actions])

  const appContextValue = useMemo(
    () => ({
      state,
      actions,
      runSaga,
    }),
    [state, actions, runSaga]
  )

  // Sync select query state into app state (feature flags, user)
  const authState = Selectors.getAuthState(state)
  const isAuthenticated = authState.status === AuthStatus.Authenticated

  const { data: flags } = useGetFeatureFlagsQuery()
  const { data: user } = useMeQuery(
    isAuthenticated,
    // Only listens for changes to `data`, we don't care about loading state or anything else
    { notifyOnChangeProps: ["data"] }
  )
  const { data: spaceLimits } = useGetSpaceLimitsQuery(spaceId as string)
  const { data: spacePreviewData } = useGetSpacePreviewQuery(spaceId as string, {
    onError: (error) => {
      const sapiError = getFirstSapiError(error)
      if (sapiError?.code === "USER_UNAUTHORIZED") {
        const errorMessage = authState.isLoggedIn
          ? "You are not authorized to view this space. Please contact the space owner for access."
          : "Try logging in to view this space."
        toast.error(errorMessage, {
          duration: 10000,
        })
      }
    },
  })

  useEffect(() => {
    actions.setUser(user)
  }, [actions, user])
  useEffect(() => {
    if (flags) {
      actions.setFeatureFlags(flags.featureFlags)
    }
  }, [actions, flags])
  useEffect(() => {
    if (spaceLimits) {
      actions.setSpaceLimits(spaceLimits)
    }
  }, [actions, spaceLimits])
  useEffect(() => {
    if (spacePreviewData) {
      actions.setSpacePreviewData(spacePreviewData)
    }
  }, [actions, spacePreviewData])

  return <AppContext.Provider value={appContextValue}>{children}</AppContext.Provider>
}

export const useAppContext = <T,>(selector: (context: AppContext) => T) => useContextSelector(AppContext, selector)

// This machinery helps us plumb through from the Sentry client config
// into this SpatialUnityWebGL component instance
export let global_setHasFatalException: (() => void) | null = null

export const useAuthState = () => {
  return useAppContext((context) => Selectors.getAuthState(context.state))
}
