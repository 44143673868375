import { once } from "lodash"

export const enum WebGLAvailability {
  NotAvailable = 0,
  WebGL1 = 1,
  WebGL2 = 2,
}

type GpuInfo = {
  vendor?: string
  renderer?: string
}

/**
 * Checks what type of support for WebGL the browser has.
 * The return value will never change, so memoized to save on some perf.
 */
export const getWebGLAvailability = once((): { availability: WebGLAvailability } & GpuInfo => {
  // Return as not available during tests by default. If needed otherwise, mock out the function.
  if (process.env.NODE_ENV === "test") {
    return { availability: WebGLAvailability.NotAvailable }
  }

  // On server and service worker tests, just return WebGL2. Try to SSR as much as possible,
  // When the page is hydrated, this will be checked again.
  if (typeof window === "undefined" || typeof document === "undefined") {
    return { availability: WebGLAvailability.WebGL2 }
  }

  let gpuInfo = hasWebGL2()
  if (gpuInfo) {
    return {
      availability: WebGLAvailability.WebGL2,
      ...gpuInfo,
    }
  }

  gpuInfo = hasWebGL1()
  if (gpuInfo) {
    return {
      availability: WebGLAvailability.WebGL1,
      ...gpuInfo,
    }
  }

  return { availability: WebGLAvailability.NotAvailable }
})

const hasWebGL1 = (): false | GpuInfo => {
  const canvas = document.createElement("canvas")
  const gl = canvas.getContext("webgl")
  if (!gl) {
    return false
  }
  return getGpuInfo(gl)
}

const hasWebGL2 = (): false | GpuInfo => {
  const canvas = document.createElement("canvas")
  const gl = canvas.getContext("webgl2")
  if (!gl) {
    return false
  }
  return getGpuInfo(gl)
}

/**
 * Gets the GPU vendor and renderer using the `WEBGL_debug_renderer_info` extension.
 * See https://developer.mozilla.org/en-US/docs/Web/API/WEBGL_debug_renderer_info for more info.
 */
const getGpuInfo = (gl: WebGLRenderingContext | WebGL2RenderingContext): { vendor?: string; renderer?: string } => {
  const debugInfo = gl.getExtension("WEBGL_debug_renderer_info")
  let vendor: string | undefined = undefined
  let renderer: string | undefined = undefined
  if (debugInfo) {
    vendor = gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL)
    renderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL)
  }
  return {
    vendor,
    renderer,
  }
}
