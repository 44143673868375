import Router from "next/router"
import { useEffect, useRef } from "react"

import { isMobileSafariUa } from "@spatialsys/web/user-agent"

/**
 * Adapted from https://gist.github.com/claus/992a5596d6532ac91b24abe24e10ae81?permalink_comment_id=4301903#gistcomment-4301903
 * Fixes Next.js scroll restoration on browser back/forward, while still preserving scroll on refresh.
 *
 * We make the following modifications to the original code:
 * - Remove the `window` check, since this runs in an effect
 * - Remove the 1ms timeout, this should not be needed with proper SSR
 *
 * ------ All comments here are from the original author ------
 *
 * Scroll restoration to work around bugs in NextJS. At time of writing,
 * they do support `experimental: { scrollRestoration: true }` but there
 * are still bugs with it.
 * (https://github.com/vercel/next.js/issues/20951#issuecomment-1247401017)
 *
 * @param router (Non-reactive) NextRouter instance from _app props
 *
 * @example
 * // place in _app.tsx exported component:
 * useScrollRestoration(router);
 *
 * @see https://gist.github.com/claus/992a5596d6532ac91b24abe24e10ae81?permalink_comment_id=4301903#gistcomment-4301903
 * @see https://github.com/vercel/next.js/issues/3303#issuecomment-628400930
 * @see https://github.com/vercel/next.js/issues/12530#issuecomment-628864374
 * @see https://github.com/vercel/next.js/issues/20951#issuecomment-987252541
 * @see https://github.com/vercel/next.js/issues/37893
 */
export const useScrollRestoration = () => {
  const shouldScrollRestore = useRef(true)

  useEffect(() => {
    if (!("scrollRestoration" in window.history)) return

    // Manual doesn't work well on iOS Safari https://github.com/vercel/next.js/issues/20951#issuecomment-1231966865
    const isMobileSafari = isMobileSafariUa(window.navigator.userAgent)
    window.history.scrollRestoration = isMobileSafari ? "auto" : "manual"

    const saveScrollPos = (url: string) => {
      try {
        sessionStorage.setItem(`scrollPos:${url}`, JSON.stringify({ x: window.scrollX, y: window.scrollY }))
      } catch {
        // If user is in private mode or has storage restriction
        // sessionStorage can throw. JSON.stringify can throw, too.
      }
    }

    const restoreScrollPos = (url: string) => {
      try {
        const json = sessionStorage.getItem(`scrollPos:${url}`)
        const scrollPos = json ? JSON.parse(json) : undefined
        if (scrollPos) {
          window.scrollTo(scrollPos.x, scrollPos.y)
        }
      } catch {
        // If user is in private mode or has storage restriction
        // sessionStorage can throw. JSON.parse can throw, too.
      }
    }

    const onBeforeUnload = (event: BeforeUnloadEvent) => {
      saveScrollPos(Router.asPath)
      delete event["returnValue"]
    }

    const onRouteChangeStart = () => {
      saveScrollPos(Router.asPath)
    }

    /**
     * Calling with relative url, not expected asPath, so this
     * will break if there is a basePath or locale path prefix.
     */
    const triggerRestore = (url: string) => {
      if (shouldScrollRestore.current) {
        shouldScrollRestore.current = false
        restoreScrollPos(url)
      }
    }

    window.addEventListener("beforeunload", onBeforeUnload)
    Router.events.on("routeChangeStart", onRouteChangeStart)
    Router.events.on("routeChangeComplete", triggerRestore)
    Router.beforePopState(() => {
      shouldScrollRestore.current = true
      return true
    })

    // initial load (e.g. page refresh)
    if (shouldScrollRestore.current) {
      triggerRestore(Router.asPath)
    }

    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload)
      Router.events.off("routeChangeStart", onRouteChangeStart)
      Router.events.off("routeChangeComplete", triggerRestore)
      Router.beforePopState(() => true)
    }
  }, [])
}
