import { AxiosInstance } from "axios"

export type CreateTipaltiIframeResponse = {
  iframeURL: string
}

export function createPayoutsEndpoint(client: AxiosInstance) {
  return {
    /** Create Tipalti iframe URL for managing user's Tipalti account */
    async createTipaltiIframe() {
      const response = await client.post<CreateTipaltiIframeResponse>(`/onboarding`)
      return response.data
    },
  }
}
