import { AxiosInstance } from "axios"

export type GetSdkTokenResponse = {
  accessToken: string
}

export function createSdkEndpoints(client: AxiosInstance) {
  return {
    async getSdkToken(): Promise<GetSdkTokenResponse> {
      const resp = await client.get<GetSdkTokenResponse>("/token")
      return resp.data
    },
  }
}
