import { AxiosInstance } from "axios"

import type { FeatureFlags, TreatmentsInBootstrapRetool } from "../types"

export type GetFeatureFlagsResponse = {
  /**
   * Legacy treatments from Retool. Will be deprecated eventually,
   * should use ConfigCat flags instead (`flags`)
   */
  bootstrapTreatments: TreatmentsInBootstrapRetool
  featureFlags: FeatureFlags
}

export function createFeatureFlagEndpoints(client: AxiosInstance) {
  return {
    async get(): Promise<GetFeatureFlagsResponse> {
      return (await client.get<GetFeatureFlagsResponse>(`/`)).data
    },
  }
}
