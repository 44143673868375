import { produce } from "immer"
import type { Channel, StreamChat } from "stream-chat"

import { ActionT, GetActionType, makeActionCreator } from "@spatialsys/js/redux"

export interface ChatState {
  channel: Channel | null
  client: StreamChat | null
  error: Error | null
  /** The input element, so that it can be focused from a saga */
  inputElement: HTMLTextAreaElement | null
  isInputFocused: boolean
  loaded: boolean
  /**
   * Whether the user joined an empty channel and may potentially witness the first message being sent in the channel
   * Used for analytic purposes to determine the potential impacts of an optimization in connecting to chat
   */
  maySeeFirstMessage: boolean
  /** The number of new messages sent while the panel is closed */
  numNewMessages: number
}

export const initialChatState: ChatState = {
  channel: null,
  client: null,
  loaded: false,
  error: null,
  isInputFocused: false,
  numNewMessages: 0,
  maySeeFirstMessage: false,
  inputElement: null,
}

export enum ChatActionType {
  CloseChat = "CloseChat",
  FocusChatInput = "FocusChatInput",
  IncrementNewChatMessages = "IncrementNewChatMessages",
  OpenChat = "OpenChat",
  ProcessChatMessage = "ProcessChatMessage",
  ResetChat = "ResetChat",
  SetChatChannel = "SetChatChannel",
  SetChatClient = "SetChatClient",
  SetChatError = "SetChatError",
  SetChatInputElement = "SetChatInputElement",
  SetChatMaySeeFirstMessage = "SetChatMaySeeFirstMessage",
  ToggleChat = "ToggleChat",
  UnfocusChatInput = "UnfocusChatInput",
}

export type CloseChat = ActionT<ChatActionType.CloseChat>
export type FocusChatInput = ActionT<ChatActionType.FocusChatInput>
export type IncrementNewChatMessages = ActionT<ChatActionType.IncrementNewChatMessages>
export type OpenChat = ActionT<ChatActionType.OpenChat>
export type SetChatChannel = ActionT<ChatActionType.SetChatChannel, Channel | null>
export type SetChatClient = ActionT<ChatActionType.SetChatClient, StreamChat | null>
export type SetChatError = ActionT<ChatActionType.SetChatError, Error | null>
export type SetChatInputElement = ActionT<ChatActionType.SetChatInputElement, HTMLTextAreaElement | null>
export type ProcessChatMessage = ActionT<ChatActionType.ProcessChatMessage>
export type SetChatMaySeeFirstMessage = ActionT<ChatActionType.SetChatMaySeeFirstMessage, boolean>
export type ResetChat = ActionT<ChatActionType.ResetChat>
export type ToggleChat = ActionT<ChatActionType.ToggleChat>
export type UnfocusChatInput = ActionT<ChatActionType.UnfocusChatInput>

export const ChatActions = {
  closeChat: makeActionCreator<CloseChat>(ChatActionType.CloseChat),
  focusChatInput: makeActionCreator<FocusChatInput>(ChatActionType.FocusChatInput),
  incrementNewChatMessages: makeActionCreator<IncrementNewChatMessages>(ChatActionType.IncrementNewChatMessages),
  openChat: makeActionCreator<OpenChat>(ChatActionType.OpenChat),
  setChatChannel: makeActionCreator<SetChatChannel>(ChatActionType.SetChatChannel),
  setChatClient: makeActionCreator<SetChatClient>(ChatActionType.SetChatClient),
  setChatError: makeActionCreator<SetChatError>(ChatActionType.SetChatError),
  setChatInputElement: makeActionCreator<SetChatInputElement>(ChatActionType.SetChatInputElement),
  processChatMessage: makeActionCreator<ProcessChatMessage>(ChatActionType.ProcessChatMessage),
  setChatMaySeeFirstMessage: makeActionCreator<SetChatMaySeeFirstMessage>(ChatActionType.SetChatMaySeeFirstMessage),
  toggleChat: makeActionCreator<ToggleChat>(ChatActionType.ToggleChat),
  resetChat: makeActionCreator<ResetChat>(ChatActionType.ResetChat),
  unfocusChatInput: makeActionCreator<UnfocusChatInput>(ChatActionType.UnfocusChatInput),
}

export type ChatAction = GetActionType<typeof ChatActions>

export const chatReducer = (state: ChatState, action: ChatAction): ChatState => {
  switch (action.type) {
    case ChatActionType.SetChatChannel:
      return produce(state, (draft) => {
        draft.channel = action.payload
        draft.loaded = true
      })
    case ChatActionType.SetChatClient:
      return produce(state, (draft) => void (draft.client = action.payload))
    case ChatActionType.SetChatError:
      return produce(state, (draft) => void (draft.error = action.payload))
    case ChatActionType.SetChatInputElement:
      return { ...state, inputElement: action.payload }
    case ChatActionType.IncrementNewChatMessages:
      return { ...state, numNewMessages: state.numNewMessages + 1 }
    case ChatActionType.SetChatMaySeeFirstMessage:
      return produce(state, (draft) => void (draft.maySeeFirstMessage = action.payload))
    case ChatActionType.OpenChat:
      // Reset the new message count when opening chat
      return { ...state, numNewMessages: 0 }
    case ChatActionType.FocusChatInput:
      return { ...state, isInputFocused: true }
    case ChatActionType.UnfocusChatInput:
      return { ...state, isInputFocused: false }
    case ChatActionType.ResetChat:
      return initialChatState
    default:
      return state
  }
}
