import { AxiosInstance } from "axios"

import { Wallet } from "../types"

export type GetUsersWalletRequest = {
  /** isSandbox is an override flag which denotes that the sandbox balance should always be included in the response for transaction status */
  isSandbox?: boolean
  /** spaceId allows SAPI to determine if the space is one used for testing. If so, the sandbox balance will be included in the response for successful transactions */
  spaceId?: string
  userId: string
}

export type GetUsersWalletResponse = Wallet

export function createWalletEndpoint(client: AxiosInstance) {
  return {
    /**
     * Gets wallet for a given user
     */
    async getUsersWallet({ userId, spaceId, isSandbox }: GetUsersWalletRequest): Promise<GetUsersWalletResponse> {
      // specifically omit spaceId if isSandbox is truthy; axios will omit spaceId if it's undefined
      const params = isSandbox ? { sandbox: 1 } : { space_id: spaceId }
      const response = await client.get<GetUsersWalletResponse>(`/${userId}/balance`, { params })
      return response.data
    },
  }
}
