import { AxiosInstance } from "axios"

import { CoinPackage, LedgerTransactionStatus } from "../types"

export type GetCoinPackagesResponse = {
  prices: CoinPackage[]
}

export type GetTransactionStatusResponse = {
  /**
   * The new balance of the user's account after the transaction has been processed.
   */
  spatialCoinsBalance: number
  spatialPaymentToken: string
  status: LedgerTransactionStatus
  userID: string
}

export type GetTransactionStatusRequest = {
  /** isSandbox is an override flag which denotes that the sandbox balance should always be included in the response for transaction status */
  isSandbox?: boolean
  /** spaceId allows SAPI to determine if the space is one used for testing. If so, the sandbox balance will be included in the response for successful transactions */
  spaceId?: string
  /** spatialPaymentToken is the key used to lookup the transaction */
  spatialPaymentToken: string
}

export function createCoinsEndpoint(client: AxiosInstance) {
  return {
    async getCoinPackages() {
      const response = await client.get<GetCoinPackagesResponse>(`/prices`)
      return response.data
    },
    /** Get the status of a purchase transaction */
    async getTransactionStatus({ spatialPaymentToken, spaceId, isSandbox }: GetTransactionStatusRequest) {
      // specifically omit spaceId if isSandbox is truthy; axios will omit spaceId if it's undefined
      const params = isSandbox ? { sandbox: 1 } : { space_id: spaceId }
      const response = await client.get<GetTransactionStatusResponse>(`/${spatialPaymentToken}`, { params })
      return response.data
    },
  }
}
