import { useMemo } from "react"

import { screensPx } from "@spatialsys/theme/screens"

import { useWindowSize } from "./use-window-size"

const createBreakpoint =
  (breakpoints: { [name: string]: number } = { ...screensPx, none: 0 }) =>
  () => {
    const { width, height } = useWindowSize()

    const sortedBreakpoints = useMemo(() => Object.entries(breakpoints).sort((a, b) => (a[1] >= b[1] ? 1 : -1)), [])

    if (width === Infinity) return { breakpoint: "none", width, height }

    const breakpoint = sortedBreakpoints.reduce((acc, [name, bpWidth]) => {
      if (width >= bpWidth) {
        return name
      } else {
        return acc
      }
    }, sortedBreakpoints[0][0])
    return { breakpoint, width, height }
  }

/**
 * Returns the current breakpoint, width, and height of the window.
 * Adapted from https://github.com/streamich/react-use/blob/master/src/factory/createBreakpoint.ts.
 * If no breakpoint is met, breakpoint will be "none".
 *
 * SSR-safe, however the initial breakpoint will be "none" until the client has rendered.
 */
export const useBreakpoint = createBreakpoint()
