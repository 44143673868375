import { AxiosInstance } from "axios"

import { Badge, Item, MicroTransaction, Payout, World } from "../types"

export type GetWorldsItemsRequest = {
  count?: number
  offset?: number
  worldId: string
}

export const defaultGetWorldsItemsRequestPaginationArgs = {
  count: 100,
  offset: 0,
}

export type UpdateWorldRequest = {
  description: string
  displayName: string
  worldId: string
}

export type GetWorldsPublishedItemsResponse = {
  continuationToken: string
  items: Item[]
}

export type GetWorldsItemsResponse = {
  items: Item[]
}

export type GetWorldRequest = {
  worldId: string
}

export type GetWorldsBadgesRequest = {
  worldId: string
}
export type GetWorldsBadgesResponse = {
  badges: Badge[]
}

export type GetWorldCurrencyRequest = { worldId: string }

export type GetWorldBalanceRequest = { worldId: string }
export type GetWorldBalanceResponse = {
  /** Coins that are "pending"; they have not surpassed the `holdoutPeriod` time */
  balanceOnHold: number
  /** The exchange rate for coins */
  coinExchangeRate: number
  /** The number of days that coins are held before they can be cashed out */
  holdoutPeriod: number
  /** The minimum number of coins required before user can cash out */
  minimumPayoutThreshold: number
  /** Coins that are ready to cash out */
  payableBalance: number
}

export type GetTransactionsHistoryRequest = {
  /** ms since epoch, defaults to now */
  end?: number
  limit?: number
  offset?: number
  /** ms since epoch, defaults to epoch */
  start?: number
  worldId: string
}
export type GetTransactionsHistoryResponse = {
  microtransactions: MicroTransaction[]
}
export const defaultGetTransactionsHistoryRequestArgs = { limit: 200, offset: 0 }

export type GetPayoutsHistoryRequest = {
  /** End time in ms since epoch, defaults to now */
  end?: number
  limit?: number
  offset?: number
  /** Start time in ms since epoch, defaults to epoch */
  start?: number
  worldId: string
}
export const defaultGetPayoutsHistoryRequestArgs = { limit: 100, offset: 0 }
export type GetPayoutsHistoryResponse = {
  payoutHistory: Payout[]
}

export type RequestPayoutRequest = { worldId: string }

export function createWorldsEndpoint(client: AxiosInstance) {
  return {
    /** Get a world by ID */
    async getWorld({ worldId }: GetWorldRequest) {
      const response = await client.get<World>(`/${worldId}`)
      return response.data
    },

    /** Update a world by ID */
    async updateWorld({ worldId, ...rest }: UpdateWorldRequest) {
      const response = await client.put<World>(`/${worldId}`, rest)
      return response.data
    },

    /**
     * Gets all _published_ items associated with a world
     */
    async getPublishedItems(args: GetWorldsItemsRequest) {
      const { count, offset, worldId } = { ...defaultGetWorldsItemsRequestPaginationArgs, ...args }
      const response = await client.get<GetWorldsPublishedItemsResponse>(`/${worldId}/shop`, {
        params: { count, offset },
      })
      return response.data
    },

    /**
     * Gets all items associated with a world, published and unpublished
     */
    async getItems(args: GetWorldsItemsRequest) {
      const { count, offset, worldId } = { ...defaultGetWorldsItemsRequestPaginationArgs, ...args }
      const response = await client.get<GetWorldsItemsResponse>(`/${worldId}/items`, { params: { count, offset } })
      return response.data
    },

    /**
     * Gets all badges associated with a world
     */
    async getBadges({ worldId }: GetWorldsBadgesRequest) {
      const response = await client.get<GetWorldsBadgesResponse>(`/${worldId}/badges`)
      return response.data
    },

    /**
     * Gets currency for a world, if it exists. Returns 404 if it doesn't exist
     */
    async getCurrency({ worldId }: GetWorldCurrencyRequest) {
      const response = await client.get<Item>(`/${worldId}/currency`)
      return response.data
    },

    /**
     * Gets a creators balance for that world. This balance is incremented from item sales in that world.
     * Requires the user to be an owner of the world.
     */
    async getBalance({ worldId }: GetWorldBalanceRequest) {
      const response = await client.get<GetWorldBalanceResponse>(`/${worldId}/balance`)
      return response.data
    },

    /**
     * Gets a list of transactions for a given world.
     * Requires the user to be an owner of the world.
     */
    async getTransactionsHistory(args: GetTransactionsHistoryRequest) {
      const { end, limit, offset, start, worldId } = { ...defaultGetTransactionsHistoryRequestArgs, ...args }
      const response = await client.get<GetTransactionsHistoryResponse>(`/${worldId}/microtransactions`, {
        params: { end, limit, offset, start },
      })
      return response.data
    },

    /**
     * Gets a list of payouts.
     * Requires the user to be an owner of the world.
     */
    async getPayoutsHistory(args: GetPayoutsHistoryRequest) {
      const { end, limit, offset, start, worldId } = { ...defaultGetPayoutsHistoryRequestArgs, ...args }
      const response = await client.get<GetPayoutsHistoryResponse>(`/${worldId}/payouts`, {
        params: { end, limit, offset, start },
      })
      return response.data
    },

    /**
     * Requires the user to be an owner of the world.
     */
    async requestPayout({ worldId }: RequestPayoutRequest) {
      const response = await client.post<void>(`/${worldId}/payouts`)
      return response.data
    },
  }
}
