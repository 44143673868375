import { isAxiosError } from "axios"

import { ISAPIError } from "../types"

export class SapiError extends Error {
  code: ISAPIError["code"]
  customData?: ISAPIError["customData"]
  display: ISAPIError["display"]
  fields?: ISAPIError["fields"]
  statusCode: ISAPIError["statusCode"]

  constructor(err: ISAPIError) {
    super(err.message)
    this.code = err.code
    this.customData = err.customData
    this.display = err.display
    this.fields = err.fields
    this.statusCode = err.statusCode
  }
}

export function legacySapiErrorMessage(error: any): string | undefined {
  if (error.response) {
    const sapiError = getFirstSapiError(error)
    if (sapiError) {
      return sapiError.message
    } else if (error.response?.data) {
      return error.response.data.replace(/\n/g, "") // Remove newlines, this is raw response data
    }
  }
  return undefined
}
/**
 * Parses the error response and returns the first SAPI error code.
 * Returns undefined if it fails to get the error code.
 */
export function getFirstSapiError(error: any): SapiError | undefined {
  const errors = parseSapiError(error)
  if (errors?.length > 0) {
    const firstErr = errors[0]
    if (firstErr instanceof SapiError) {
      return firstErr
    }
  }
  return undefined
}

/**
 * Parses a SAPI error response into an array of SAPIError objects
 * Supports parsing the legacy error format, which was "double-nested" JSON
 */
export function parseSapiError(error: any): Error[] {
  if (isAxiosError(error)) {
    if (error.response?.data) {
      return Array.from(parseError(error.response.data))
    }
  }

  return [new Error("Unknown error")]
}

export function* parseError(error: any): Generator<Error> {
  try {
    // Support the legacy error format, where the error message is double-nested as a stringified JSON object
    if ("message" in error) {
      if (error.message.startsWith("{") && error.message.endsWith("}")) {
        yield* parseError(JSON.parse(error.message))
      }

      // Check that it is a SAPIError
      if (error.code && error.message) {
        yield new SapiError(error)
      } else {
        // Preserve the error, but it is not a `SapiError`
        yield new Error(error.message)
      }
      // SAPI returns a field `errors`, which contains an array of `ISAPIError`
    } else if ("errors" in error && Array.isArray(error.errors)) {
      for (const err of error.errors) {
        yield* parseError(err)
      }
    }
  } catch (e) {
    yield new Error("Unknown error")
  }
}
