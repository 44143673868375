import { AxiosInstance } from "axios"

import type { ThirdPartyGame } from "@spatialsys/js/sapi/types"

export function createThirdPartyGameEndpoints(client: AxiosInstance) {
  return {
    async get(args: { slug: string }) {
      const response = await client.get<{ game: ThirdPartyGame }>(`/${args.slug}`)
      return response.data
    },
    async getAll() {
      const response = await client.get<{ games: ThirdPartyGame[] }>(`/`)
      return response.data
    },
    async getFeed() {
      const response = await client.get<{ games: ThirdPartyGame[] }>(`/feed`)
      return response.data
    },
    async getGamesForCategory(args: { slug: string }) {
      const response = await client.get<{ games: ThirdPartyGame[] }>(`/category/${args.slug}`)
      return response.data
    },
  }
}
