import { call, put, select } from "typed-redux-saga/macro"

import { createKeyDownChannel, takeEveryAndClose } from "@spatialsys/js/redux"
import { isInputFocused } from "@spatialsys/js/util/is-input-focused"
import { serializeKeyboardEvent } from "@spatialsys/js/util/keyboard-event"
import { startViewTransition } from "@spatialsys/js/util/start-view-transition"
import { InteractionType } from "@spatialsys/react/analytics"
import { Actions, AppState, Modals, Selectors } from "@spatialsys/web/app-state"
import Config from "@spatialsys/web/config"

/**
 * Global hotkeys that can be used anywhere in the app.
 * This file has a lot of overlap with `hotkeys-saga.ts` in `web/space`. Eventually,
 * we should have a way of registering hotkeys in a central place, rather than having duplicated logic.
 */
export function* globalHotkeysSaga() {
  const channel = createKeyDownChannel()
  yield* takeEveryAndClose(channel, (e: KeyboardEvent) => handleKeyDown(e, hotkeyMap))
}

type HotkeyOptions = {
  /*
   * If true, the hotkey is ignored if any modals that block hotkeys are open
   * If given a function AND if a modal is open, based on the result
   * of the function, it decides to ignore the keypress or not
   */
  ignoreIfModalOpen?: true | ((state: AppState) => boolean)
  allowIfInputFocused?: true | ((state: AppState) => boolean)
}

type HotkeyMap = Record<string, { handler: (e: KeyboardEvent) => void; options?: HotkeyOptions }>

const hotkeyMap: HotkeyMap = {
  "ctrl+shift+Backquote": {
    *handler() {
      const canView = yield* select((state: AppState) => Selectors.canViewDebugSettings(state, Config))
      if (!canView) {
        return
      }
      yield* put(Actions.openModal({ type: Modals.Debug }))
    },
  },
  // Toggle theater and full screen hotkeys. These are only applicable to the `/s` page.
  "alt+KeyK": {
    *handler() {
      // Check if on the `/s` page
      const isInSpace = yield* select((state: AppState) => Boolean(state.space.id))
      const pathPrefix = yield* select((state: AppState) => state.space.pathPrefix)
      if (!isInSpace || pathPrefix !== "/s") return

      yield* call(startViewTransition)
      yield* put(Actions.toggleCanvasViewSize({ type: InteractionType.Keypress }))
    },
  },
  "alt+KeyL": {
    *handler() {
      // Check if on the `/s` page
      const isInSpace = yield* select((state: AppState) => Boolean(state.space.id))
      const pathPrefix = yield* select((state: AppState) => state.space.pathPrefix)
      if (!isInSpace || pathPrefix !== "/s") return

      yield* call(startViewTransition)
      yield* put(Actions.toggleCanvasMaximized({ type: InteractionType.Keypress }))
    },
  },
}

function* handleKeyDown(event: KeyboardEvent, hotkeyMap: HotkeyMap) {
  const serializedEvent = serializeKeyboardEvent(event)
  const entry = hotkeyMap[serializedEvent]
  if (!entry) {
    return
  }

  // Short-circuit if an input field is focused & allow if input not enabled
  if (isInputFocused()) {
    if (!entry.options?.allowIfInputFocused) {
      return
    }
    if (typeof entry.options?.allowIfInputFocused === "function") {
      const shouldAllow = yield* select(entry.options?.allowIfInputFocused)
      if (!shouldAllow) {
        return
      }
    }
  }

  // Short-circuit if modal is open, and the hotkey should be ignored if modal is open.
  if (entry.options?.ignoreIfModalOpen) {
    const openModalsBlockingHotkeysCount = yield* select((state: AppState) => state.openModalsBlockingHotkeysCount)
    if (openModalsBlockingHotkeysCount > 0) {
      if (typeof entry.options.ignoreIfModalOpen !== "function") {
        return
      }
      const shouldIgnore = yield* select(entry.options.ignoreIfModalOpen)
      if (shouldIgnore) {
        return
      }
    }
  }

  yield* call(entry.handler, event, entry.options)
}
