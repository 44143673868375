import { AxiosInstance } from "axios"

import { createAppleSsoEndpoints } from "./apple-sso"
import { createAzureSsoEndpoints } from "./azure-sso"
import { createEmailPwEndpoints } from "./email-pw"
import { createEthereumEndpoints } from "./ethereum"
import { createPostLoginEndpoints } from "./post-login"
import { createVerifyEndpoints } from "./verify"

export function createAuthEndpoints(client: AxiosInstance) {
  return {
    appleSso: createAppleSsoEndpoints(client),
    azureSso: createAzureSsoEndpoints(client),
    emailPw: createEmailPwEndpoints(client),
    postLogin: createPostLoginEndpoints(client),
    verify: createVerifyEndpoints(client),
    ethereum: createEthereumEndpoints(client),
  }
}
