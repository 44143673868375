import { call } from "typed-redux-saga/macro"

import { waitUntilExists } from "@spatialsys/use-saga"
import { AppState } from "@spatialsys/web/app-state"
import Config from "@spatialsys/web/config"
import { logger } from "@spatialsys/web/logger"

export function* bugReporterSaga() {
  const flags = yield* waitUntilExists((state: AppState) => state.featureFlags)

  if (!flags.bugReporter) {
    return
  }
  const user = yield* waitUntilExists((state: AppState) => state.user)

  const unityVersion = yield* waitUntilExists((state: AppState) => state.unity.version)
  try {
    const { default: markerSDK } = yield* call(() => import("@marker.io/browser"))
    yield* call([markerSDK, markerSDK.loadWidget], {
      destination: Config.MARKERIO_DESTINATION,
      ssr: {
        renderDelay: 3000, // 0 - 15000 (ms)
      },
      reporter: {
        email: user.email,
        fullName: user.displayName,
      },
      customData: {
        version: Config.SPATIAL_UNITY_VERSION,
        deploymentEnv: Config.DEPLOYMENT_ENV,
        userEmail: user.email,
        unityVersion: unityVersion.versionData.versionString,
      },
    })
  } catch (err) {
    logger.error("Unexpected error while loading Marker.io widget.", err as Error)
  }
}
