import { SAMLAuthProvider, getAuth, signInWithPopup } from "firebase/auth"
import { call, put, select } from "typed-redux-saga/macro"

import { Actions, AppState, AuthConnection, LoginWithSamlSso } from "@spatialsys/web/app-state"
import { sapiClient } from "@spatialsys/web/sapi"

import { getTokenForFirebaseUser } from "../../auth"

/** Attempts to log in with SAML SSO by calling SAPI (Firebase) */
export function* loginWithSamlSso({ payload }: LoginWithSamlSso) {
  try {
    const resp = yield* call(sapiClient.samlSso.login, payload)
    const provider = new SAMLAuthProvider(resp.providerID)
    const firebaseAuth = yield* call(getAuth)
    const credentials = yield* call(signInWithPopup, firebaseAuth, provider)
    const authState = yield* call(getTokenForFirebaseUser, credentials.user)

    if (payload.forceRedirect) {
      window.location.reload()
    } else {
      yield* put(Actions.setAuthSuccess({ ...authState, loginMethod: AuthConnection.SSO }))
      const spaceId = yield* select((state: AppState) => state.space.id)
      if (spaceId) {
        yield* put(Actions.signInFromSpace({ authToken: authState.accessToken }))
      }
    }
  } catch (error) {
    yield* put(Actions.setLoginError(error))
  }
}
