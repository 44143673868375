import { useEffect, useState } from "react"

/**
 *  From https://usehooks.com/useWindowSize/
 *
 * Modified slightly to be SSR safe.  The initial window size will be 0x0 until the client has rendered.
 */
export function useWindowSize() {
  const getSize = () => {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    }
  }
  const [windowSize, setWindowSize] = useState({ width: 0, height: 0 })
  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getSize())
    }

    // Get the initial window size on first mount
    handleResize()

    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return windowSize
}
