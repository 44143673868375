import * as Sentry from "@sentry/nextjs"

import {
  GET_FRIENDS_QUERY_KEY,
  GET_FRIEND_REQUESTS_QUERY_KEY,
  SEARCH_USERS_KEY,
} from "@spatialsys/react/query-hooks/friends/query-keys"
import { GET_ANOTHER_USERS_LIST_OF_FRIENDS_QUERY_KEY } from "@spatialsys/react/query-hooks/profiles"
import { waitUntilExists } from "@spatialsys/use-saga"
import { AppState } from "@spatialsys/web/app-state"
import { toast } from "@spatialsys/web/ui/toast"

import { WebSocketMessage } from "./types"

export function* webSocketHandler(event: MessageEvent<string> | null) {
  const queryClient = yield* waitUntilExists((state: AppState) => state.reactQueryClient)

  if (event === null) return

  try {
    const data: WebSocketMessage = JSON.parse(event.data)

    const friend = data.friend

    switch (data.action) {
      case "FRIEND_REQUEST":
        void queryClient.invalidateQueries([GET_FRIEND_REQUESTS_QUERY_KEY])
        void queryClient.invalidateQueries([SEARCH_USERS_KEY])

        toast.success(`${friend.displayName} sent you a friend request!`)
        break
      case "FRIEND_CONFIRMED":
        void queryClient.invalidateQueries([GET_FRIENDS_QUERY_KEY])
        void queryClient.invalidateQueries([GET_FRIEND_REQUESTS_QUERY_KEY])
        void queryClient.invalidateQueries([SEARCH_USERS_KEY])
        void queryClient.invalidateQueries([GET_ANOTHER_USERS_LIST_OF_FRIENDS_QUERY_KEY, data.friend.id])

        toast.success(`You're now friends with ${friend.displayName}!`)

        break
      case "FRIEND_REMOVED":
        void queryClient.invalidateQueries([GET_FRIENDS_QUERY_KEY])
        void queryClient.invalidateQueries([GET_FRIEND_REQUESTS_QUERY_KEY])
        void queryClient.invalidateQueries([SEARCH_USERS_KEY])
        void queryClient.invalidateQueries([GET_ANOTHER_USERS_LIST_OF_FRIENDS_QUERY_KEY, data.friend.id])

        break
      case "FRIEND_ONLINE":
        void queryClient.invalidateQueries([GET_FRIENDS_QUERY_KEY])

        break
      case "FRIEND_OFFLINE":
        void queryClient.invalidateQueries([GET_FRIENDS_QUERY_KEY])

        break
      case "FRINED_JOINED_SPACE":
        void queryClient.invalidateQueries([GET_FRIENDS_QUERY_KEY])

        break
      case "FRIEND_LEFT_SPACE":
        void queryClient.invalidateQueries([GET_FRIENDS_QUERY_KEY])

        break
      default:
        Sentry.captureException(new Error(`Unexpected WebSocketMessage action: ${data.action}`), { extra: data })
        break
    }
  } catch (error) {
    Sentry.captureException(error)
  }
}
