import { Patch, applyPatches, enablePatches, produce } from "immer"

import { ActionT, GetActionType, makeActionCreator } from "@spatialsys/js/redux"
import { AppState as UnityAppState, emptyAppState } from "@spatialsys/unity/app-state"
import { VersionData } from "@spatialsys/web/config"

import { AppAction, AppActionType } from "../app-state"
import { SpaceAction, SpaceActionType } from "../space"

// We use immer's feature for creating or applying state patches immutably in the reducer.
enablePatches()

export type UnityClientState = {
  appState: UnityAppState
  appStateLoaded: boolean
  buildUrlBase: string
  downloadState: DownloadState
  initializationProgress: number
  isDoneBooting: boolean
  /**
   * If true, will begin downloading Unity
   */
  shouldDownload: boolean
  shouldStart: boolean
  version: VersionState | null
}

export enum DownloadState {
  NotStarted,
  InProgress,
  Done,
}

export type VersionSource = "config" | "flag" | "localStorage" | "localUnityBuild"

export interface VersionState {
  isCompressed: boolean
  source: VersionSource
  urlBase: string
  versionData: VersionData
}

export const createInitialUnityState = (): UnityClientState => {
  return {
    appState: emptyAppState,
    appStateLoaded: false,
    isDoneBooting: false,
    shouldDownload: false,
    shouldStart: false,
    buildUrlBase: "",
    downloadState: DownloadState.NotStarted,
    initializationProgress: 0,
    version: null,
  }
}

export enum UnityClientActionType {
  DownloadUnity = "DownloadUnity",
  FinishUnityBooting = "FinishUnityBooting",
  PatchAppState = "PatchAppState",
  SetUnityAppState = "SetUnityAppState",
  SetUnityDownloadState = "SetUnityDownloadState",
  SetUnityInitializationProgress = "SetUnityInitializationProgress",
  SetVersion = "SetVersion",
  StartUnity = "StartUnity",
}

export type DownloadUnity = ActionT<UnityClientActionType.DownloadUnity>
export type PatchAppState = ActionT<UnityClientActionType.PatchAppState, Patch[]>
export type SetUnityAppState = ActionT<UnityClientActionType.SetUnityAppState, UnityAppState>
export type SetUnityDoneBooting = ActionT<UnityClientActionType.FinishUnityBooting>
export type SetUnityDownloadState = ActionT<UnityClientActionType.SetUnityDownloadState, DownloadState>
export type SetUnityInitializationProgress = ActionT<UnityClientActionType.SetUnityInitializationProgress, number>
export type SetVersion = ActionT<UnityClientActionType.SetVersion, VersionState>
export type StartUnity = ActionT<UnityClientActionType.StartUnity>

export const UnityClientActions = {
  downloadUnity: makeActionCreator<DownloadUnity>(UnityClientActionType.DownloadUnity),
  finishUnityBooting: makeActionCreator<SetUnityDoneBooting>(UnityClientActionType.FinishUnityBooting),
  patchAppState: makeActionCreator<PatchAppState>(UnityClientActionType.PatchAppState),
  setUnityAppState: makeActionCreator<SetUnityAppState>(UnityClientActionType.SetUnityAppState),
  setUnityDownloadState: makeActionCreator<SetUnityDownloadState>(UnityClientActionType.SetUnityDownloadState),
  setUnityInitializationProgress: makeActionCreator<SetUnityInitializationProgress>(
    UnityClientActionType.SetUnityInitializationProgress
  ),
  setVersion: makeActionCreator<SetVersion>(UnityClientActionType.SetVersion),
  startUnity: makeActionCreator<StartUnity>(UnityClientActionType.StartUnity),
}

export type UnityClientAction = GetActionType<typeof UnityClientActions>

export function unityClientReducer(
  state: UnityClientState,
  action: AppAction | SpaceAction | UnityClientAction
): UnityClientState {
  switch (action.type) {
    case UnityClientActionType.DownloadUnity:
      return produce(state, (draft) => void (draft.shouldDownload = true))
    case AppActionType.SetSpaceToCreate:
    case SpaceActionType.SetIsCreateAvatarFlow:
    case UnityClientActionType.StartUnity:
      return produce(state, (draft) => {
        draft.shouldDownload = true
        draft.shouldStart = true
      })
    case UnityClientActionType.SetUnityInitializationProgress:
      return produce(state, (draft) => void (draft.initializationProgress = action.payload))
    case UnityClientActionType.SetUnityDownloadState:
      return produce(state, (draft) => void (draft.downloadState = action.payload))
    case UnityClientActionType.SetVersion:
      return produce(state, (draft) => {
        draft.version = action.payload
        draft.buildUrlBase = action.payload.urlBase
      })
    case UnityClientActionType.SetUnityAppState:
      return produce(state, (draft) => {
        draft.appState = action.payload
        draft.appStateLoaded = true
      })
    case UnityClientActionType.PatchAppState:
      return {
        ...state,
        appState: applyPatches(state.appState, action.payload),
      }
    case UnityClientActionType.FinishUnityBooting:
      return produce(state, (draft) => void (draft.isDoneBooting = true))
    default:
      return state
  }
}
