import {
  AvatarData,
  PaidPricingPlanId,
  PricingPlan,
  PricingPlanId,
  SAPIRoom,
  SpaceLimitsResponse,
  SpaceTemplate,
  TreatmentsRetool,
} from "./types"

/**
 * The treatments used internally within the React applications. Some of the keys are renamed from their Retool values to be more semantic/meaningful.
 * Each treatment is also typed correctly according to its intended usage, rather than as a string.
 *
 * This type should never be instantiated directly, but only created using `convertTreatmentsFromSapi`
 */
export interface ITreatments {
  authlessRpmAvatars: string[]
}

/**
 * Converts the treatment object from SAPI into `ITreatments`, accounting for default and undefined values
 *
 * TODO (DEV-6573): we can consider memoizing this function (i.e. with `lodash.memo), when yarn workspaces is set up to enable external dependencies within `SpatialPackages`.
 */
export const convertTreatmentsFromSapi = (treatments: TreatmentsRetool): ITreatments => {
  return {
    authlessRpmAvatars: treatments.authlessRpmAvatars?.split("|") ?? [],
  }
}

export type SAPIRoomSubsetSpaceAdmin = Pick<SAPIRoom, "ownerID" | "roomAdmins">

/** Determines whether a user is an admin of a room. The room owner is always an admin. */
export const isAdminOfSAPIRoom = (room: SAPIRoom | SAPIRoomSubsetSpaceAdmin, userID: string): boolean => {
  return room.roomAdmins.includes(userID) || room.ownerID === userID
}

export const getCustomNftEnvironmentId = (spaceTemplate: SpaceTemplate) => {
  return `${spaceTemplate.nftChain}/${spaceTemplate.contractAddress}/${spaceTemplate.tokenID}`
}

export const formatAvatarMixpanelProperties = (avatarData: AvatarData | undefined) => {
  const avatarStyle =
    avatarData?.activeAvatarStyle === "REALISTIC"
      ? avatarData.avatarID
        ? "REALISTIC"
        : "DEFAULT"
      : avatarData?.activeAvatarStyle
  return {
    "Avatar Style": avatarStyle,
    "Avatar Body Type": avatarData?.avatarBody,
  }
}

export type SpatialStoreFlag = { id: string; shareId: string }

/**
 * Attempts to parse a JSON string as a Spatial Store space feature flag. Returns null if the string is not a valid flag.
 *
 * Catches errors from `JSON.parse`, so this function _not_ not throw but instead returns null.
 *
 * Note: we do not run this at the SAPI or query hook layer because:
 * - I do not think it's good practice to change the underlying SAPI response, though it may make sense here
 * - It would be very tedious to do consistently at the query hook layer. We would need to do it in the feature flag hook, but
 * also anywhere we prefetch flags in SSR, as well as possibly anywhere we fetch flags in sagas.
 */
export function parseSpatialStoreSpaceFeatureFlag(s: string) {
  try {
    const parsedValue = JSON.parse(s) as SpatialStoreFlag
    if (!parsedValue.id || !parsedValue.shareId) {
      return null
    }
    return parsedValue
  } catch (error) {
    return null
  }
}

/**
 * Returns the human-readable name from the pricing plan ID.
 */
export function pricingPlanIdToName(planId: PricingPlanId) {
  switch (planId) {
    case "FREE":
      return "Free"
    case "FREE_UGC":
      return "Free UGC"
    case "PRO":
      return "Pro"
    case "BUSINESS":
      return "Business"
    case "ENTERPRISE":
      return "Enterprise"
    default:
      return "Unknown"
  }
}

/**
 * List of all paid pricing plan IDs, pre-sorted in ascending order.
 */
export const sortedPaidPricingPlanIds: PaidPricingPlanId[] = ["PRO", "BUSINESS", "ENTERPRISE"]

/**
 * Compares two paid pricing plan tiers and returns the "higher" (typically more expensive) one.
 */
export function getHigherPaidPricingPlan(a: PaidPricingPlanId, b: PaidPricingPlanId): PaidPricingPlanId {
  return sortedPaidPricingPlanIds.indexOf(a) >= sortedPaidPricingPlanIds.indexOf(b) ? a : b
}

/**
 * Returns a map from pricing plan ID to pricing plan, based on the provided pricing plans.
 */
export function getPricingPlansMap(prices: PricingPlan[]): Map<PricingPlanId, PricingPlan> {
  return new Map(prices.map((p) => [p.id, p] as const))
}

type CurrentLimitsUsage = SpaceLimitsResponse & {
  concurrentUsersCount: number
}

// Temporary until backend changes are merged
type PlanTechnicalLimits = {
  concurrentUsersLimit: number
  packageSizeSoftLimit: number
  uploadStorageSoftLimit: number
}

/**
 * Returns the best plan ID to upgrade to, based on current usage and available plans.
 */
export function getBestPricingPlanIdFromLimits(
  currentUsage: CurrentLimitsUsage,
  prices: PricingPlan[]
): PaidPricingPlanId {
  // Temporary until backend changes are merged on staging/prod
  const planIdToTechnicalLimits: Record<PricingPlanId, PlanTechnicalLimits> = {
    "": {
      concurrentUsersLimit: 10,
      packageSizeSoftLimit: 100 * 1024 * 1024,
      uploadStorageSoftLimit: 100 * 1024 * 1024,
    },
    FREE: {
      concurrentUsersLimit: 10,
      packageSizeSoftLimit: 100 * 1024 * 1024,
      uploadStorageSoftLimit: 100 * 1024 * 1024,
    },
    PRO: {
      concurrentUsersLimit: 50,
      packageSizeSoftLimit: 500 * 1024 * 1024,
      uploadStorageSoftLimit: 500 * 1024 * 1024,
    },
    BUSINESS: {
      concurrentUsersLimit: 1000,
      packageSizeSoftLimit: 1000 * 1024 * 1024,
      uploadStorageSoftLimit: 1000 * 1024 * 1024,
    },
    ENTERPRISE: {
      concurrentUsersLimit: 0,
      packageSizeSoftLimit: 0,
      uploadStorageSoftLimit: 0,
    },
    FREE_UGC: {
      concurrentUsersLimit: 0,
      packageSizeSoftLimit: 500 * 1024 * 1024,
      uploadStorageSoftLimit: 50 * 1024 * 1024,
    },
    PLUS_LEGACY: {
      concurrentUsersLimit: 500,
      packageSizeSoftLimit: 500 * 1024 * 1024,
      uploadStorageSoftLimit: 0,
    },
  }
  // Temporary until backend changes are merged on staging/prod
  const plansThatCannotUseProTemplates: PricingPlanId[] = ["", "FREE", "FREE_UGC", "PLUS_LEGACY"]

  const pricingPlanMap = getPricingPlansMap(prices)

  // Check current usage and return the first plan ID that doesn't exceed that plan's limits.
  const nextPlanIndex = sortedPaidPricingPlanIds.findIndex((planId) => planId === currentUsage.nextPlan)
  if (nextPlanIndex > -1) {
    // Start at the nextPlanIndex so that we do not recommend plans that will not upgrade the current plan.
    for (let i = nextPlanIndex; i < sortedPaidPricingPlanIds.length; i++) {
      const id = sortedPaidPricingPlanIds[i]
      const pricingPlan = pricingPlanMap.get(id)
      if (!pricingPlan) continue

      const { uploadStorageSoftLimit, packageSizeSoftLimit, concurrentUsersLimit } = planIdToTechnicalLimits[id]
      // Skip if current upload storage exceeds this plan's limit.
      if (uploadStorageSoftLimit > 0 && currentUsage.uploadStorageCurrent >= uploadStorageSoftLimit) continue
      // Skip if current package storage exceeds this plan's limit.
      if (packageSizeSoftLimit > 0 && currentUsage.packageSizeCurrent >= packageSizeSoftLimit) continue
      // Skip if current concurrent users exceeds this plan's limit
      if (concurrentUsersLimit > 0 && currentUsage.concurrentUsersCount >= concurrentUsersLimit) continue
      // Skip if currently using pro template but plan doesn't support it.
      if (currentUsage.usesProTemplate && currentUsage.plan in plansThatCannotUseProTemplates) continue

      // If we got here, no limits were exceeded. Recommend this plan.
      return id
    }
  }

  // Fall back to the next tier if there's one available.
  if (currentUsage.nextPlan) {
    return currentUsage.nextPlan as PaidPricingPlanId
  }

  // If there's no next tier, then there's no valid plan to upsell to, so just return the highest plan (Enterprise)
  return "ENTERPRISE"
}
