import Config from "@spatialsys/web/config"

export const H5_SCRIPT_URL =
  "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7849013300997404"

export const H5_SCRIPT_PARAMETERS = {
  "data-ad-client": "ca-pub-7849013300997404",
  "data-ad-frequency-hint": "120s",
  crossorigin: "anonymous",
  /**
   * On staging, report ad events to a hardcoded channel to test functionality.
   * The channel is called "staging_ads", link here: https://www.google.com/adsense/new/u/0/pub-7849013300997404/reporting/?ol=custom_channels
   * For docs on tracking events with channels, see: https://developers.google.com/ad-placement/docs/advanced-reporting
   */
  ...(Config.DEPLOYMENT_ENV === "staging" && { "data-ad-channel": "3794316251" }),
  ...(Config.DEPLOYMENT_ENV === "development" && { "data-adbreak-test": "on" }),
}
