import { Slot } from "@radix-ui/react-slot"
import { VariantProps, cva } from "class-variance-authority"
import { forwardRef } from "react"

import { BaseComponentProps } from "../types"
import { cn } from "../utils/cn"

export const textVariants = cva("font-body antialiased", {
  variants: {
    size: {
      "2xs": "text-2xs",
      xs: "text-xs",
      sm: "text-sm",
      // `md` provided as an alias for base
      md: "text-base",
      base: "text-base",
      lg: "text-lg",
    },
    weight: {
      bold: "font-bold",
      normal: "font-normal",
    },
    textAlign: { left: "text-left", center: "text-center" },
  },
  defaultVariants: { size: "md", weight: "normal" },
})

export type TextProps = VariantProps<typeof textVariants> & BaseComponentProps

/**
 * Displays text.
 *
 * @example
 * // Change the style using the `size` and `weight` props.
 * <Text size="sm" weight="black">Lorem Ipsum</Text>
 *
 * // Pass arbitrary classes, Tailwind classes will be merged in the correct order automatically
 * <Text size="lg" className="text-center px-2">Lorem Ipsum</Text>
 *
 * // Pass intrinsic HTML attributes using `asChild`. This creates a more ergonomic API (better prop auto-completion)
 * <Text asChild><span id="foo">Lorem Ipsum</span></Text>
 *
 * @see https://web-docs.spatial.io/components/text
 */
export const Text = forwardRef<HTMLParagraphElement, TextProps>((props, ref) => {
  const { className, size, weight, textAlign, asChild = false, ...rest } = props
  const Comp = asChild ? Slot : "p"
  return <Comp ref={ref} className={cn(textVariants({ size, weight, textAlign, className }))} {...rest} />
})

Text.displayName = "Text"
