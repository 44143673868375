import { AxiosInstance } from "axios"

export function createContentV2Endpoints(client: AxiosInstance) {
  return {
    /**
     * Hides a file from the CMS
     * @param fileId fileId of the file you are trying to hide
     */
    hideFile: async function (fileId: string): Promise<void> {
      const response = await client.patch<void>(`/${fileId}/hide`)
      return response.data
    },
    /**
     * Unhides a file from the CMS
     * @param fileId fileId of the file you are trying to unhide
     */
    unhideFile: async function (fileId: string): Promise<void> {
      const response = await client.patch<void>(`/${fileId}/unhide`)
      return response.data
    },
    /**
     * Deletes a file from the CMS
     * @param fileId fileId of the file you are trying to delete
     */
    deleteFile: async function (fileId: string): Promise<void> {
      const response = await client.delete<void>(`/${fileId}`)
      return response.data
    },
  }
}
