import { getAuth, signInWithCustomToken } from "firebase/auth"
import { call, put, select } from "typed-redux-saga/macro"

import { Actions, AppState, LoginWithEmailPw } from "@spatialsys/web/app-state"
import { sapiClient } from "@spatialsys/web/sapi"

import { getTokenForFirebaseUser } from "../../auth"

/** Attempts to log in with email password by calling SAPI (Firebase) */
export function* loginWithEmailPw({ payload }: LoginWithEmailPw) {
  try {
    const resp = yield* call(sapiClient.auth.emailPw.login, payload)
    const firebaseAuth = yield* call(getAuth)
    const credentials = yield* call(signInWithCustomToken, firebaseAuth, resp.accessToken)
    const authState = yield* call(getTokenForFirebaseUser, credentials.user)

    if (payload.forceRedirect) {
      window.location.reload()
    } else {
      yield* put(Actions.setAuthSuccess(authState))
      const spaceId = yield* select((state: AppState) => state.space.id)
      if (spaceId) {
        yield* put(Actions.signInFromSpace({ authToken: authState.accessToken }))
      }
    }
  } catch (error) {
    yield* put(Actions.setLoginError(error))
  }
}
