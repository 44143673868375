// Keep in sync with `./screens.scss`

export const screens = {
  mobile: "450px",
  xxs: "520px",
  xs: "640px",
  sm: "832px",
  md: "1024px",
  lg: "1216px",
  xl: "1520px",
  "2xl": "1800px",
  "4xl": "2400px",
} as const

export const screensPx = {
  mobile: 450,
  xxs: 520,
  xs: 640,
  sm: 832,
  md: 1024,
  lg: 1216,
  xl: 1520,
  "2xl": 1800,
  "4xl": 2400,
}
