import Router from "next/router"
import { call, fork, join, put } from "typed-redux-saga/macro"

import { SpatialLogger } from "@spatialsys/js/logger"
import { registerVersionInfo } from "@spatialsys/web/analytics"
import { Actions } from "@spatialsys/web/app-state"
import Config from "@spatialsys/web/config"
import { Storage } from "@spatialsys/web/storage"

import { authSaga } from "../auth/sagas/auth-saga"
import { bugReporterSaga } from "./bug-reporter-saga"
import { globalHotkeysSaga } from "./global-hotkeys-saga"
import { networkErrorsSaga } from "./network-errors-saga"
import { permissionSaga } from "./permission-saga"
import { plusMembershipSaga } from "./plus-membership-saga"
import { routerSaga } from "./router-saga"
import { serviceWorkerMessages } from "./service-worker-messages-saga"
import { register as registerServiceWorker } from "./service-worker-registration"
import { versionSaga } from "./version-saga"
import { webSocketSaga } from "./web-socket-saga/web-socket-saga"

/**
 * The root saga for Spatial Web. Any application startup logic or side effects that should run
 * on every page of the application should go here.
 *
 * For logic that's specific to certain pages, components, or just doesn't need to be part of the
 * initial bundle, either use the `runSaga` function from `useAppContext` to start the saga from
 * the component, or dispatch an action when the component mounts with a listener in this saga
 * to asynchronously import and run the relevant saga.
 */
export function* appSaga() {
  if (typeof navigator !== "undefined") {
    SpatialLogger.properties["userAgent"] = navigator.userAgent
  }
  yield* fork(routerSaga, Router)
  yield* fork(authSaga)
  yield* fork(networkErrorsSaga)
  yield* fork(webSocketSaga)

  // Register the service worker immediately to start caching asset requests
  const registrationTask = yield* fork(registerServiceWorker)
  yield* put(Actions.setServiceWorkerRegistrationTask(registrationTask))

  yield* fork(bugReporterSaga)
  yield* fork(function* startPermissionSaga() {
    const registration: ServiceWorkerRegistration | null = yield* join(registrationTask)
    if (!registration) {
      console.warn("Service worker failed to register, not setting up push notifications")
      return
    }
    yield* fork(permissionSaga, registration)

    if (navigator.serviceWorker) {
      yield* call(serviceWorkerMessages, navigator.serviceWorker)
    }
  })
  yield* fork(globalHotkeysSaga)

  const versionState = yield* call(versionSaga, Config, Storage)
  registerVersionInfo(versionState.versionData)

  yield* fork(plusMembershipSaga)
}
