import { NextRouter } from "next/router"
import { eventChannel } from "redux-saga"
import { put } from "typed-redux-saga/macro"

import { takeEveryAndClose } from "@spatialsys/js/redux"
import { registerInitialPath, trackRouteChange } from "@spatialsys/web/analytics"
import { Actions } from "@spatialsys/web/app-state"

type RouteEvent = {
  path: string
  shallow?: boolean
}

/**
 * Tracks route-related analytics and updates the `route` in AppState as the route changes.
 * Tracks initial route and route changes as analytics events, as well as the initial path
 * as a super property.
 */
export function* routerSaga(router: NextRouter) {
  registerInitialPath(router)

  // Track the initial route as an event
  trackRouteChange(router)

  const routeChanges = eventChannel<RouteEvent>((emitter) => {
    const handler = (path: string, { shallow }: { shallow: boolean }) => emitter({ path, shallow })
    router.events.on("routeChangeComplete", handler)
    return () => router.events.off("routeChangeComplete", handler)
  })
  yield* takeEveryAndClose(routeChanges, function* (route) {
    // Track non-shallow/actual changes to the route
    // See https://nextjs.org/docs/api-reference/next/router#routerevents
    if (!route.shallow) {
      trackRouteChange(router)
    }
    yield* put(Actions.setRoute(route))
  })
}
