import { clamp, random } from "lodash"
import { memo, useEffect, useMemo, useState } from "react"

import { useAppContext } from "@spatialsys/web/app-context"
import { Selectors } from "@spatialsys/web/app-state"
import { cn } from "@spatialsys/web/ui"

import classes from "./progress-bar.module.scss"

type ProgressBarProps = {
  classNameBar?: string
  classNameContainer?: string
  progress: number
  shouldShimmer?: boolean
}

export const ProgressBar = memo(function ProgressBar(props: ProgressBarProps) {
  const { classNameBar, classNameContainer, progress } = props
  const isSpaceLoading = useAppContext((context) => Selectors.getIsSpaceLoading(context.state))

  const displayProgress = useMemo(
    () => (isSpaceLoading ? Number(clamp(progress, 0, 1).toFixed(2)) : 1),
    [isSpaceLoading, progress]
  )

  return (
    <div
      className={cn("relative h-2 w-full bg-white/20", classes.container, classNameContainer)}
      style={{ "--progress": clamp(displayProgress, 0, 1) } as React.CSSProperties}
    >
      <div className={cn("absolute inset-0 overflow-hidden rounded", classNameBar)}>
        <div className={cn("absolute inset-0 origin-left bg-white", classes.progressInstant)} />
      </div>
    </div>
  )
})

type ProgressBarIndeterminateProps = {
  classNameBar?: string
  classNameContainer?: string
  isActive?: boolean
  isComplete?: boolean
}

/**
 * Indeterminate progress bar that fills up at a random rate to "fake" progress.
 */
export const ProgressBarIndeterminate = memo(function ProgressBarIndeterminate(props: ProgressBarIndeterminateProps) {
  const { classNameBar, classNameContainer, isActive = false, isComplete = false } = props

  const [progress, setProgress] = useState(0)

  useEffect(() => {
    let interval: NodeJS.Timer

    // If active and not complete, we increment the progress by a random amount on a random interval
    if (isActive && !isComplete && progress <= 1) {
      const intervalLength = random(200, 700)
      const incrementAmount = random(0.01, 0.05)
      interval = setInterval(() => {
        setProgress((prev) => clamp(prev + incrementAmount, 0, 1))
      }, intervalLength)
    }

    // Otherwise, do nothing. i.e. if not active, the progress will be kept at 0.

    return () => clearInterval(interval)
  }, [isActive, isComplete, progress])

  const displayProgress = useMemo(() => (isComplete ? 1 : progress), [isComplete, progress])

  return (
    <div
      className={cn("relative h-2 w-full rounded bg-white/20", classes.container, classNameContainer)}
      style={{ "--progress": displayProgress } as React.CSSProperties}
    >
      <div className={cn("absolute inset-0 overflow-hidden rounded", classNameBar)}>
        <div className={cn("absolute inset-0 origin-left bg-white", classes.progressInstant)} />
      </div>
    </div>
  )
})
