import { last } from "lodash"
import { createCachedSelector } from "re-reselect"
import { createSelector } from "reselect"

import {
  IntegrationConnection,
  IntegrationsResponse,
  checkIntegrationAccountStatus,
} from "@spatialsys/js/sapi/sapi/integrations"
import { UserData } from "@spatialsys/js/sapi/sapi/users"
import { PUNCH_HERO_SPACE_ID, SHOOTY_SHOOTY_SPACE_ID } from "@spatialsys/js/util/constants"
import { formatAvatarModelAndThumbnailUrl } from "@spatialsys/js/util/format-avatar-model-and-thumbnail-url"
import { AppStateSelectors, SpatialCameraRotationMode } from "@spatialsys/unity/app-state"
import Config, { ConfigT } from "@spatialsys/web/config"

import { AppState } from "./app-state"
import { NoAuthError } from "./auth/errors"
import { getAuthState } from "./auth/selectors"
import { gamePortalEmbedSources } from "./canvas"
import { Modals } from "./modals"
import { getOpenSpaceModal } from "./space/space-selectors"

const getIntegrations = (state: AppState) => state.integrations
const takeIntegrationName = (_: AppState, integration: IntegrationConnection) => integration
const findIntegrationStatus = (integrations: IntegrationsResponse | null, integration: IntegrationConnection) => {
  if (!integrations) {
    return false
  }
  return checkIntegrationAccountStatus(integration, integrations) === "CONNECTED"
}

export const getMediaSettings = (state: AppState) => state.mediaSettings

export const isIntegrationConnected = createCachedSelector(
  getIntegrations,
  takeIntegrationName,
  findIntegrationStatus
)((_, integration) => integration)

export const getSelectedPixelRatio = (state: AppState) => state.mediaSettings?.selectedPixelRatio
export const getOpenModal = createSelector(
  (state: AppState) => state.modals,
  (modals) => last(modals)
)
export const getLoginModal = (state: AppState) => {
  const openModal = last(state.modals)
  return openModal?.type === Modals.Login ? openModal : undefined
}

export const getUnfriendModal = (state: AppState) => {
  const openModal = last(state.modals)
  return openModal?.type === Modals.UnfriendModal ? openModal : undefined
}
export const getFriendsListModal = (state: AppState) => {
  const openModal = last(state.modals)
  return openModal?.type === Modals.FriendsListModal ? openModal : undefined
}

export const getConfirmBuyItemsModal = (state: AppState) => {
  const openModal = last(state.modals)
  return openModal?.type === Modals.ConfirmBuyItemModal ? openModal : undefined
}

export const getUpsellBuyItemsModal = (state: AppState) => {
  const openModal = last(state.modals)
  return openModal?.type === Modals.UpsellBuyItemModal ? openModal : undefined
}

export const getStripePaymentModal = (state: AppState) => {
  const openModal = last(state.modals)
  return openModal?.type === Modals.StripePaymentModal ? openModal : undefined
}

export const getReportSpaceModal = (state: AppState) => {
  const openModal = last(state.modals)
  return openModal?.type === Modals.ReportSpace ? openModal : undefined
}

export const getSpaceSubUpgradeModal = (state: AppState) => {
  const openModal = last(state.modals)
  return openModal?.type === Modals.SpaceSubUpgrade ? openModal : undefined
}
export const getSelectSpaceToUpgradeModal = (state: AppState) => {
  const openModal = last(state.modals)
  return openModal?.type === Modals.SelectSpaceToUpgrade ? openModal : undefined
}

export const getPlusCantUpgradeToSpaceSubModal = (state: AppState) => {
  const openModal = last(state.modals)
  return openModal?.type === Modals.PlusCantUpgradeToSpaceSub ? openModal : undefined
}

export const getSpaceSubCheckoutModal = (state: AppState) => {
  const openModal = last(state.modals)
  return openModal?.type === Modals.SpaceSubCheckout ? openModal : undefined
}

export const getMetaMaskSetupModal = (state: AppState) => {
  const openModal = last(state.modals)
  return openModal?.type === Modals.MetaMaskSetupModal ? openModal : undefined
}

export const canViewDebugSettings = (state: AppState, config: ConfigT) => {
  return (
    config.DEPLOYMENT_ENV === "development" ||
    config.DEPLOYMENT_ENV === "staging" ||
    state.featureFlags?.webShowDebugMenu
  )
}

export const getAuthlessUserData = createSelector(
  (state: AppState) => state.authlessUserData,
  (authlessUserData) =>
    authlessUserData && {
      ...authlessUserData,
      avatarUrls: formatAvatarModelAndThumbnailUrl({
        avatarBaseUrl: Config.AUTHLESS_AVATAR_BASE_URL,
        avatarPath: authlessUserData.avatar,
        rpmBaseUrl: Config.RPM_MODELS_BASE_URL,
        rpmId: authlessUserData?.rpmId,
      }),
    }
)

/**
 * Determines if we should enter pointer lock on clicking the canvas.
 */
export const shouldEnterPointerLock = (state: AppState) => {
  return (
    state.unity.appState.isMouseInUnity &&
    !state.unity.appState.isMouseOverUnityUI &&
    state.openModalCount === 0 &&
    state.unity.appState.roomSession.camera.rotationMode === SpatialCameraRotationMode.PointerLock_Unlocked
  )
}

/**
 * Returns `true` if Spatial is currently running in an embedded context.
 * We consider this to be true if:
 * - the webapp is embedded
 * - the path is `/embed`
 */
export const getIsEmbedded = (state: AppState) => {
  return state.isInIframe || state.space.pathPrefix === "/embed"
}

export const getIsEmbeddedOnPartner = (state: AppState) => {
  const embedSource = state.canvas.embedSource
  return getIsEmbedded(state) && !!embedSource && gamePortalEmbedSources.includes(embedSource)
}

export const getIsEmbeddedOnNonPartner = (state: AppState) => {
  const embedSource = state.canvas.embedSource
  return getIsEmbedded(state) && (embedSource === null || !gamePortalEmbedSources.includes(embedSource))
}

/**
 * We disable auth features on select embedded partners.
 */
export const getShouldDisableAuthFeatures = (state: AppState) => {
  const embedSource = state.canvas.embedSource
  if (getIsEmbedded(state)) {
    if (embedSource === "coolmath") {
      return true
    }

    if (embedSource === "crazygames") {
      const spaceId = state.space.id
      // Disable auth features on crazygames except for Punch Hero and Shooty Shooty,
      // which were released prior to these requirements.
      if (spaceId !== PUNCH_HERO_SPACE_ID && spaceId !== SHOOTY_SHOOTY_SPACE_ID) {
        return true
      }
    }
  }

  return false
}

export const getIsRtcDisabled = (state: AppState) => {
  return getIsEmbedded(state) && !state.canvas.enableRtcInEmbed
}

export const getCurrentRtcStream = createSelector(
  (state: AppState) => state.space.rtcState,
  (rtcState) => {
    if (!rtcState.currentStreamId) {
      return null
    }
    return rtcState.localMedias[rtcState.currentStreamId] ?? rtcState.remoteMedias[rtcState.currentStreamId]
  }
)

export const getIsLightboxOpen = createSelector(
  (state: AppState) => state,
  getCurrentRtcStream,
  (state, rtcStream) =>
    getOpenSpaceModal(state)?.type === "LivestreamLightbox" ||
    Boolean(rtcStream) ||
    state.unity.appState.roomSession.lightBoxTargetObjectID !== 0
)

/**
 * Returns if the space is loading
 */
export const getIsSpaceLoading = createSelector(
  (state: AppState) => state,
  (state) => {
    return (
      !state.unity.appStateLoaded ||
      state.unity.appState.roomSession?.shouldShowLoadingScreen ||
      state.showIframeBlockingModal ||
      state.debugSettings.showLoadingSplash
    )
  }
)

/**
 * Returns if the loading screen should be shown. Similar to {@link getIsSpaceLoading}, but also considers
 * `isReadyToJoin` state if the `requiresPlayClick` feature flag is enabled.
 */
export const getShowLoadingScreen = createSelector(
  (state: AppState) => state.canvas.isReadyToJoin,
  (state: AppState) => state.featureFlags?.requiresPlayClick,
  getIsSpaceLoading,
  (isReadyToJoin, requiresPlayClick, isSpaceLoading) => (requiresPlayClick && !isReadyToJoin) || isSpaceLoading
)

/**
 * Returns if the space is ready to be rendered. This only considers the "readiness" of the space itself — it does not
 * take into account if the user has clicked the "play" button or not.
 */
export const getCanRenderSpace = createSelector(
  (state: AppState) => state,
  getIsSpaceLoading,
  (_: AppState, userData: UserData | undefined) => userData,
  (state, isSpaceLoading, userData) => {
    if (isSpaceLoading) {
      return false
    }
    const unitySpaceId = state.unity.appState.roomSession.roomID
    return Boolean(
      // Don't mount Room if RTC client isn't prepared, room content isn't loaded, or /me query hasn't succeeded.
      state.rtc.localClient &&
        state.rtc.mediaCapture &&
        // Either fully synced or the initial data load has completed.
        (AppStateSelectors.getRoomFullySynced(state.unity.appState) ||
          state.unity.appState.roomSession?.initialDataLoadComplete) &&
        userData &&
        unitySpaceId &&
        // If the space ID in JS AppState and the space ID in Unity AppState are mismatched, unmount Room.
        // This happens when the user navigates to another space through a portal or browser navigation.
        // Unmounting Room runs its effect cleanups, which disconnects from RTC and sending a message to the
        // Unity client to leave the space.
        unitySpaceId === state.space.id &&
        !state.showIframeBlockingModal
    )
  }
)

/**
 * Returns if the space UI is ready to be rendered.
 * Similar to {@link getCanRenderSpace}, but uses {@link getIsSpaceLoading} instead of {@link getShowLoadingScreen}.
 */
export const getCanRenderSpaceUi = createSelector(
  (state: AppState) => state,
  getShowLoadingScreen,
  (_: AppState, userData: UserData | undefined) => userData,
  (state, showLoadingScreen, userData) => {
    if (showLoadingScreen) {
      return false
    }
    const unitySpaceId = state.unity.appState.roomSession.roomID
    return Boolean(
      // Don't mount Room if RTC client isn't prepared, room content isn't loaded, or /me query hasn't succeeded.
      state.rtc.localClient &&
        state.rtc.mediaCapture &&
        // Either fully synced or the initial data load has completed.
        (AppStateSelectors.getRoomFullySynced(state.unity.appState) ||
          state.unity.appState.roomSession?.initialDataLoadComplete) &&
        userData &&
        unitySpaceId &&
        // If the space ID in JS AppState and the space ID in Unity AppState are mismatched, unmount Room.
        // This happens when the user navigates to another space through a portal or browser navigation.
        // Unmounting Room runs its effect cleanups, which disconnects from RTC and sending a message to the
        // Unity client to leave the space.
        unitySpaceId === state.space.id &&
        !state.showIframeBlockingModal
    )
  }
)

export const getSpaceAuthState = createSelector(
  getAuthState,
  (state: AppState) => state.space.id,
  (state: AppState) => state.space.shareId,
  (state: AppState) => state.featureFlags?.noAuthUserJoinSpaceWithoutShareId,
  (
    { isAuthenticated, isSigningInFromSpace, isAuthenticating, authenticationError },
    spaceId,
    shareId,
    noAuthUserJoinSpaceWithoutShareId
  ) => {
    const authErrorWithoutShareId = authenticationError instanceof NoAuthError && authenticationError.roomId === spaceId

    const authErrorWithShareId =
      authenticationError instanceof NoAuthError &&
      authenticationError.shareId === shareId &&
      authenticationError.roomId === spaceId

    const hasFailedAuthForCurrentSpace = noAuthUserJoinSpaceWithoutShareId
      ? authErrorWithoutShareId
      : authErrorWithShareId

    const isLoadingAuthForSpace =
      isSigningInFromSpace || isAuthenticating || (!hasFailedAuthForCurrentSpace && !isAuthenticated)
    const showAuthFallbackForSpace =
      hasFailedAuthForCurrentSpace ||
      // Only check this case when shareId is required for authless users
      (noAuthUserJoinSpaceWithoutShareId === false && !shareId && !isAuthenticating && !isAuthenticated)

    return {
      hasFailedAuthForCurrentSpace,
      isLoadingAuthForSpace,
      /**
       * Show login splash if:
       * - No-auth was attempted and failed (by definition, a share ID exists for this to happen)
       * - No share ID exists (space is private), and the user is not authenticated nor authenticating
       */
      showAuthFallbackForSpace,
      shouldRenderMainContent: !isLoadingAuthForSpace && !showAuthFallbackForSpace,
    }
  }
)
