import { produce } from "immer"

import { ActionT, GetActionType, makeActionCreator } from "@spatialsys/js/redux"

export type CrazyGamesState = {
  isDisplayingBannerAd: boolean
  isSdkLoaded: boolean
}

export const initialCrazyGamesState: CrazyGamesState = {
  isDisplayingBannerAd: false,
  isSdkLoaded: false,
}

export enum CrazyGamesActionType {
  SetCrazyGamesSdkLoaded = "SetCrazyGamesSdkLoaded",
  SetIsDisplayingCrazyGamesBannerAd = "SetIsDisplayingCrazyGamesBannerAd",
}

export type SetIsDisplayingCrazyGamesBannerAd = ActionT<CrazyGamesActionType.SetIsDisplayingCrazyGamesBannerAd, boolean>
export type SetCrazyGamesSdkLoaded = ActionT<CrazyGamesActionType.SetCrazyGamesSdkLoaded>

export const CrazyGamesActions = {
  setIsDisplayingCrazyGamesBannerAd: makeActionCreator<SetIsDisplayingCrazyGamesBannerAd>(
    CrazyGamesActionType.SetIsDisplayingCrazyGamesBannerAd
  ),
  setCrazyGamesSdkLoaded: makeActionCreator<SetCrazyGamesSdkLoaded>(CrazyGamesActionType.SetCrazyGamesSdkLoaded),
}

export type CrazyGamesAction = GetActionType<typeof CrazyGamesActions>

export const crazyGamesReducer = (state: CrazyGamesState, action: CrazyGamesAction): CrazyGamesState => {
  switch (action.type) {
    case CrazyGamesActionType.SetIsDisplayingCrazyGamesBannerAd:
      return produce(state, (draft) => void (draft.isDisplayingBannerAd = action.payload))
    case CrazyGamesActionType.SetCrazyGamesSdkLoaded:
      return produce(state, (draft) => void (draft.isSdkLoaded = true))
    default:
      return state
  }
}
