import { google } from "@alugha/ima"
import { produce } from "immer"

import { ActionT, GetActionType, makeActionCreator } from "@spatialsys/js/redux"
import { SpatialAdType } from "@spatialsys/unity/app-state"

declare global {
  interface Window {
    google: typeof google
  }
}

export type CurrentAd = {
  adType: SpatialAdType
  callbackId: number
}

export type ImaState = {
  adContainer: HTMLDivElement | null
  adElement: HTMLVideoElement | null
  adsLoader: google.ima.AdsLoader | null
  currentAd: CurrentAd | null
  sdkStatus: "failed" | "loaded" | "unintialized"
}

export const initialImaState: ImaState = {
  adsLoader: null,
  sdkStatus: "unintialized",
  adContainer: null,
  adElement: null,
  currentAd: null,
}

export enum ImaActionType {
  SetCurrentAd = "SetCurrentAd",
  SetImaAdContainer = "SetImaAdContainer",
  SetImaAdElement = "SetImaAdElement",
  SetImaLoader = "SetImaLoader",
  SetImaSdkStatus = "SetImaSdkStatus",
}

export type SetImaLoader = ActionT<ImaActionType.SetImaLoader, google.ima.AdsLoader>
export type SetImaSdkStatus = ActionT<ImaActionType.SetImaSdkStatus, ImaState["sdkStatus"]>
export type SetImaAdContainer = ActionT<ImaActionType.SetImaAdContainer, ImaState["adContainer"]>
export type SetImaAdElement = ActionT<ImaActionType.SetImaAdElement, ImaState["adElement"]>
export type SetCurrentAd = ActionT<ImaActionType.SetCurrentAd, ImaState["currentAd"]>

export const ImaActions = {
  setImaLoader: makeActionCreator<SetImaLoader>(ImaActionType.SetImaLoader),
  setImaSdkStatus: makeActionCreator<SetImaSdkStatus>(ImaActionType.SetImaSdkStatus),
  setImaAdContainer: makeActionCreator<SetImaAdContainer>(ImaActionType.SetImaAdContainer),
  setImaAdElement: makeActionCreator<SetImaAdElement>(ImaActionType.SetImaAdElement),
  setCurrentAd: makeActionCreator<SetCurrentAd>(ImaActionType.SetCurrentAd),
}

export type ImaAction = GetActionType<typeof ImaActions>

export const imaReducer = (state: ImaState, action: ImaAction): ImaState => {
  switch (action.type) {
    case ImaActionType.SetImaLoader:
      return produce(state, (draft) => void (draft.adsLoader = action.payload))
    case ImaActionType.SetImaSdkStatus:
      return produce(state, (draft) => void (draft.sdkStatus = action.payload))
    case ImaActionType.SetImaAdContainer:
      return { ...state, adContainer: action.payload }
    case ImaActionType.SetImaAdElement:
      return { ...state, adElement: action.payload }
    case ImaActionType.SetCurrentAd:
      return produce(state, (draft) => void (draft.currentAd = action.payload))
    default:
      return state
  }
}
