import { produce } from "immer"

import { ActionT, GetActionType, makeActionCreator } from "@spatialsys/js/redux"

type ApiStatus = "failed" | "initialized" | "uninitialized"

export type H5State = {
  apiStatus: ApiStatus
}

export const initialH5State: H5State = {
  apiStatus: "uninitialized",
}

export enum H5ActionType {
  SetH5ApiStatus = "SetH5ApiStatus",
}

export type SetH5ApiStatus = ActionT<H5ActionType.SetH5ApiStatus, ApiStatus>

export const H5Actions = {
  setH5ApiStatus: makeActionCreator<SetH5ApiStatus>(H5ActionType.SetH5ApiStatus),
}

export type H5Action = GetActionType<typeof H5Actions>

export const h5Reducer = (state: H5State, action: H5Action): H5State => {
  switch (action.type) {
    case H5ActionType.SetH5ApiStatus:
      return produce(state, (draft) => void (draft.apiStatus = action.payload))
    default:
      return state
  }
}
