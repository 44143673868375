import { useAppContext } from "@spatialsys/web/app-context"

import { SpaceContainerOutPortal } from "./space-container-portals"

/**
 * Ths component should not be necessary after Unity fixes its bug.
 * https://forum.unity.com/threads/getboundingclientrect-problem.1302099/#post-9075118
 * It should be fixed as of 2021.3.26f1.
 */
export function ClosedPlayer() {
  const mode = useAppContext((context) => context.state.canvas.mode)

  if (mode !== "closed") {
    return null
  }

  return (
    <div className="hidden">
      <SpaceContainerOutPortal />
    </div>
  )
}
