import { SocialProfile, SocialProfileV2 } from "../types"

export function profileV1ToV2(v1Profile: SocialProfile, prev?: SocialProfileV2): Partial<SocialProfileV2> {
  return {
    about: v1Profile.about || prev?.about || "",
    avatarImageURL: v1Profile.avatarImageURL || prev?.avatarImageURL,
    bannerURL: v1Profile.bannerURL || prev?.bannerURL || "",
    displayName: v1Profile.displayName || prev?.displayName,
    isPrivate: v1Profile.isPrivate || prev?.isPrivate || false,
    numFollowers: v1Profile.numFollowers || prev?.numFollowers,
    numFollowing: v1Profile.numFollowing || prev?.numFollowing,
    profileBackgroundColor: v1Profile.appearanceCustomization?.profileColor || prev?.profileBackgroundColor,
    userID: v1Profile.userID || prev?.userID,
    username: v1Profile.username || prev?.username,
  }
}
