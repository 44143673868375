import { put, takeLatest } from "typed-redux-saga/macro"

import { Text } from "@spatialsys/web/ui"

import "@spatialsys/web/ui"

import dynamic from "next/dynamic"

import { UserData, getSubscriptionFromUser } from "@spatialsys/js/sapi/sapi/users"
import { waitUntilChanged } from "@spatialsys/use-saga"
import { Actions, AppActionType, AppState } from "@spatialsys/web/app-state"
import { Storage } from "@spatialsys/web/storage"
import { toast } from "@spatialsys/web/ui"

// set 5 minutes for the toast to expire in local storage so user refetch stops after 5 minutes
// SAPI should push the updated user subscription status immediately after the user subscribes
const REFETCH_MAX_AGE = 5 * 60 * 1000

const ConfettiAnimation = dynamic(() =>
  // eslint-disable-next-line @nx/enforce-module-boundaries
  import(
    /* webpackChunkName: "confetti-animation" */ "@spatialsys/web/core/js/components/confetti-animation/confetti-animation"
  ).then((mod) => mod.ConfettiAnimation)
)

export function* plusMembershipSaga() {
  yield* takeLatest(AppActionType.UpdatePlusMembership, updatePlusMembership)
  while (true) {
    const [user] = yield* waitUntilChanged((state: AppState) => state.user)
    handlePlusMembershipChange(user)
  }
}

/**
 * Set a flag in local storage while updating membership status to
 * 1. Refetch user data in an interval
 * 2. Determine if the user membership status still should be updated when the user refreshes the page or the app is reloaded
 */
export function* updatePlusMembership() {
  yield* put(Actions.setSpaceState({ isSettingsDrawerOpen: false }))
  Storage.setItem(
    Storage.UPDATING_PLUS_MEMBERSHIP,
    JSON.stringify({ value: "true", expiresAt: (Date.now() + REFETCH_MAX_AGE).toString() })
  )
}

/**
 * Shows a welcome toast when user membership status is updated to Spatial Plus
 */
export function welcomePlusMembership() {
  toast(
    <div className="flex flex-col items-center">
      <Text size="sm" className="font-demibold">
        Welcome to Spatial Plus!
      </Text>
      <Text size="sm" className="text-gray-400">
        Enjoy your unlimited perks
      </Text>
      <ConfettiAnimation className="-top-48 w-full" />
    </div>,
    {
      duration: Infinity,
    }
  )
  // Remove the flag so the toast doesn't show again
  Storage.removeItem(Storage.UPDATING_PLUS_MEMBERSHIP)
}

/**
 * Handles user's plus membership subscription status change
 */
export function handlePlusMembershipChange(user: UserData | undefined) {
  try {
    const isPlusUser = user && Boolean(getSubscriptionFromUser(user))
    const isUpdatingPlusMembership =
      Storage.fetch(Storage.UPDATING_PLUS_MEMBERSHIP) &&
      JSON.parse(Storage.fetch(Storage.UPDATING_PLUS_MEMBERSHIP, "") as string).value === "true"
    if (user && isUpdatingPlusMembership) {
      if (isPlusUser) {
        welcomePlusMembership()
      } else {
        updatePlusMembership()
      }
    }
  } catch (error) {
    Storage.removeItem(Storage.UPDATING_PLUS_MEMBERSHIP)
  }
}
