import { useRouter } from "next/router"
import { useRef } from "react"
import { CSSTransition } from "react-transition-group"

import { ReactComponent as FullScreenIcon } from "@spatialsys/assets/icons/lucide/maximize-2.svg"
import { ReactComponent as CloseIcon } from "@spatialsys/assets/img/svg/close.svg"
import { useGetSpacePreviewQuery } from "@spatialsys/react/query-hooks/spaces"
import { useAppContext } from "@spatialsys/web/app-context"
import { Selectors } from "@spatialsys/web/app-state"
import { Tooltip } from "@spatialsys/web/ui"

import { ProgressBar } from "./components/loading-splash/progress-bar/progress-bar"
import { SpaceContainerOutPortal } from "./space-container-portals"

import classes from "./mini-player.module.scss"

const ANIMATION_LENGTH = parseInt(classes.loadingAnimationDuration, 10)

export function MiniPlayer() {
  const ref = useRef<HTMLDivElement>(null)
  const router = useRouter()
  const actions = useAppContext((context) => context.actions)
  const spaceId = useAppContext((context) => context.state.space.id)
  const shareId = useAppContext((context) => context.state.space.shareId)
  const spacePreviewQuery = useGetSpacePreviewQuery(spaceId!, {
    retry: false,
    enabled: Boolean(spaceId),
  })
  const spaceName = spacePreviewQuery.data?.space?.name

  const close = () => {
    ref.current!.animate({ opacity: 0 }, { duration: 250, fill: "forwards" }).onfinish = () => {
      actions.setCanvasState({ mode: "closed" })
      actions.clearSpaceAndInstanceId()
    }
  }

  const goFullscreen = () => {
    void router.push({
      pathname: `/s/${spaceId}`,
      query: { share: shareId },
    })
  }

  return (
    <div ref={ref} className={`${classes.container} fixed z-10 overflow-hidden rounded-lg bg-muted shadow-md`}>
      <div className="pointer-events-none absolute z-[1] flex w-full flex-row items-start justify-between bg-gradient-to-b from-almost-black/50 to-transparent">
        <Tooltip content="Expand">
          <button
            onClick={goFullscreen}
            className="pointer-events-auto box-content flex aspect-square w-7 items-center justify-center p-2"
          >
            <FullScreenIcon className="rotate-90 stroke-white" />
          </button>
        </Tooltip>
        <Tooltip content="Leave">
          <button
            onClick={close}
            className="pointer-events-auto mr-[-2px] box-content flex aspect-square w-7 items-center justify-center p-2 text-white"
          >
            <CloseIcon />
          </button>
        </Tooltip>
      </div>
      <div className="pointer-events-none absolute bottom-0 z-[1] flex w-full flex-row items-start justify-start bg-gradient-to-b from-transparent to-almost-black/50 px-2 py-2 font-body text-sm font-bold text-white">
        {spaceName}
      </div>
      <SpaceContainerOutPortal canvasClassName="rounded-lg" />
      <Loading />
    </div>
  )
}

function Loading() {
  const ref = useRef<HTMLDivElement>(null)
  const isShowing = useAppContext((context) => Selectors.getShowLoadingScreen(context.state))

  return (
    <CSSTransition
      nodeRef={ref}
      mountOnEnter
      unmountOnExit
      classNames={{ exitActive: classes.exiting }}
      in={isShowing}
      timeout={ANIMATION_LENGTH}
    >
      <div
        ref={ref}
        className={`${classes.loading} absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center bg-muted px-12`}
      >
        {/* FIXME: we hardcode progress = 1, this component is not used */}
        <ProgressBar progress={1} />
      </div>
    </CSSTransition>
  )
}
