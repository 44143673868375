import { createTrackAdEvent } from "@spatialsys/react/analytics"
import { SpatialAdType } from "@spatialsys/unity/app-state"
import { track } from "@spatialsys/web/analytics"

import { PlacementInfoStatus } from "./types"

export const trackH5AdEvent = createTrackAdEvent(track, "H5")

/**
 * A helper function to help fire the correct track event based on H5's `PlacementInfo.breakStatus` once an ad FINISHES.
 * We report the following ad statuses to Mixpanel: `AdStatus = "complete" | "empty" | "failed" | "requested" | "start" | "tooSoon"` as such:
 * - `complete`: when `PlacementInfo.breakStatus` is `viewed`, i.e ad was completed and not skipped.
 * - `empty`: when `PlacementInfo.breakStatus` is `noAdPreloaded`, i.e no ad was loaded.
 * - `failed`: when `PlacementInfo.breakStatus` is `notReady`, `timeout`, `ignored`, `invalid`,
 *           `other`, `error` or `dismissed`. We treat ads skipped as `failed`.
 * - `requested`: not reported here.
 * - `start`: not reported here.
 * - `tooSoon`: when `PlacementInfo.breakStatus` is `frequencyCapped`. H5 handles ad limits internally.
 * @see https://developers.google.com/ad-placement/apis/adbreak#adbreakdone_and_placementinfo
 */
export function trackH5AdFinishedEvent(adStatus: PlacementInfoStatus, adType: SpatialAdType) {
  switch (adStatus) {
    case "viewed":
      trackH5AdEvent({ adStatus: "complete", adType: adType })
      break
    case "noAdPreloaded":
      trackH5AdEvent({ adStatus: "empty", adType: adType, "Error Reason": "No ad preloaded" })
      break
    case "frequencyCapped":
      trackH5AdEvent({ adStatus: "tooSoon", adType: adType, "Error Reason": "Ad requested too soon" })
      break
    /**
     * Remaining possible `PlacementInfo.breakStatus`: `notReady`, `timeout`, `invalid`, `ignored`, `other`, `error`, `dismissed`.
     * These should be reported as `failed`.
     */
    default:
      trackH5AdEvent({
        adStatus: "failed",
        adType: adType,
        "Error Reason": `Ad failed with status: ${adStatus}`,
      })
  }
}
