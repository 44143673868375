import { MotionValue } from "framer-motion"
import { Draft, produce, setAutoFreeze } from "immer"

import { ActionT, GetActionType, makeActionCreator } from "@spatialsys/js/redux"
import { ParticipantProfile } from "@spatialsys/js/sapi/sapi/rooms"
import { SpaceTemplate } from "@spatialsys/js/sapi/types"
import { PlayerColors } from "@spatialsys/js/util/player-colors"
import { SocialProfileState, SpatialCameraRotationMode, VREnvironment } from "@spatialsys/unity/app-state"
import { ContentObject } from "@spatialsys/unity/bridge"
import type { RoomRTCManager } from "@spatialsys/web/rtc/room-rtc-manager"
import { RoomRTCMediaState, RtcState, RtcStateUpdate, getInitialRoomRtcState } from "@spatialsys/web/rtc/rtc-state"

import { ChatAction, ChatActionType, ChatActions, ChatState, chatReducer, initialChatState } from "./chat"
import {
  CrazyGamesAction,
  CrazyGamesActionType,
  CrazyGamesActions,
  CrazyGamesState,
  crazyGamesReducer,
  initialCrazyGamesState,
} from "./crazy-games"
import {
  GamepixAction,
  GamepixActionType,
  GamepixActions,
  GamepixState,
  gamepixReducer,
  initialGamepixState,
} from "./gamepix"
import {
  SpaceJoinContextAction,
  SpaceJoinContextActionType,
  SpaceJoinContextActions,
  SpaceJoinContextState,
  spaceJoinContextReducer,
} from "./join-context"
import {
  SpaceModalsAction,
  SpaceModalsActionType,
  SpaceModalsActions,
  SpaceModalsState,
  initialSpaceModalsState,
  spaceModalsReducer,
} from "./modals"
import { QuestsAction, QuestsActionType, QuestsActions, QuestsState, initialQuestsState, questsReducer } from "./quests"
import { RewardsActionType, RewardsActions } from "./rewards"

/**
 * Because in the {@link spaceReducer} we combine multiple state slices with mutation after a pass
 * through an initial reducer that uses `produce`, we need to disable auto-freezing, otherwise the
 * mutations thrown an error.
 */
setAutoFreeze(false)

export const enum AddContentHyperlinkState {
  Closed,
  Active,
}

export const enum ComposerType {
  Note = "Note",
  SearchOrURL = "SearchOrURL",
}

export enum TransformPanelState {
  Closed = 0,
  Active = 2,
}

// playerColor will be deprecated: see DEV-5541
// appearanceCustomization.profileColor determines playerColors
export type ParticipantData = Omit<ParticipantProfile, "appearanceCustomization" | "clientPlatform" | "playerColor"> & {
  active: boolean
  clientPlatforms: Set<string>
  isAuthless: boolean
  isMuted: boolean
  isTalking: boolean
  playerColors: PlayerColors
  roomActorNumbers: Set<number>
  socialProfile: SocialProfileState | null
}

/**
 * A participant that's used internally in rendering the participants list
 * It joins `ParticipantData` with the RTC object associated with that participant, if available
 */
export type ParticipantWithRTC = ParticipantData & {
  isLocalUser: boolean
  media: RoomRTCMediaState | null
}

export interface EnvironmentOption {
  artist?: string
  // TODO: (DEV-16902) cleanup/remove this interface to remove redundancy
  environment: VREnvironment
  id?: string
  images: string[]
  metadata?: SpaceTemplate
  name: string
  variantOrders?: number[]
}

export type BannerStatus = "dismissed" | "initial" | "open"

type SignUpBannerState = {
  state: BannerStatus
  variant: "item" | "quest" | "time"
}

export const enum HotkeyActionPopoverType {
  CameraRotationMode = "CameraRotationMode",
  Emotes = "Emotes",
  FilmingTools = "FilmingTools",
}

export enum ObjectType {
  SessionDocumentView = "SessionDocumentView",
  SessionGalleryEmptyFrameView = "SessionGalleryEmptyFrameView",
  SessionImageView = "SessionImageView",
  SessionModelView = "SessionModelView",
  SessionVideoPlayer = "SessionVideoPlayer",
}

export type ObjectMetadata = {
  deleteEnabled: boolean
  fileId: string
  id: number
  isFramed: boolean
  isLocked: boolean
  name: string
  type: ObjectType
}

export type UploadedAssets = {
  instanceGroups: [ObjectMetadata, ...ObjectMetadata[]][]
  singularInstanceGroup: [ObjectMetadata, ...ObjectMetadata[]][]
}

export type SpaceState = {
  autoLayoutFiles: ContentObject[]
  chat: ChatState
  composerType: ComposerType | null
  controlsModalOpen: boolean
  crazyGames: CrazyGamesState
  environmentToChangeTo: EnvironmentOption | undefined
  environmentVariantToChangeTo: number
  gamepix: GamepixState
  /**
   * Show space info modal once if tags or description is empty before publishing or going live.
   */
  hasShownDeetsBeforePublish: boolean
  hasShownTokenGateWelcomeModal: boolean
  hotkeyActionPopoverType: HotkeyActionPopoverType | null
  hyperlinkPanelState: AddContentHyperlinkState
  id: string | null
  instanceId?: string | null
  is2dUiVisible: boolean
  isCreateAvatarFlow: { isOnboarding: boolean } | null
  isCreateCustomEnvModalOpen: boolean
  isCustomEnvModalVisible: boolean
  isHostToolsOpen: boolean
  isNewSpace: boolean
  isObjectInspectorVisible: boolean
  isOwnerPlusMember: boolean
  isReplaceCustomEnvModalInUploadContext: boolean
  isReplaceCustomEnvModalOpen: boolean
  isRevertSpaceModalOpen: boolean
  isScreenRecording: boolean
  isSettingsDrawerOpen: boolean
  isShareModalOpen: boolean
  isSpaceInfoModalOpen: boolean
  isSpaceMenuOpen: boolean
  isSpacePickerVisible: boolean
  joinContext: SpaceJoinContextState
  livestreamMicUsageBannerStatus: BannerStatus
  /**
   * A [MotionValue](https://www.framer.com/motion/motionvalue/) to avoid re-renders since this
   * state changes at such a high frequency.
   **/
  micPeakAmplitude: MotionValue<number>
  /**
   * Modal state in-space. Like global modals, but scoped to a space.
   * This is cleared on leaving a space. Only one modal can be open at a time, modeled as a stack.
   */
  modals: SpaceModalsState
  newCustomEnvironmentFile: ContentObject[]
  pathPrefix: string
  quests: QuestsState
  rtcManager: RoomRTCManager | null
  rtcState: RtcState
  selectedUserProfileId: string | null
  shareId: string | null
  shouldShowContentMenu: boolean
  shouldShowLayersPanel: boolean
  shouldShowPropertiesPanel: boolean
  showCreateHyperlinkPortal: boolean
  showCreatePortalModal: boolean
  showEditCustomEnvironmentBanner: boolean
  showEndGoLiveModal: boolean
  showEnvironmentPickerModal: boolean
  showEnvironmentSettingsModal: boolean
  showFirstTutorial: boolean
  showFullscreenModeNewFeatureBadge: boolean
  showGoLiveModal: boolean
  showManageHostsModal: boolean
  showTokenGateAccessModal: boolean
  showUserProfileEditorModal: boolean
  signUpBanner: SignUpBannerState
  spaceInfoModalCopy: { dismissCopy?: string; title?: string }
  spaceInfoModalOnClose: (() => void) | undefined
  supportsUserMedia: boolean
  transformPanelState: TransformPanelState
  uiMode: UiModes
  uploadedAssets: UploadedAssets | null
  wasParticipantsMenuOpened: boolean
}

export const enum UiModes {
  Camera = "Camera",
  Default = "Default",
}

export type SpaceStateSeed = {
  crazyGames?: CrazyGamesState
  instanceId?: string | null
  joinContext: SpaceJoinContextState
  pathPrefix: string
  shareId?: string | null
  spaceId?: string | null
}

export const createInitialSpaceState = (seed: SpaceStateSeed): SpaceState => {
  const micPeakAmplitude = new MotionValue<number>()
  micPeakAmplitude.set(0)

  return {
    id: seed.spaceId ?? null,
    chat: initialChatState,
    crazyGames: seed.crazyGames ?? initialCrazyGamesState,
    gamepix: initialGamepixState,
    is2dUiVisible: true,
    isCreateAvatarFlow: null,
    isCustomEnvModalVisible: false,
    isNewSpace: false,
    isObjectInspectorVisible: false,
    isOwnerPlusMember: false,
    isScreenRecording: false,
    joinContext: seed.joinContext,
    quests: initialQuestsState,
    livestreamMicUsageBannerStatus: "initial",
    micPeakAmplitude,
    modals: initialSpaceModalsState,
    shouldShowContentMenu: false,
    shouldShowLayersPanel: false,
    shouldShowPropertiesPanel: false,
    uiMode: UiModes.Default,
    instanceId: seed.instanceId ?? null,
    isHostToolsOpen: false,
    isSettingsDrawerOpen: false,
    isSpaceMenuOpen: false,
    isRevertSpaceModalOpen: false,
    isShareModalOpen: false,
    hotkeyActionPopoverType: null,
    showEnvironmentSettingsModal: false,
    showEnvironmentPickerModal: false,
    showEditCustomEnvironmentBanner: false,
    showFirstTutorial: false,
    showManageHostsModal: false,
    showFullscreenModeNewFeatureBadge: true,
    showTokenGateAccessModal: false,
    transformPanelState: TransformPanelState.Closed,
    hyperlinkPanelState: AddContentHyperlinkState.Closed,
    controlsModalOpen: false,
    supportsUserMedia: false,
    pathPrefix: seed.pathPrefix,
    shareId: seed.shareId ?? null,
    showCreatePortalModal: false,
    autoLayoutFiles: [],
    environmentToChangeTo: undefined,
    environmentVariantToChangeTo: 0,
    isReplaceCustomEnvModalOpen: false,
    isReplaceCustomEnvModalInUploadContext: false,
    newCustomEnvironmentFile: [],
    isSpacePickerVisible: false,
    showCreateHyperlinkPortal: false,
    hasShownTokenGateWelcomeModal: false,
    showUserProfileEditorModal: false,
    selectedUserProfileId: null,
    spaceInfoModalCopy: {},
    spaceInfoModalOnClose: undefined,
    composerType: null,
    hasShownDeetsBeforePublish: false,
    isSpaceInfoModalOpen: false,
    showEndGoLiveModal: false,
    showGoLiveModal: false,
    signUpBanner: { state: "initial", variant: "time" },
    isCreateCustomEnvModalOpen: false,
    rtcState: getInitialRoomRtcState(),
    rtcManager: null,
    uploadedAssets: null,
    wasParticipantsMenuOpened: true,
  }
}

export enum SpaceMiscActionType {
  ClearSpaceAndInstanceId = "ClearSpaceAndInstanceId",
  CloseParticipantsMenu = "CloseParticipantsMenu",
  DismissLivestreamMicUsageBanner = "DismissLivestreamMicUsageBanner",
  DismissSignUpBanner = "DismissSignUpBanner",
  GetOwnerMembershipStatus = "GetOwnerMembershipStatus",
  OpenLivestreamMicUsageBanner = "OpenLivestreamMicUsageBanner",
  OpenSignUpBanner = "OpenSignUpBanner",
  /** Request Pointer Lock is a message that is then dispatched in `handle-unity-message` from Unity */
  RequestPointerLock = "RequestPointerLock",
  ResetSpaceState = "ResetSpaceState",
  Set2dUiVisibility = "Set2dUiVisibility",
  SetCameraRotationMode = "SetCameraRotationMode",
  SetHotkeyActionPopoverType = "SetHotkeyActionPopoverType",
  SetIsCreateAvatarFlow = "SetIsCreateAvatarFlow",
  SetIsCustomEnvModalVisible = "SetIsCustomEnvModalVisible",
  SetIsLayersPanelOpen = "SetIsLayersPanelOpen",
  SetIsOwnerPlusMember = "SetIsOwnerPlusMember",
  SetIsPropertiesPanelOpen = "SetIsPropertiesPanelOpen",
  SetIsSpaceMenuOpen = "SetIsSpaceMenuOpen",
  SetRtcState = "SetRtcState",
  SetShouldShowContentMenu = "SetShouldShowContentMenu",
  SetSpaceAndInstanceId = "SetSpaceAndInstanceId",
  SetSpaceState = "SetSpaceState",
  SetUiMode = "SetUiMode",
  ShowFullscreenModeToast = "ShowFullscreenModeToast",
  ShowRtcPermissionErrorToast = "ShowRtcPermissionErrorToast",
  StartRecording = "StartRecording",
  StopRecording = "StopRecording",
  TakeScreenshot = "TakeScreenshot",
  Toggle2dUiVisibility = "Toggle2dUiVisibility",
  ToggleEmoteTray = "ToggleEmoteTray",
  ToggleFullscreenMode = "ToggleFullscreenMode",
  ToggleHotkeyActionPopoverType = "ToggleHotkeyActionPopoverType",
  ToggleObjectInspectorVisibility = "ToggleInspectorVisibility",
  ToggleParticipantsMenu = "ToggleParticipantsMenu",
  ToggleSettingsDrawer = "ToggleSettingsDrawer",
}

export type SpaceActionUnionType =
  | ChatActionType
  | CrazyGamesActionType
  | GamepixActionType
  | QuestsActionType
  | RewardsActionType
  | SpaceJoinContextActionType
  | SpaceMiscActionType
  | SpaceModalsActionType

export const SpaceActionType = {
  ...ChatActionType,
  ...CrazyGamesActionType,
  ...GamepixActionType,
  ...RewardsActionType,
  ...SpaceJoinContextActionType,
  ...QuestsActionType,
  ...SpaceMiscActionType,
  ...SpaceModalsActionType,
}

export type DismissSignUpBanner = ActionT<SpaceMiscActionType.DismissSignUpBanner>
export type OpenSignUpBanner = ActionT<SpaceMiscActionType.OpenSignUpBanner, { variant?: SignUpBannerState["variant"] }>
export type DismissLivestreamMicUsageBanner = ActionT<SpaceMiscActionType.DismissLivestreamMicUsageBanner>
export type OpenLivestreamMicUsageBanner = ActionT<SpaceMiscActionType.OpenLivestreamMicUsageBanner>
export type RequestPointerLock = ActionT<
  SpaceMiscActionType.RequestPointerLock,
  {
    /** If true, always request pointer lock. Otherwise, we only request pointer lock if currently in PointerLock_Unlocked camera */
    forceRequest?: boolean
    /**
     * If true, request for pointer lock with unadjustedMovement (disables OS-level mouse acceleration)
     * @see https://developer.mozilla.org/en-US/docs/Web/API/Element/requestPointerLock#parameters
     */
    unadjustedMovement?: boolean
  }
>
export type SetIsCreateAvatarFlow = ActionT<SpaceMiscActionType.SetIsCreateAvatarFlow, { isOnboarding: boolean } | null>
export type SetHotkeyActionPopoverType = ActionT<
  SpaceMiscActionType.SetHotkeyActionPopoverType,
  HotkeyActionPopoverType | null
>
export type SetShouldShowContentMenu = ActionT<SpaceMiscActionType.SetShouldShowContentMenu, boolean>
export type SetUiMode = ActionT<SpaceMiscActionType.SetUiMode, UiModes>
export type StartRecording = ActionT<SpaceMiscActionType.StartRecording>
export type StopRecording = ActionT<SpaceMiscActionType.StopRecording>
export type SetIsCustomEnvModalVisible = ActionT<SpaceMiscActionType.SetIsCustomEnvModalVisible, boolean>
export type SetIsOwnerPlusMember = ActionT<SpaceMiscActionType.SetIsOwnerPlusMember, boolean>
export type GetOwnerMembershipStatus = ActionT<SpaceMiscActionType.GetOwnerMembershipStatus>
export type SetIsSpaceMenuOpen = ActionT<SpaceMiscActionType.SetIsSpaceMenuOpen, boolean>
export type SetIsLayersPanelOpen = ActionT<SpaceMiscActionType.SetIsLayersPanelOpen, boolean>
export type SetIsPropertiesPanelOpen = ActionT<SpaceMiscActionType.SetIsPropertiesPanelOpen, boolean>
export type Set2dUiVisibility = ActionT<SpaceMiscActionType.Set2dUiVisibility, boolean>
export type TakeScreenshot = ActionT<SpaceMiscActionType.TakeScreenshot>
export type Toggle2dUiVisibility = ActionT<SpaceMiscActionType.Toggle2dUiVisibility>
export type ToggleObjectInspectorVisibility = ActionT<SpaceMiscActionType.ToggleObjectInspectorVisibility>
export type ResetSpaceState = ActionT<SpaceMiscActionType.ResetSpaceState>
export type SetCameraRotationMode = ActionT<
  SpaceMiscActionType.SetCameraRotationMode,
  { newState: SpatialCameraRotationMode }
>
export type SetRtcState = ActionT<SpaceMiscActionType.SetRtcState, RtcStateUpdate>
type SpaceStateUpdate = Partial<SpaceState> | ((draft: Draft<SpaceState>) => void)
export type SetSpaceState = ActionT<SpaceMiscActionType.SetSpaceState, SpaceStateUpdate>
export type SetSpaceAndInstanceId = ActionT<
  SpaceMiscActionType.SetSpaceAndInstanceId,
  { instanceId?: string | null; spaceId: string }
>
export type ClearSpaceAndInstanceId = ActionT<SpaceMiscActionType.ClearSpaceAndInstanceId>
export type ToggleEmoteTray = ActionT<SpaceMiscActionType.ToggleEmoteTray>
export type ToggleHotkeyActionPopoverType = ActionT<
  SpaceMiscActionType.ToggleHotkeyActionPopoverType,
  HotkeyActionPopoverType
>
export type CloseParticipantsMenu = ActionT<SpaceMiscActionType.CloseParticipantsMenu>
export type ToggleParticipantsMenu = ActionT<SpaceMiscActionType.ToggleParticipantsMenu>
export type ToggleSettingsDrawer = ActionT<SpaceMiscActionType.ToggleSettingsDrawer>
export type ToggleFullscreenMode = ActionT<SpaceMiscActionType.ToggleFullscreenMode>
export type ShowRtcPermissionErrorToast = ActionT<SpaceMiscActionType.ShowRtcPermissionErrorToast>
export type ShowFullscreenModeToast = ActionT<SpaceMiscActionType.ShowFullscreenModeToast>

export const SpaceActions = {
  ...ChatActions,
  ...CrazyGamesActions,
  ...GamepixActions,
  ...RewardsActions,
  ...SpaceJoinContextActions,
  ...SpaceModalsActions,
  ...QuestsActions,
  dismissSignUpBanner: makeActionCreator<DismissSignUpBanner>(SpaceMiscActionType.DismissSignUpBanner),
  openSignUpBanner: makeActionCreator<OpenSignUpBanner>(SpaceMiscActionType.OpenSignUpBanner),
  dismissLivestreamMicUsageBanner: makeActionCreator<DismissLivestreamMicUsageBanner>(
    SpaceMiscActionType.DismissLivestreamMicUsageBanner
  ),
  openLivestreamMicUsageBanner: makeActionCreator<OpenLivestreamMicUsageBanner>(
    SpaceMiscActionType.OpenLivestreamMicUsageBanner
  ),
  requestPointerLock: makeActionCreator<RequestPointerLock>(SpaceMiscActionType.RequestPointerLock),
  setIsCreateAvatarFlow: makeActionCreator<SetIsCreateAvatarFlow>(SpaceMiscActionType.SetIsCreateAvatarFlow),
  setHotkeyActionPopoverType: makeActionCreator<SetHotkeyActionPopoverType>(
    SpaceMiscActionType.SetHotkeyActionPopoverType
  ),
  setShouldShowContentMenu: makeActionCreator<SetShouldShowContentMenu>(SpaceMiscActionType.SetShouldShowContentMenu),
  setUiMode: makeActionCreator<SetUiMode>(SpaceMiscActionType.SetUiMode),
  startRecording: makeActionCreator<StartRecording>(SpaceMiscActionType.StartRecording),
  stopRecording: makeActionCreator<StopRecording>(SpaceMiscActionType.StopRecording),
  setIsCustomEnvModalVisible: makeActionCreator<SetIsCustomEnvModalVisible>(
    SpaceMiscActionType.SetIsCustomEnvModalVisible
  ),
  setIsOwnerPlusMember: makeActionCreator<SetIsOwnerPlusMember>(SpaceMiscActionType.SetIsOwnerPlusMember),
  setIsSpaceMenuOpen: makeActionCreator<SetIsSpaceMenuOpen>(SpaceMiscActionType.SetIsSpaceMenuOpen),
  setIsLayersPanelOpen: makeActionCreator<SetIsLayersPanelOpen>(SpaceMiscActionType.SetIsLayersPanelOpen),
  setIsPropertiesPanelOpen: makeActionCreator<SetIsPropertiesPanelOpen>(SpaceMiscActionType.SetIsPropertiesPanelOpen),
  set2dUiVisibility: makeActionCreator<Set2dUiVisibility>(SpaceMiscActionType.Set2dUiVisibility),
  takeScreenshot: makeActionCreator<TakeScreenshot>(SpaceMiscActionType.TakeScreenshot),
  toggle2dUiVisibility: makeActionCreator<Toggle2dUiVisibility>(SpaceMiscActionType.Toggle2dUiVisibility),
  toggleObjectInspectorVisibility: makeActionCreator<ToggleObjectInspectorVisibility>(
    SpaceMiscActionType.ToggleObjectInspectorVisibility
  ),
  getOwnerMembershipStatus: makeActionCreator<GetOwnerMembershipStatus>(SpaceMiscActionType.GetOwnerMembershipStatus),
  resetSpaceState: makeActionCreator<ResetSpaceState>(SpaceMiscActionType.ResetSpaceState),
  setRtcState: makeActionCreator<SetRtcState>(SpaceMiscActionType.SetRtcState),
  setSpaceState: makeActionCreator<SetSpaceState>(SpaceMiscActionType.SetSpaceState),
  setSpaceAndInstanceId: makeActionCreator<SetSpaceAndInstanceId>(SpaceMiscActionType.SetSpaceAndInstanceId),
  clearSpaceAndInstanceId: makeActionCreator<ClearSpaceAndInstanceId>(SpaceMiscActionType.ClearSpaceAndInstanceId),
  toggleEmoteTray: makeActionCreator<ToggleEmoteTray>(SpaceMiscActionType.ToggleEmoteTray),
  toggleHotkeyActionPopoverType: makeActionCreator<ToggleHotkeyActionPopoverType>(
    SpaceMiscActionType.ToggleHotkeyActionPopoverType
  ),
  closeParticipantsMenu: makeActionCreator<CloseParticipantsMenu>(SpaceMiscActionType.CloseParticipantsMenu),
  toggleParticipantsMenu: makeActionCreator<ToggleParticipantsMenu>(SpaceMiscActionType.ToggleParticipantsMenu),
  toggleSettingsDrawer: makeActionCreator<ToggleSettingsDrawer>(SpaceMiscActionType.ToggleSettingsDrawer),
  toggleFullscreenMode: makeActionCreator<ToggleFullscreenMode>(SpaceMiscActionType.ToggleFullscreenMode),
  setCameraRotationMode: makeActionCreator<SetCameraRotationMode>(SpaceMiscActionType.SetCameraRotationMode),
  showRtcPermissionErrorToast: makeActionCreator<ShowRtcPermissionErrorToast>(
    SpaceMiscActionType.ShowRtcPermissionErrorToast
  ),
  showFullscreenModeToast: makeActionCreator<ShowFullscreenModeToast>(SpaceMiscActionType.ShowFullscreenModeToast),
}

export type SpaceAction = GetActionType<typeof SpaceActions>

export function spaceReducer(state: SpaceState, action: SpaceAction): SpaceState {
  if (action.type === SpaceActionType.ResetSpaceState) {
    return createInitialSpaceState({
      crazyGames: state.crazyGames,
      spaceId: state.id,
      shareId: state.shareId,
      joinContext: state.joinContext,
      pathPrefix: state.pathPrefix,
    })
  }

  // FIXME: After the Room component is broken down into more specific components and state selction,
  // remove this update-by-mutation and the auto-freeze disabling line at the top of the file.
  // This is a temporary fix to avoid over-rendering the Room component on every AppState change.
  /* const chat = chatReducer(state.chat, action as ChatAction)
  const joinContext = spaceJoinContextReducer(state.joinContext, action as SpaceJoinContextAction)
  const quests = questsReducer(state.quests, action as QuestsAction)
  return {
    ...spaceMiscReducer(state, action),
    chat,
    joinContext,
    quests
  } */
  const newState = spaceMiscReducer(state, action)
  newState.chat = chatReducer(newState.chat, action as ChatAction)
  newState.crazyGames = crazyGamesReducer(newState.crazyGames, action as CrazyGamesAction)
  newState.gamepix = gamepixReducer(newState.gamepix, action as GamepixAction)
  newState.joinContext = spaceJoinContextReducer(newState.joinContext, action as SpaceJoinContextAction)
  newState.modals = spaceModalsReducer(newState.modals, action as SpaceModalsAction)
  newState.quests = questsReducer(newState.quests, action as QuestsAction)
  return newState
}

function spaceMiscReducer(state: SpaceState, action: SpaceAction): SpaceState {
  switch (action.type) {
    case SpaceActionType.DismissSignUpBanner:
      return produce(state, (draft) => void (draft.signUpBanner.state = "dismissed"))
    case SpaceActionType.OpenSignUpBanner:
      if (state.signUpBanner.state === "initial") {
        return produce(state, (draft) => {
          draft.signUpBanner.state = "open"
          if (action.payload?.variant) draft.signUpBanner.variant = action.payload.variant
        })
      }
      return state
    case SpaceActionType.DismissLivestreamMicUsageBanner:
      return produce(state, (draft) => void (draft.livestreamMicUsageBannerStatus = "dismissed"))
    case SpaceActionType.OpenLivestreamMicUsageBanner:
      // Only open the banner if it has not already been opened/dismissed, to avoid annoying the user.
      if (state.livestreamMicUsageBannerStatus === "initial") {
        return produce(state, (draft) => {
          draft.livestreamMicUsageBannerStatus = "open"
        })
      }
      return state
    case SpaceActionType.SetHotkeyActionPopoverType:
      return produce(state, (draft) => void (draft.hotkeyActionPopoverType = action.payload))
    case SpaceActionType.SetShouldShowContentMenu:
      return produce(state, (draft) => void (draft.shouldShowContentMenu = action.payload))
    case SpaceActionType.SetIsCreateAvatarFlow:
      return produce(state, (draft) => void (draft.isCreateAvatarFlow = action.payload))
    case SpaceActionType.SetUiMode:
      return produce(state, (draft) => {
        draft.uiMode = action.payload
        draft.hotkeyActionPopoverType = null
      })
    case SpaceActionType.SetIsCustomEnvModalVisible:
      return produce(state, (draft) => {
        draft.isCustomEnvModalVisible = action.payload
        if (action.payload) {
          draft.transformPanelState = TransformPanelState.Closed
        }
      })
    case SpaceActionType.SetIsOwnerPlusMember:
      return produce(state, (draft) => void (draft.isOwnerPlusMember = action.payload))
    case SpaceActionType.SetIsSpaceMenuOpen:
      return produce(state, (draft) => void (draft.isSpaceMenuOpen = action.payload))
    case SpaceActionType.SetIsLayersPanelOpen:
      return produce(state, (draft) => void (draft.shouldShowLayersPanel = action.payload))
    case SpaceActionType.SetIsPropertiesPanelOpen:
      return produce(state, (draft) => void (draft.shouldShowPropertiesPanel = action.payload))
    case SpaceActionType.Set2dUiVisibility:
      return produce(state, (draft) => void (draft.is2dUiVisible = action.payload))
    case SpaceActionType.Toggle2dUiVisibility:
      return produce(state, (draft) => void (draft.is2dUiVisible = !state.is2dUiVisible))
    case SpaceActionType.ToggleObjectInspectorVisibility:
      return produce(state, (draft) => void (draft.isObjectInspectorVisible = !state.isObjectInspectorVisible))
    case SpaceActionType.SetRtcState:
      return {
        ...state,
        rtcState: produce(state.rtcState, action.payload),
      }
    case SpaceActionType.StartRecording:
      return produce(state, (draft) => {
        draft.isScreenRecording = true
        draft.hotkeyActionPopoverType = null
      })
    case SpaceActionType.StopRecording:
      return produce(state, (draft) => {
        draft.isScreenRecording = false
        draft.hotkeyActionPopoverType = null
      })
    case SpaceActionType.SetSpaceState:
      if (typeof action.payload === "function") {
        return produce(state, action.payload)
      }
      return { ...state, ...action.payload }
    case SpaceActionType.SetSpaceAndInstanceId:
      return produce(state, (draft) => {
        draft.id = action.payload.spaceId
        draft.instanceId = action.payload.instanceId
      })
    case SpaceActionType.ClearSpaceAndInstanceId:
      return produce(state, (draft) => {
        draft.id = null
        draft.instanceId = null
      })
    case SpaceActionType.TakeScreenshot:
      return produce(state, (draft) => {
        draft.hotkeyActionPopoverType = null
      })
    case SpaceActionType.ToggleHotkeyActionPopoverType:
      return produce(state, (draft) => {
        draft.hotkeyActionPopoverType = draft.hotkeyActionPopoverType === action.payload ? null : action.payload
      })

    case SpaceActionType.ToggleSettingsDrawer:
      return produce(state, (draft) => {
        draft.isSettingsDrawerOpen = !draft.isSettingsDrawerOpen
      })
    case SpaceActionType.ToggleEmoteTray:
      return produce(state, (draft) => {
        draft.hotkeyActionPopoverType = null
      })
    default:
      return state
  }
}
