import { ActionT, GetActionType, makeActionCreator } from "@spatialsys/js/redux"
import { getCookieValue } from "@spatialsys/js/util/cookies"
import { InteractionType, TrackedComponents } from "@spatialsys/react/analytics"

export const CANVAS_VIEW_SIZE_COOKIE_NAME = "spatialCanvasViewSize"

export type CanvasViewSize = "default" | "theater"
export type EmbedSource = "coolmath" | "crazygames" | "gamepix" | "nowgg" | "play-games" | "y8"

export const gamePortalEmbedSources: EmbedSource[] = ["coolmath", "crazygames", "y8", "nowgg", "gamepix", "play-games"]

export type SpatialCanvasState = {
  /**
   * If true, the Play CTA will not appear on non-partner embeds (by default it will).
   */
  autoplay: boolean
  canMaximize: boolean
  element: HTMLCanvasElement | null
  embedSource: EmbedSource | null
  /**
   * If true, allows RTC to be enabled in the embed.
   */
  enableRtcInEmbed: boolean
  isFeaturedInView: boolean | null
  /**
   * If true, the canvas should be maximized to the full size of its container (on the `/s` page).
   */
  isMaximized: boolean
  /**
   * If true, the space is ready to be joined. Otherwise, some sort of loading state should be displayed.
   * In particular, an explicit click on a Play CTA is required, and dismisses this state.
   */
  isReadyToJoin: boolean
  mode: "closed" | "featured" | "miniplayer" | "standalone-page"
  /**
   * If theater, the canvas should expand to a wider width, but not quite the ful window size (on the `/s` page).
   * The space information and related space recommendations should still be displayed.
   *
   * `view` is only applicable to the `/s` page. It is ignored in jump cut or embed cases.
   *
   * The view is persisted in session storage.
   */
  viewSize: CanvasViewSize
  zIndex: number
}

export type CanvasStateSeed = Partial<
  Pick<SpatialCanvasState, "autoplay" | "embedSource" | "enableRtcInEmbed" | "isReadyToJoin">
> & {
  mode: SpatialCanvasState["mode"]
  viewSize?: CanvasViewSize
}

export const getInitialCanvasState = (seed: CanvasStateSeed): SpatialCanvasState => {
  const {
    autoplay = false,
    embedSource = null,
    enableRtcInEmbed = false,
    mode,
    viewSize: viewSizeFromSeed,
    isReadyToJoin = false,
  } = seed
  let viewSize = viewSizeFromSeed
  if (!viewSize) {
    if (typeof document !== "undefined") {
      const cookieVal = getCookieValue(document.cookie, CANVAS_VIEW_SIZE_COOKIE_NAME)
      if (cookieVal === "default" || cookieVal === "theater") {
        viewSize = cookieVal
      } else {
        viewSize = "default"
      }
    }
  }

  return {
    autoplay,
    canMaximize: false,
    element: null,
    embedSource,
    enableRtcInEmbed,
    isFeaturedInView: null,
    isReadyToJoin,
    isMaximized: false,
    mode,
    viewSize: viewSize!,
    zIndex: 1,
  }
}

export enum CanvasActionType {
  SetCanvas = "SetCanvas",
  SetCanvasMaximized = "SetCanvasMaximized",
  SetCanvasState = "SetCanvasState",
  SetCanvasViewSize = "SetCanvasViewSize",
  SetFeaturedInView = "SetFeaturedInView",
  SetIsReadyToJoinSpace = "SetIsReadyToJoinSpace",
  ToggleCanvasMaximized = "ToggleCanvasMaximized",
  ToggleCanvasViewSize = "ToggleCanvasViewSize",
}

export type SetCanvas = ActionT<CanvasActionType.SetCanvas, HTMLCanvasElement>
export type SetCanvasMaximized = ActionT<CanvasActionType.SetCanvasMaximized, boolean>
export type SetCanvasState = ActionT<CanvasActionType.SetCanvasState, Partial<SpatialCanvasState>>
export type SetCanvasViewSize = ActionT<CanvasActionType.SetCanvasViewSize, CanvasViewSize>
export type SetFeaturedInView = ActionT<CanvasActionType.SetFeaturedInView, boolean | null>
export type SetIsReadyToJoinSpace = ActionT<CanvasActionType.SetIsReadyToJoinSpace, boolean>
export type ToggleCanvasMaximized = ActionT<
  CanvasActionType.ToggleCanvasMaximized,
  { component?: TrackedComponents; type?: InteractionType } | undefined
>
export type ToggleCanvasViewSize = ActionT<
  CanvasActionType.ToggleCanvasViewSize,
  { component?: TrackedComponents; type?: InteractionType } | undefined
>

export const CanvasActions = {
  setCanvas: makeActionCreator<SetCanvas>(CanvasActionType.SetCanvas),
  setCanvasMaximized: makeActionCreator<SetCanvasMaximized>(CanvasActionType.SetCanvasMaximized),
  setCanvasState: makeActionCreator<SetCanvasState>(CanvasActionType.SetCanvasState),
  setCanvasViewSize: makeActionCreator<SetCanvasViewSize>(CanvasActionType.SetCanvasViewSize),
  setFeaturedInView: makeActionCreator<SetFeaturedInView>(CanvasActionType.SetFeaturedInView),
  setIsReadyToJoinSpace: makeActionCreator<SetIsReadyToJoinSpace>(CanvasActionType.SetIsReadyToJoinSpace),
  toggleCanvasMaximized: makeActionCreator<ToggleCanvasMaximized>(CanvasActionType.ToggleCanvasMaximized),
  toggleCanvasViewSize: makeActionCreator<ToggleCanvasViewSize>(CanvasActionType.ToggleCanvasViewSize),
}

export type CanvasAction = GetActionType<typeof CanvasActions>

export function canvasReducer(state: SpatialCanvasState, action: CanvasAction): SpatialCanvasState {
  switch (action.type) {
    case CanvasActionType.SetCanvas:
      return { ...state, element: action.payload }
    case CanvasActionType.SetCanvasState:
      return { ...state, ...action.payload }
    case CanvasActionType.SetFeaturedInView:
      return { ...state, isFeaturedInView: action.payload }
    case CanvasActionType.SetCanvasMaximized:
      return { ...state, isMaximized: action.payload }
    case CanvasActionType.SetCanvasViewSize:
      return { ...state, viewSize: action.payload }
    case CanvasActionType.SetIsReadyToJoinSpace:
      return { ...state, isReadyToJoin: action.payload }
    default:
      return state
  }
}
