import { UseQueryOptions, useQuery } from "@tanstack/react-query"

import { GetCategoriesMenuResponse } from "@spatialsys/js/sapi/types"

import { useSapi } from "../use-sapi"

export const GET_CATEGORIES_MENU_QUERY_KEY = "GET_CATEGORIES_MENU_QUERY"

/**
 * Fetches the categories to use in the categories menu
 */
export const useGetCategoriesMenu = (
  options?: UseQueryOptions<GetCategoriesMenuResponse, unknown, GetCategoriesMenuResponse, string[]>
) => {
  const sapiClient = useSapi()
  return useQuery({
    queryKey: [GET_CATEGORIES_MENU_QUERY_KEY],
    queryFn: () => sapiClient.menu.getCategories(),
    staleTime: Infinity,
    ...options,
  })
}
