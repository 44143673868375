import { UnityInstanceParameters } from "@spatialsys/js/types"

import type { ConfigT } from "./config"
import { createDefaultUnityVersionString } from "./create-version-string"

export type VersionData = {
  /** i.e. `dev`, `store`, `early-access`. Should probably be an enum */
  channel: string
  /** The short SHA of the most recent change that affected Unity */
  shortSha: string
  /** The semantic version, i.e. `6.28.0` */
  version: string
  /**
   * The full version string shown where the webGL build is stored,
   * i.e. `Spatial_6.28.0_aac2c8ba_dev`
   */
  versionString: string
}

export const createDefaultUnityVersion = (config: ConfigT): VersionData => ({
  channel: config.CHANNEL_NAME,
  shortSha: config.SHORT_SHA_LAST_UNITY_CHANGE,
  version: config.SPATIAL_UNITY_VERSION,
  versionString: createDefaultUnityVersionString({
    spatialUnityVersion: config.SPATIAL_UNITY_VERSION,
    shortShaLastUnityChange: config.SHORT_SHA_LAST_UNITY_CHANGE,
    channel: config.CHANNEL_NAME,
  }),
})

/**
 * Parses a full version string into version data object
 */
export const parseVersionString = (versionString: string): VersionData => {
  try {
    const [, version, shortSha, channel] = versionString.split("_")
    return {
      channel,
      shortSha,
      version,
      versionString,
    }
  } catch (e) {
    throw new Error("Error parsing version string")
  }
}

// If unity's progress event returns greater than this value, it's fully downloaded.
export const UNITY_DOWNLOADED_PROGRESS_THRESHOLD = 0.85

export function createUnityManifest(version: string, urlBase: string, useCompression: boolean): UnityManifest {
  const suffix = useCompression ? ".br" : ""
  return {
    loaderUrl: `${urlBase}/spatial-webgl/${version}/Build/WebGL.loader.js`,
    dataUrl: `${urlBase}/spatial-webgl/${version}/Build/WebGL.data${suffix}`,
    frameworkUrl: `${urlBase}/spatial-webgl/${version}/Build/WebGL.framework.js${suffix}`,
    codeUrl: `${urlBase}/spatial-webgl/${version}/Build/WebGL.wasm${suffix}`,
    streamingAssetsUrl: `${urlBase}/spatial-webgl/${version}/StreamingAssets`,
    productVersion: version,
  }
}

// Used for preloading in preload-unity-bundles.tsx
export function createAOTLinks(
  config: ConfigT,
  urlBase: string,
  compressed: boolean = true
): Record<number, { url: string }> {
  const manifest = createUnityManifest(createDefaultUnityVersion(config).versionString, urlBase, compressed)
  const suffix = compressed ? ".br" : ""
  return {
    0: { url: `${manifest.streamingAssetsUrl}/AOTAssemblies/System.Core.dll.bytes${suffix}` },
    1: { url: `${manifest.streamingAssetsUrl}/AOTAssemblies/mscorlib.dll.bytes${suffix}` },
    2: {
      url: `${manifest.streamingAssetsUrl}/AOTAssemblies/SpatialSys.Client.CSharpScripting.APIHooks.dll.bytes${suffix}`,
    },
  }
}

export type UnityManifest = Pick<
  UnityInstanceParameters,
  "codeUrl" | "dataUrl" | "frameworkUrl" | "productVersion" | "streamingAssetsUrl"
> & {
  loaderUrl: string
}

const coreBuildFilePattern = /spatial-webgl\/Spatial_.*\/Build\/WebGL\.(data|wasm)/
export const isCoreBuildFile = (url: URL) => coreBuildFilePattern.test(url.href)

const streamingAssetPattern = /spatial-webgl\/Spatial_.*\/StreamingAssets/
export const isStreamingAsset = (url: URL) => streamingAssetPattern.test(url.href)

const catalogFilesPattern = /asset-bundles-.*\/WebGL\/catalog_.*\.(json|hash)/
export const isAssetBundleManifest = (url: URL) => catalogFilesPattern.test(url.pathname)

const assetBundlePattern = /asset-bundles-.*\/WebGL\/.*.bundle$/
export const isAssetBundle = (url: URL) => assetBundlePattern.test(url.href)
