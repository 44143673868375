import { SpatialAdType } from "@spatialsys/unity/app-state"

import { GenericTrack } from "./generic-track"
import { AdStatus, InteractionName, getAdInteractionType } from "./interactions"
import { Properties } from "./properties"

export interface TrackedEvent {
  name: InteractionName
}

export const trackEvent = (track: GenericTrack, action: TrackedEvent, properties?: Properties) => {
  track(`${action.name}`, {
    Name: action.name,
    ...properties,
  })
}

type AdSdk = "AdinPlay" | "CrazyGames" | "Gamepix" | "H5" | "IMA"

type TrackAdArgs = Properties & {
  adStatus: AdStatus
  adType: SpatialAdType
}

export function createTrackAdEvent(track: GenericTrack, adSdk: AdSdk) {
  return (args: TrackAdArgs) => {
    const { adStatus, adType, ...properties } = args
    return trackEvent(
      track,
      { name: getAdInteractionType(adStatus) },
      {
        "Ad SDK": adSdk,
        "Ad Type": adType === SpatialAdType.MidGame ? "Midgame" : "Reward",
        ...properties,
      }
    )
  }
}
