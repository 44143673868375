import { AxiosInstance } from "axios"

export function createAccountEndpoint(client: AxiosInstance) {
  return {
    /**
     * Deletes the user's account
     */
    deleteMe: async function (): Promise<void> {
      const response = await client.delete<void>(`/me`)
      return response.data
    },
  }
}
