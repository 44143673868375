import { AxiosInstance } from "axios"

import {
  Avatar,
  AvatarAnimation,
  Badge,
  PackageWorldDetail,
  PaginationRequestMetadata,
  PaginationResponseMetadata,
  PublicFriend,
  SocialProfileV2,
  SpaceAndCreator,
} from "../types"

export type GetAnotherUsersFriendsRequest = {
  userId: string
}
export type GetAnotherUsersMembershipRequest = {
  userId: string
}

export type GetAnotherUsersFriendsResponse = PaginationResponseMetadata & { friends: PublicFriend[] }
export type GetAnotherUsersMembershipResponse = { hasSubscription: boolean }

export type GetPublishedSpacesByUsernameRequest = PaginationRequestMetadata & { username: string }
export type GetPublishedSpacesByUsernameResponse = PaginationResponseMetadata & {
  spaces: SpaceAndCreator[]
  totalCount: number
}

export type GetBadgesByUsernameRequest = PaginationRequestMetadata & { username: string }
export type GetBadgesByUsernameResponse = PaginationResponseMetadata & {
  badges: Badge[]
  totalCount: number
}

export type GetAvatarsByUsernameRequest = PaginationRequestMetadata & { username: string }

export type AvatarWithWorldDetail = Avatar & { worldDetail?: PackageWorldDetail }
export type GetAvatarsByUsernameResponse = PaginationResponseMetadata & {
  avatars: AvatarWithWorldDetail[]
  totalCount: number
}

export type GetEmotesByUsernameRequest = PaginationRequestMetadata & { username: string }

export type AvatarAnimationWithWorldDetail = AvatarAnimation & { worldDetail?: PackageWorldDetail }
export type GetEmotesByUsernameResponse = PaginationResponseMetadata & {
  emotes: AvatarAnimationWithWorldDetail[]
  totalCount: number
}

export function createProfilesEndpoint(client: AxiosInstance) {
  return {
    async getProfileByUsername(username: string): Promise<SocialProfileV2> {
      const response = await client.get<SocialProfileV2>(`/username/${username}`)
      return response.data
    },
    async getProfileByUserId(userId: string): Promise<SocialProfileV2> {
      const response = await client.get<SocialProfileV2>(`/${userId}`)
      return response.data
    },

    async getAnotherUsersFriends({ userId }: GetAnotherUsersFriendsRequest): Promise<GetAnotherUsersFriendsResponse> {
      const response = await client.get<GetAnotherUsersFriendsResponse>(`/${userId}/friends`)
      return response.data
    },
    async getAnotherUsersMembership({
      userId,
    }: GetAnotherUsersMembershipRequest): Promise<GetAnotherUsersMembershipResponse> {
      const response = await client.get<GetAnotherUsersMembershipResponse>(`/${userId}/subscription`)
      return response.data
    },

    /**
     * Get user's published spaces by username
     */
    async getPublishedSpacesByUsername({
      username,
      ...params
    }: GetPublishedSpacesByUsernameRequest): Promise<GetPublishedSpacesByUsernameResponse> {
      const response = await client.get<GetPublishedSpacesByUsernameResponse>(`/username/${username}/spaces`, {
        params,
      })
      return response.data
    },

    /**
     * Get user's badges by username
     */
    async getBadgesByUsername({
      username,
      ...params
    }: GetBadgesByUsernameRequest): Promise<GetBadgesByUsernameResponse> {
      const response = await client.get<GetBadgesByUsernameResponse>(`/username/${username}/badges`, { params })
      return response.data
    },

    /**
     * Get user's avatars by username
     */
    async getAvatarsByUsername({
      username,
      ...params
    }: GetAvatarsByUsernameRequest): Promise<GetAvatarsByUsernameResponse> {
      const response = await client.get<GetAvatarsByUsernameResponse>(`/username/${username}/avatars`, { params })
      return response.data
    },

    /**
     * Get user's emotes by username
     */
    async getEmotesByUsername({
      username,
      ...params
    }: GetEmotesByUsernameRequest): Promise<GetEmotesByUsernameResponse> {
      const response = await client.get<GetEmotesByUsernameResponse>(`/username/${username}/emotes`, { params })
      return response.data
    },
  }
}
