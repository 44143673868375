import { produce } from "immer"

import { ActionT, GetActionType, makeActionCreator } from "@spatialsys/js/redux"

type SdkStatus = "failed" | "loaded" | "uninitialized"

export type AdinPlayState = {
  element: HTMLDivElement | null
  sdkStatus: SdkStatus
}

export const initialAdinPlayState: AdinPlayState = {
  element: null,
  sdkStatus: "uninitialized",
}

export enum AdinPlayActionType {
  SetAdinPlayElement = "SetAdinPlayElement",
  SetAdinPlaySdkStatus = "SetAdinPlaySdkStatus",
}

export type SetAdinPlayElement = ActionT<AdinPlayActionType.SetAdinPlayElement, HTMLDivElement>
export type SetAdinPlaySdkStatus = ActionT<AdinPlayActionType.SetAdinPlaySdkStatus, SdkStatus>

export const AdinPlayActions = {
  setAdinPlayElement: makeActionCreator<SetAdinPlayElement>(AdinPlayActionType.SetAdinPlayElement),
  setAdinplaySdkStatus: makeActionCreator<SetAdinPlaySdkStatus>(AdinPlayActionType.SetAdinPlaySdkStatus),
}

export type AdinPlayAction = GetActionType<typeof AdinPlayActions>

export const adinPlayReducer = (state: AdinPlayState, action: AdinPlayAction): AdinPlayState => {
  switch (action.type) {
    case AdinPlayActionType.SetAdinPlayElement:
      // Cannot use immer here because the element is not serializable
      return { ...state, element: action.payload }
    case AdinPlayActionType.SetAdinPlaySdkStatus:
      return produce(state, (draft) => void (draft.sdkStatus = action.payload))
    default:
      return state
  }
}
